import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';

class Compliance extends Component {
  constructor(props) {
    super(props);
    this.handleTermsChange = this.handleChange.bind(this, 'terms');
    this.handlePrivacyChange = this.handleChange.bind(this, 'privacy_policy');
    this.handleAgeChange = this.handleChange.bind(this, 'age');
    this.handleCookieChange = this.handleChange.bind(this, 'cookie_policy');
    this.handlePersonalData = this.handleChange.bind(this, 'personal_data');
    this.handleMarketingChange = this.handleChange.bind(this, 'marketing');
    this.handlePassportChange = this.handleChange.bind(this, 'passport');
    this.handleInventoryChange = this.handleChange.bind(this, 'inventory');
    this.handleProfileChange = this.handleChange.bind(this, 'personal_profile');
    this.state = {
      terms: true,
      privacy_policy: true,
      age: true,
      cookie_policy: true,
      marketing: true,
      passport: false,
      inventory: false,
      required: false,
      errors: {},
      warning: {},
    };
  }

  handleChange(key, event) {
    this.setState({ [key]: event.target.checked });
  }

  validate = () => {
    const errors = {};
    if (this.state.terms !== true) errors.terms = 'You must agree to SkyHi Terms of Service!';
    if (this.state.privacy_policy !== true) errors.privacy_policy = 'You must agree to SkyHi Privacy Policy!';
    if (this.state.age !== true) errors.age = 'You must be over 18 to use SkyHi as a service!';
    if (this.state.passport !== true) errors.passport = 'You must have a valid passport to use SkyHi as a service!';
    if (this.state.inventory !== true)
      errors.inventory =
        'I understand and agree that all flights displayed are remnant inventory. There may be times that there is limited or no availability. SkyHi does not guarantee flight inventory and does not guarantee the cumulative value of the Services will be less than the purchase of Tickets through third-parties. ';

    return errors;
  };

  submitCompliance = () => {
    const errors = this.validate();
    const warning = {};
    this.setState({ errors, warning });
    if (this.state.cookie_policy !== true)
      warning.cookie_policy =
        'SkyHi uses Cookies to make your site experience better. Your promo codes and other user information may not work correctly without enabling cookies.';

    if (Object.keys(errors).length === 0) {
      this.props.updateCompliance({
        terms_of_use: this.state.terms,
        privacy_policy: this.state.privacy_policy,
        age_consent: this.state.age,
        personal_data: this.state.personal_data,
        cookie_policy: this.state.cookie_policy,
        marketing: this.state.marketing,
        passport: this.state.passport,
        inventory: this.state.inventory,
      });
    }
  };

  renderErrorMessage = () => {
    if (Object.keys(this.state.errors).length !== 0) {
      return Object.values(this.state.errors).map((key, value) => (
        <li className="errorli" key={value}>
          {key}
        </li>
      ));
    }
  };

  renderWarningMessage = () => {
    if (Object.keys(this.state.warning).length !== 0) {
      return Object.values(this.state.warning).map((key, value) => (
        <li className="warningli" key={value}>
          {key}
        </li>
      ));
    }
  };

  render() {
    const errors = this.state.errors;
    const warning = this.state.warning;
    return (
      <div className="account-page onboarding-page-flow consent-up">
        <div className="container">
          <div className="form-container">
            <h2>Privacy Consent</h2>
            <br />
            <p>In order to become a customer, I agree to the following:</p>
            <br />
            {Object.keys(errors).length === 0 ? null : (
              <ul className="alert alert-danger">{this.renderErrorMessage()}</ul>
            )}
            {Object.keys(warning).length === 0 ? null : (
              <ul className="alert alert-warning">{this.renderWarningMessage()}</ul>
            )}
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.terms} onChange={this.handleTermsChange} />
              <span>
                I agree to SkyHi <Link to="/legal/terms/">Terms and Conditions</Link>
              </span>
            </label>
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.privacy_policy} onChange={this.handlePrivacyChange} />
              <span>
                I agree to SkyHi <Link to="/legal/privacy/">Privacy Policy</Link>
              </span>
            </label>
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.age} onChange={this.handleAgeChange} />
              <span>I agree that I am over 18 years old to use SkyHi</span>
            </label>
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.cookie_policy} onChange={this.handleCookieChange} />
              <span>I agree to SkyHi Cookie Policy</span>
            </label>
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.marketing} onChange={this.handleMarketingChange} />
              <span>I agree to receive SkyHi marketing materials.</span>
            </label>
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.passport} onChange={this.handlePassportChange} />
              <span>I understand that in order to book tickets using SkyHi, I must possess a VALID PASSPORT.</span>
            </label>
            <br />
            <label htmlFor="compliance-switch" className="compliance-switch">
              <Toggle defaultChecked={this.state.inventory} onChange={this.handleInventoryChange} />
              <span>
                I understand and agree that all flights displayed are remnant inventory. There may be times that there
                is limited or no availability. SkyHi does not guarantee flight inventory and does not guarantee the
                cumulative value of the Services will be less than the purchase of Tickets through third-parties.{' '}
              </span>
            </label>
            <button onClick={this.submitCompliance} className="btn btn-blue">
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Compliance;
