import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import appleAppStoreButton from '../../../images/download_app-store@2x.png';
import googlePlayStoreButton from '../../../images/download_google-play@2x.png';

class Footer extends Component {
  state = {};

  submitEmail = e => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_API_URL_BASE + '/v1/mailing-list/subscribe', {
      email: this.state.email
    });
  };

  render() {
    return (
      <div>
        <footer className="footer2desktop">
          <div className="footer-top2">
            <div className="container">
              <div className="footer-flex">
                <div className="footer-column navlinks">
                  <p className="footer-header2">Navigate</p>
                  <ul className="footer-links">
                    <li>
                      <Link to="/how-youll-fly/">how you'll fly</Link>
                    </li>
                    <li>
                      <Link to="/destinations/">destinations</Link>
                    </li>
                    <li>
                      <Link to="/why-skyhi/">why skyhi</Link>
                    </li>
                    <li>
                      <a href="https://go.flyskyhi.com">blog</a>
                    </li>
                    <li>
                      <Link to="/legal/privacy/">privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/legal/terms/">terms of use</Link>
                    </li>
                    <li>
                      <Link to="/baggage/">baggage</Link>
                    </li>
                    <li>
                      <Link to="/airline-contracts-of-carriage/">carrier policy</Link>
                    </li>
                    <li>
                      <a href="http://help.flyskyhi.com">FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-column contact">
                  <p className="footer-header2">Contact Us</p>
                  <ul>
                    <li>+1(844)467-5944</li>
                    <li>contact@flyskyhi.com</li>
                    <ul className="social-flex-items">
                      <li className="social-item">
                        <a href="https://go.flyskyhi.com/">
                          <i className="fa fa-medium" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="social-item">
                        <a href="https://www.instagram.com/travelskyhi/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="social-item">
                        <a href="https://www.facebook.com/travelskyhi">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="social-item">
                        <a href="https://twitter.com/skyhi">
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </ul>
                  <div className="tablet-footer-download">
                    <p className="footer-header2">Get the App</p>
                    <div className="flex-icons-download tablet">
                      <div className="icon-1">
                        <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
                          <img
                            src={appleAppStoreButton}
                            className="img-fluid"
                            alt="iphone"
                          />
                        </a>
                      </div>
                      <div className="icon-1">
                        <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
                          <img
                            src={googlePlayStoreButton}
                            className="img-fluid"
                            alt="googleplay"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-column subscribe2 tablet-subscribe">
                  <p className="footer-header2">Subscribe</p>
                  <p className="footer-header2">
                    Sign up for SkyHi news to get the latest updates, destinations and offers!
                  </p>
                  <form className="form-inline" onSubmit={this.submitEmail}>
                    <span className="input input--hideo">
                      <input
                        type="email"
                        className="input__field input__field--hideo"
                        placeholder="EMAIL"
                        value={this.state.email}
                        onChange={e => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </span>
                    <button type="submit" className="btn-footer-bottom">
                      Sign Up
                    </button>
                  </form>
                </div>
                <div className="footer-column app">
                  <p className="footer-header2">Get the App</p>
                  <div className="flex-icons-download">
                    <div className="icon-1">
                      <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
                        <img
                          src={appleAppStoreButton}
                          className="img-fluid"
                          alt="iphone"
                        />
                      </a>
                    </div>
                    <div className="icon-1">
                      <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
                        <img
                          src={googlePlayStoreButton}
                          className="img-fluid"
                          alt="googleplay"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top footer-middle">
            <div className="container">
              <div className="row flex-center">
                <span className="contact-item">SkyHi Travel, Inc.</span>
                <span className="contact-item last">Apple Valley, CA, USA</span>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row disclaimer-footer">
                <div className="col-md-6 col-sm-12">
                  <p className="lead">
                    SKYHI DOES NOT PROVIDE AIR CARRIER SERVICES AND IS NOT A CERTIFICATED AIR CARRIER. IT IS UP TO THIRD
                    PARTY AIR CARRIERS (“AIR CARRIERS”) TO OFFER AIR TRANSPORTATION SERVICES, WHICH MAY BE PURCHASED
                    THROUGH USE OF SKYHI SERVICES. SKYHI OFFERS INFORMATION AND A METHOD TO OBTAIN SUCH THIRD PARTY AIR
                    TRANSPORTATION SERVICES FROM AIR CARRIERS.{' '}
                  </p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <p>
                    SkyHi offers access to a number of flights operated by Air Carriers. SkyHi shall act solely and
                    exclusively as your agent in arranging a flight. SkyHi offers a method by which to obtain the
                    services of the Air Carriers, but in no way does SkyHi have any responsibility or liability for any
                    services provided by third parties. By using the Services and booking a flight, you authorize SkyHi
                    to enter into a contract with the Air Carrier as your agent.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <footer className="footer2mobile">
          <div className="footer-top2">
            <div className="container">
              <div className="">
                <div className="footer-column navlinks">
                  <ul className="footer-links">
                    <li>
                      <Link to="/how-youll-fly/">how you'll fly</Link>
                    </li>
                    <li>
                      <Link to="/destinations/">destinations</Link>
                    </li>
                    <li>
                      <Link to="/why-skyhi/">why skyhi</Link>
                    </li>
                    <li>
                      <a href="https://go.flyskyhi.com">blog</a>
                    </li>
                    <li>
                      <Link to="/legal/privacy/">privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/legal/terms/">terms of use</Link>
                    </li>
                    <li>
                      <Link to="/baggage/">baggage</Link>
                    </li>
                    <li>
                      <Link to="/airline-contracts-of-carriage/">carrier policy</Link>
                    </li>
                    <li>
                      <a href="http://help.flyskyhi.com">FAQ</a>
                    </li>
                  </ul>
                  <br />
                </div>
              </div>
              <div className="mobile-social-footer footer-column">
                <ul className="mobile-social-flex-items">
                  <li className="mobile-social-item">
                    <a href="https://go.flyskyhi.com/">
                      <i className="fa fa-medium" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="mobile-social-item">
                    <a href="https://www.instagram.com/travelskyhi/">
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="mobile-social-item">
                    <a href="https://www.facebook.com/travelskyhi">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="mobile-social-item">
                    <a href="https://twitter.com/skyhi">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="mobile-footer-section">
              <div className="container">
                <div className="footer-column app ">
                  <p className="footer-header2">Get the App</p>
                  <div className="flex-icons-download">
                    <div className="icon-1">
                      <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
                        <img
                          src={appleAppStoreButton}
                          className="img-fluid"
                          alt="iphone"
                        />
                      </a>
                    </div>
                    <div className="icon-1">
                      <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
                        <img
                          src={googlePlayStoreButton}
                          className="img-fluid"
                          alt="googleplay"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-footer-section">
              <div className="container">
                <div className="footer-column contact">
                  <ul>
                    <li>SKYHI Travel, INC.</li>
                    <li>+1(844)467-5944</li>
                    <li>
                      <a href="mailto:contact@flyskyhi.com">contact@flyskyhi.com</a>
                    </li>
                    <li>Apple Valley, CA, USA</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mobile-footer-section">
              <div className="container">
                <div className="footer-column">
                  <p className="footer-header2">Subscribe</p>
                  <p className="footer-header2">
                    Sign up for SkyHi news to get the latest updates, destinations and offers!
                  </p>
                  <form className="form-inline" onSubmit={this.submitEmail}>
                    <span className="input input--hideo">
                      <input
                        type="email"
                        className="input__field input__field--hideo"
                        placeholder="Your Email"
                        value={this.state.email}
                        onChange={e => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </span>
                    <button type="submit" className="btn-footer-bottom">
                      Sign Up
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="footer-column">
                <div className="row disclaimer-footer">
                  <div className="col-sm-12">
                    <p className="lead">
                      SKYHI DOES NOT PROVIDE AIR CARRIER SERVICES AND IS NOT A CERTIFICATED AIR CARRIER. IT IS UP TO
                      THIRD PARTY AIR CARRIERS (“AIR CARRIERS”) TO OFFER AIR TRANSPORTATION SERVICES, WHICH MAY BE
                      PURCHASED THROUGH USE OF SKYHI SERVICES. SKYHI OFFERS INFORMATION AND A METHOD TO OBTAIN SUCH
                      THIRD PARTY AIR TRANSPORTATION SERVICES FROM AIR CARRIERS.
                    </p>
                  </div>
                  <div className="col-sm-12">
                    <br />
                    <p>
                      SkyHi offers access to a number of flights operated by Air Carriers. SkyHi shall act solely and
                      exclusively as your agent in arranging a flight. SkyHi offers a method by which to obtain the
                      services of the Air Carriers, but in no way does SkyHi have any responsibility or liability for
                      any services provided by third parties. By using the Services and booking a flight, you authorize
                      SkyHi to enter into a contract with the Air Carrier as your agent.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
