import React, { Component } from 'react';
import Promo from './Promo';

class PromoToggle extends Component {
  state = {
    isCollapsed: true
  };

  handleClick = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    const isCollapsed = this.state.isCollapsed;
    return (
      <div>
        {isCollapsed ? (
          <div className="small have-promo" aria-hidden onClick={this.handleClick}>
            I have a promo code.
          </div>
        ) : (
          <div className="promo-expand no-promo">
            <div aria-hidden className="small" onClick={this.handleClick}>
              I don't have a promo code.
            </div>
            <Promo promo={this.props.promo} />
          </div>
        )}
      </div>
    );
  }
}

export default PromoToggle;
