import Cookies from 'js-cookie';
import { SET_USER } from '../constants/user';

import api from '../api';

export const setUser = payload => ({
  type: SET_USER,
  payload,
});

export const grabUser = jwt => dispatch => api.auth.getUser(jwt).then(payload => dispatch(setUser(payload.data)));

export const updateUser = user => dispatch => api.user.account(user).then(payload => dispatch(setUser(payload.data)));

export const addPromo = user => dispatch => api.user.submitPromo(user);

export const submitPromo = promoFromServer => dispatch => {
  const promoFromCookie = Cookies.get('invite_code');
  const { jwt } = localStorage;

  return new Promise((resolve, reject) => {
    if (promoFromServer === null) {
      // User has never gotten to this point in the payment flow before. We need to
      // submit an invite to initialize the process
      if (promoFromCookie && promoFromCookie.match(/\w/)) {
        // Found a promo code in the invite cookie. Try to submit it
        dispatch(addPromo({ jwt, token: promoFromCookie }))
          .then(() =>
            dispatch(grabUser(jwt))
              .then(resolve)
              .catch(reject)
          )
          .catch(() => {
            // Failed to submit promo. We'll assume that the promo is invalid. In that
            // case, we need to submit default
            dispatch(addPromo({ jwt, token: 'default' }))
              .then(() =>
                dispatch(grabUser(jwt))
                  .then(resolve)
                  .catch(reject)
              )
              .catch(reject);
          });
      } else {
        // Submit default invite code
        dispatch(addPromo({ jwt, token: 'default' }))
          .then(() =>
            dispatch(grabUser(jwt))
              .then(resolve)
              .catch(reject)
          )
          .catch(reject);
      }
    } else {
      // User already has a invite code set
      if (
        promoFromCookie &&
        promoFromCookie.match(/\w/) &&
        promoFromCookie !== promoFromServer &&
        promoFromCookie !== 'default'
      ) {
        // The user visited a different invite link; we want to switch to that promo
        dispatch(addPromo({ jwt, token: promoFromCookie, overwrite: true }))
          .then(() =>
            dispatch(grabUser(jwt))
              .then(resolve)
              .catch(reject)
          )
          .catch(reject);
      } else {
        // Don't need to do anything
        resolve();
      }
    }
  });
};

export const updatePromo = promo => dispatch =>
  api.promo.submitPromo(promo).then(payload => dispatch(setUser(payload.data)));
