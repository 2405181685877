import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import isBrowser from '../../../isBrowser';

const JuneTermsOfServiceContents = lazy(() => import('./JuneTermsOfServiceContents'));

const contents = isBrowser() ? (
  <Suspense fallback={<span />}>
    <JuneTermsOfServiceContents />
  </Suspense>
) : <span />;

const JuneTermsOfService = () => (
  <div>
    <Helmet>
      <title>Terms of Service (June 2017)</title>
    </Helmet>
    {contents}
  </div>
);

export default JuneTermsOfService;
