import { SET_LOCATION, FETCHING_LOCATION } from '../constants/location';

const defaultState = {
  fetching: false,
  data: {
    country_code: 'US',
    country_name: 'United States',
    region_code: '',
    region_name: ''
  }
};

export default function location(state = defaultState, action) {
  switch (action.type) {
    case FETCHING_LOCATION:
      return {
        ...state,
        fetching: true
      };

    case SET_LOCATION:
      if (action.payload === null) {
        return defaultState;
      } else {
        return {
          fetching: false,
          data: action.payload
        };
      }

    default:
      return state;
  }
}
