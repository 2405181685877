import React, { Component } from 'react';
import { Animated } from 'react-animated-css';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import Spinner from '../../Spinner';


class StripeCreditCardForm extends Component {
  state = {
    ready: false,
    cardElementFocused: false,
    cardError: false,
    nameOnCard: '',
    nameError: null
  };

  validateName() {
    if (this.state.nameOnCard === '') {
      this.setState({
        nameError: 'Name on Card is a required field'
      });
      return false;
    }
    else if (!this.state.nameOnCard.match(/\w+\s\w+/)) {
      this.setState({
        nameError: 'Name on Card should have at least 2 names'
      });
      return false;
    }
    return true;
  }

  // Checks the card wrapped DOM element for the StripeElement--complete css class.
  validateCard() {
    const wrapperNode = document.getElementsByClassName('StripeElement')[0];
    if (wrapperNode) {
      if (wrapperNode.className.match(/--complete/)) {
        return true;
      }
    }

    this.setState({ cardError: this.cardErrorMessage ? this.cardErrorMessage : 'Invalid Card Info' });
    return false;
  }

  handleReady = () => this.setState({ ready: true });

  handleSubmit = e => {
    e.preventDefault();
    
    const cardValid = this.validateCard();
    const nameValid = this.validateName();

    if (cardValid && nameValid) {
      this.props.handleSubmit();
    }
  };

  handleCardFocus = () => this.setState({ cardElementFocused: true, cardError: null, nameError: null });
  handleCardBlur = () => this.setState({ cardElementFocused: false });

  handleCardChange = event => {
    if (event.error) {
      this.cardErrorMessage = event.error.message;
    }
  };

  submitPaymentProfile = async billingAddress => {
    const { stripe, elements } = this.props;
    const cardElement = elements.getElement(CardElement);
    // Inject zip code into card element for tokenization
    cardElement.update({
      value: { postalCode: billingAddress.zip }
    });

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: billingAddress.email,
        name: this.state.nameOnCard,
        phone: billingAddress.phone_number,
        address: {
          city: billingAddress.city,
          state: billingAddress.region,
          postal_code: billingAddress.zip,
          line1: billingAddress.address_line_1,
          line2: billingAddress.address_line_2,
          country: billingAddress.country
        }
      }
    });

    if (result.error) {
      throw result.error;
    }

    return {
      paymentToken: result.paymentMethod.id,
      profileInfo: this.formatProfileInfo(billingAddress)
    };
  };

  formatProfileInfo = billingAddress => {
    const firstName = this.state.nameOnCard.split(/\s+/)[0];
    const lastName = this.state.nameOnCard.split(/\s+/).slice(1).join(' ');

    return {
      first_name: firstName,
      last_name: lastName,
      email: billingAddress.email,
      phone_number: billingAddress.phone_number,
      billing_address: {
        address_line_1: billingAddress.address_line_1,
        address_line_2: billingAddress.address_line_2,
        city: billingAddress.city,
        state: billingAddress.region,
        country: billingAddress.country,
        zip_code: billingAddress.zip
      }
    };
  };

  fontFamily
    = '"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

  handleNameChange = e => this.setState({ nameOnCard: e.target.value, nameError: null, cardError: false });

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="formgroup">
          <div className={`edit-card-link ${this.props.isVisible ? 'is-shown' : ''}`} onClick={this.props.handleToggle}>
            &larr; Edit Card Info
          </div>
          {!this.state.ready && <Spinner />}
          <br />
          <Animated animationIn="fadeIn" isVisible={this.props.isVisible && this.state.ready}>
            <div className={`inputwrap ${this.props.isVisible && this.state.ready ? '' : 'is-collapsed'}`}>
              <div className="input-collapse">
                <h4 className="text-left">Card Info</h4>
                <br />
                <div className="wrap-input100">
                  <CardElement
                    className={`input100 StripeElement ${this.state.cardError ? 'is-invalid' : ''}`}
                    onChange={this.handleCardChange}
                    onFocus={this.handleCardFocus}
                    onBlur={this.handleCardBlur}
                    onReady={this.handleReady}
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontFamily: this.fontFamily,
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#4b2354',
                          '::placeholder': {
                            color: '#999',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                  <span className={`focus-input100 ${this.state.cardElementFocused ? 'focused' : ''}`} />
                </div>
                {this.state.cardError && <div className="error-text">{this.state.cardError}</div>}
                <div className="wrap-input100">
                  <input
                    type="text"
                    name="name"
                    className={`input100 ${this.state.nameError !== null ? 'is-invalid' : ''}`}
                    placeholder="Name on Card"
                    value={this.state.nameOnCard}
                    onChange={this.handleNameChange}
                  />
                  <span className="focus-input100" />
                </div>
                {this.state.nameError !== null && <div className="error-text">{this.state.nameError}</div>}
                <div className="btn btn-submit-payment">
                  <button className="btn btn-blue" disabled={!this.props.stripe}>Continue</button>
                </div>
              </div>
            </div>
          </Animated>
        </div>
      </form>
    );
  }
}

class FormWrapper extends Component {
  submitPaymentProfile = async billingAddress => {
    return await this.formRef.submitPaymentProfile(billingAddress);
  };

  render() {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <StripeCreditCardForm
            ref={c => { this.formRef = c; }}
            stripe={stripe}
            elements={elements}
            {...this.props}
          />
        )}
      </ElementsConsumer>
    );
  }
}

export default FormWrapper;
