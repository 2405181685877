import React from 'react';
import { Helmet } from 'react-helmet-async';
import plane from '../../../../images/3_green_planes.svg';
import compass from '../../../../images/compass.svg';
import geo from '../../../../images/geo.svg';
import why1 from '../../../../images/svgs/WhySkyHi_images-01.svg';
import why2 from '../../../../images/svgs/WhySkyHi_images-02.svg';
import why3 from '../../../../images/svgs/WhySkyHi_images-03.svg';
import CTA from '../components/ctaBar';
import Join from '../components/Join';
import Header from '../components/Header';

const currentYear = (new Date()).getFullYear();

const WhySkyHi = () => (
  <div>
    <Helmet>
      <title>Why SkyHi</title>
    </Helmet>
    <Header title="Why SkyHi?" subheader="So simple, it seems too good to be true." />
    <section className="why-section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h3 className="text-center tofrom">
              It
              <span>&#39;</span>s {currentYear}, and travel is different
            </h3>
            <div className="rules-intro-text">
              <p>
                For decades, online travel agencies have promised to get us the right price for airfare. We spend hours
                searching and once we book, unsure if prices will change the next day.
              </p>
              <p>
                With SkyHi, you’ll never experience that uncertainty again. With just a few guidelines, SkyHi is able to
                offer flights that get you where you want to go, without the hassle of booking weeks in advance.
              </p>
              <p>No more pricing games. Fly each month for a flat rate.</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="lessmore">
      <div className="why-left darker-yellow">
        <div className="less-box-header">
          <h3>Spend less.</h3>
          <h3>Fly more.</h3>
        </div>
        <div className="why-box-body">
          <p>
            Pay less than most round trip tickets to fly up to 5 times per month. Stop thinking of how much it
            <span>&#39;</span>s going to cost. Just think about where you want to go.
          </p>
        </div>
        <div className="why-box-icon plane">
          <img className="img-fluid mx-auto d-block" src={plane} alt="plane" />
        </div>
      </div>
      <div className="why-right salmonish">
        <img className="img-fluid mx-auto d-block" src={why1} alt="why1" />
      </div>
    </section>
    <section className="lessmore">
      <div className="why-left light-blue">
        <div className="travel-box-header">
          <h3>
            Don
            <span>&#39;</span>t just travel.
          </h3>
          <h3>Explore.</h3>
        </div>
        <div className="why-box-body">
          <p>
            Go more places each month. Use your membership to fly to new destinations each month. It will always cost
            the same amount.
          </p>
        </div>
        <div className="why-box-icon compass">
          <img className="img-fluid mx-auto d-block" src={compass} alt="radius" />
        </div>
      </div>
      <div className="why-right light-yellow">
        <img className="img-fluid mx-auto d-block" src={why2} alt="why2" />
      </div>
    </section>
    <section className="lessmore">
      <div className="why-left light-yellow">
        <div className="compass-box-header">
          <h3>Pick a place</h3>
          <h3>and go.</h3>
        </div>
        <div className="why-box-body">
          <p>
            Since members can hold up to two one-way tickets at a time, book your trip home or on to your next
            destination before you leave.
          </p>
        </div>
        <div className="why-box-icon geo">
          <img className="img-fluid mx-auto d-block" src={geo} alt="geo" />
        </div>
      </div>
      <div className="why-right light-blue">
        <img className="img-fluid mx-auto d-block" src={why3} alt="why3" />
      </div>
    </section>
    <CTA text="Explore our destinations" link="/destinations/" />
    <Join
      title="Sign up. Start Flying."
      subtext="SkyHi - the flat rate flying club to whatever's next"
      css="join-yellow"
    />
  </div>
);

export default WhySkyHi;
