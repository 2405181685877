import React from 'react';

const Header = props => (
  <section className="top-hero">
    <div className="container">
      <div className="top-hero-content">
        <h2 className="subheader-title">{props.title}</h2>
        <br />
        <div className="subheader-div">
          <p className="subheader-content">{props.subheader}</p>
        </div>
      </div>
    </div>
  </section>
);

export default Header;
