import { SET_GLOBAL_SETTINGS } from '../constants/settings';
import axios from 'axios';

export const setGlobalSettings = payload => ({
  type: SET_GLOBAL_SETTINGS,
  payload
});

export const fetchGlobalSettings = () => dispatch =>
  axios.get(process.env.REACT_APP_API_URL_BASE + '/v1/settings')
    .then(response => dispatch(setGlobalSettings(response.data)));
