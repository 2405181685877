import React, { Fragment } from "react";
import { Route, Switch, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet-async";

/* Scroll to top Component */
import ScrollToTop from "./ScrollToTop";

/* Layout */
import TopNavbar from "./components/layout/nav/TopNavbar";

/* Public Pages  */
import HomePage from "./components/pages/public/home/HomePage";
import HowYoullFly from "./components/pages/public/how_it_works/HowYoullFly";
import WhySkyHi from "./components/pages/public/why/WhySkyHi";
import Destinations from "./components/pages/public/destinations/Destinations";
import Footer from "./components/layout/footer/Footer";
import NoMatch from "./components/layout/NoMatch";

/* SkyHi Status Page */
import Status from "./components/pages/public/status";

/* Legal */
import PrivacyPolicy from "./components/pages/legal/PrivacyPolicy";
import TermsOfService from "./components/pages/legal/TermsOfService";
import JuneTermsOfService from "./components/pages/legal/JuneTermsOfService";
import DecemberTermsOfService from "./components/pages/legal/DecemberTermsOfService";
import FebruaryTermsOfService from "./components/pages/legal/FebruaryTermsOfService";
import NewTermsOfService from "./components/pages/legal/NewTermsOfService";

/* Airline Info */
import Baggage from "./components/pages/public/baggage/Baggage";
import Carriage from "./components/pages/public/baggage/Carriage";

/* Invite */
import InviteLanding from "./components/pages/user/Account_Setup/InviteLanding";

/* --- User Routes --- */

/* Authentication */
import LoginPage from "./components/pages/user/Authentication/LoginPage";

/* Account Onboarding */
import AccountInfo from "./components/pages/user/Account_Setup/AccountInfo";

/* Payment Flow */
import PaymentLanding from "./components/pages/user/Payment/PaymentLanding";
import CreatePayment from "./components/pages/user/Payment/CreatePayment";

/* User Account */
// import TravelDocumentation from './components/pages/user/User_Account/TravelDocumentation';
import Members from "./components/pages/user/User_Account/Members";
import UpdatePayment from "./components/pages/user/Payment/UpdatePayment";
import Bookings from "./components/pages/user/User_Account/Bookings";
import UserAccount from "./components/pages/user/User_Account/AccountInfo";
import PaymentInfo from "./components/pages/user/User_Account/PaymentInfo";

// Redirect helpers
import UserRoute from "./UserRoute";
import LoggedInRoute from "./LoggedInRoute";
import PrePaymentRoute from "./PrePaymentRoute";
import AuthorizedRoute from "./AuthorizedRoute";

// auth status names
import { AUTH_LOGGED_OUT } from "./constants/setAuth";
import Spinner from "./components/pages/Spinner";
import UpdatePaymentHeader from "./components/pages/user/Payment/UpdatePaymentHeader";

const AccountInfoPlaceholder = () => <AccountInfo placeholder />;

const UpdatePaymentPlaceholder = () => (
  <div className="memberPage">
    <div className="container">
      <div className="form-container">
        <UpdatePaymentHeader placeholder />
        <Spinner />
      </div>
    </div>
  </div>
);

const BookingsPlaceholder = () => (
  <Fragment>
    <Helmet>
      <title>Trips</title>
    </Helmet>
    <Spinner />
  </Fragment>
);

const UserAccountPlaceholder = () => (
  <Fragment>
    <Helmet>
      <title>Account Info</title>
    </Helmet>
    <Spinner />
  </Fragment>
);

const PaymentInfoPlaceholder = () => (
  <Fragment>
    <Helmet>
      <title>Payment Info</title>
    </Helmet>
    <Spinner />
  </Fragment>
);

const AppRoute = ({
  render,
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const mainRender = render || ((props) => <Component {...props} />);
  return (
    <Route {...rest} render={(props) => <Layout>{mainRender(props)}</Layout>} />
  );
};

const MainLayout = (props) => (
  <div>
    <TopNavbar />
    {props.children}
    <Footer />
  </div>
);

const MembersLayout = (props) => (
  <div>
    <TopNavbar />
    <Members>{props.children}</Members>
    <Footer />
  </div>
);

const AltLayout = (props) => (
  <div>
    <Helmet>
      <title>Account Setup</title>
    </Helmet>
    {props.children}
  </div>
);

const App = () => (
  <ScrollToTop>
    <Helmet>
      <title>SkyHi</title>
    </Helmet>

    <CookieConsent
      location="bottom"
      buttonText="I Agree"
      cookieName="cookie-policy"
      style={{ background: "#2B373B" }}
      buttonStyle={{
        color: "#ffffff",
        fontSize: "13px",
        background: "#ff9c80",
        fontWeight: "bold",
        cursor: "pointer",
      }}
      expires={150}
    >
      We use cookies to provide a better experience for our users. By using our
      website, you consent to the use of cookies in accordance with our privacy
      and <Link to="/legal/privacy/">cookie policy</Link>
    </CookieConsent>

    <div className="sitewrapall">
      <span id="app-is-rendered" />
      <div className="page">
        <Switch>
          {/* Public Routes */}
          <AppRoute path="/" exact layout={MainLayout} component={HomePage} />
          <AppRoute
            path="/how-youll-fly/"
            exact
            layout={MainLayout}
            component={HowYoullFly}
          />
          <AppRoute
            path="/why-skyhi/"
            exact
            layout={MainLayout}
            component={WhySkyHi}
          />
          <AppRoute
            path="/destinations/"
            exact
            layout={MainLayout}
            component={Destinations}
          />
          <AppRoute
            path="/skyhi-status/"
            exact
            layout={MainLayout}
            component={Status}
          />
          {/* User Routes */}
          <AppRoute
            path="/login/"
            exact
            layout={AltLayout}
            render={() => (
              <UserRoute
                component={LoginPage}
                placeholder={LoginPage}
                match={({ authStatus }) => authStatus === AUTH_LOGGED_OUT}
                redirect={({ filledOutAccountInfo, hasSubscription }) =>
                  !filledOutAccountInfo
                    ? "/members/account-info/"
                    : !hasSubscription
                    ? "/members/skyhi-info/"
                    : "/members/account/"
                }
              />
            )}
          />
          <AppRoute
            path="/members/account-info/"
            exact
            layout={AltLayout}
            render={() => (
              <LoggedInRoute
                component={AccountInfo}
                match={({ filledOutAccountInfo }) => !filledOutAccountInfo}
                redirect={({ hasSubscription }) =>
                  hasSubscription ? "/members/account/" : "/members/skyhi-info/"
                }
                placeholder={AccountInfoPlaceholder}
              />
            )}
          />
          <AppRoute
            path="/members/skyhi-info/"
            exact
            layout={AltLayout}
            render={() => (
              <PrePaymentRoute
                component={PaymentLanding}
                placeholder={PaymentLanding}
              />
            )}
          />
          <AppRoute
            path="/members/payment-details/"
            layout={AltLayout}
            render={() => <PrePaymentRoute component={CreatePayment} />}
          />
          {/* <AppRoute
            path="/travel-documentation"
            exact
            layout={AltLayout}
            render={() => <AuthorizedRoute component={TravelDocumentation} />}
          /> */}
          <AppRoute
            path="/members/account/"
            exact
            layout={MembersLayout}
            render={() => (
              <AuthorizedRoute
                component={UserAccount}
                placeholder={UserAccountPlaceholder}
              />
            )}
          />
          <AppRoute
            path="/members/account/trips/"
            exact
            layout={MembersLayout}
            render={() => (
              <AuthorizedRoute
                component={Bookings}
                placeholder={BookingsPlaceholder}
              />
            )}
          />
          <AppRoute
            path="/members/account/payment-info/"
            exact
            layout={MembersLayout}
            render={() => (
              <AuthorizedRoute
                component={PaymentInfo}
                placeholder={PaymentInfoPlaceholder}
              />
            )}
          />
          <AppRoute
            path="/members/account/edit-payment/"
            exact
            layout={MainLayout}
            render={() => (
              <LoggedInRoute
                component={UpdatePayment}
                match={({ hasSubscription, pastShutoff }) =>
                  hasSubscription && !pastShutoff
                }
                redirect={({ filledOutAccountInfo, pastShutoff }) =>
                  !filledOutAccountInfo
                    ? "/members/account-info"
                    : pastShutoff
                    ? "/skyhi-status"
                    : "/members/skyhi-info"
                }
                placeholder={UpdatePaymentPlaceholder}
              />
            )}
          />
          {/* Legal Info Routes */}
          <AppRoute
            path="/invited/*"
            layout={MainLayout}
            component={InviteLanding}
          />
          <AppRoute
            path="/invited"
            layout={MainLayout}
            component={InviteLanding}
          />
          <AppRoute
            path="/wework/"
            layout={MainLayout}
            component={InviteLanding}
          />
          <AppRoute
            path="/techstars/"
            layout={MainLayout}
            component={InviteLanding}
          />
          <AppRoute
            path="/legal/privacy/"
            layout={MainLayout}
            component={PrivacyPolicy}
          />
          <AppRoute
            path="/legal/past-terms/"
            layout={MainLayout}
            component={TermsOfService}
          />
          <AppRoute
            path="/legal/terms-june2017/"
            layout={MainLayout}
            component={JuneTermsOfService}
          />
          <AppRoute
            path="/legal/terms-december2017/"
            layout={MainLayout}
            component={DecemberTermsOfService}
          />
          <AppRoute
            path="/legal/terms-february2018/"
            layout={MainLayout}
            component={FebruaryTermsOfService}
          />
          <AppRoute
            path="/legal/terms/"
            layout={MainLayout}
            component={NewTermsOfService}
          />
          <AppRoute path="/baggage/" layout={MainLayout} component={Baggage} />
          <AppRoute
            path="/airline-contracts-of-carriage/"
            layout={MainLayout}
            component={Carriage}
          />
          <AppRoute layout={MainLayout} component={NoMatch} />
        </Switch>
      </div>
    </div>
  </ScrollToTop>
);
export default App;
