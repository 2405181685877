import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';

const MemberNavLink = ({ to, active, linkText }) => (
  <li className={active ? 'nav__links membernav-active' : 'nav__links'}>
    <Link to={to}>{linkText}</Link>
  </li>
);

class Members extends Component {
  renderNavItem(path, linkText) {
    return (
      <Switch>
        <Route exact path={path} render={() => <MemberNavLink active to={path} linkText={linkText} />} />
        <Route render={() => <MemberNavLink to={path} linkText={linkText} />} />
      </Switch>
    );
  }

  render() {
    return (
      <div className="account">
        <section className="members-nav">
          <div className="container">
            <ul className="membernav">
              {this.renderNavItem('/members/account/', 'Account Info')}
              {this.renderNavItem('/members/account/trips/', 'My Trips')}
              {this.renderNavItem('/members/account/payment-info/', 'Payment Info')}
            </ul>
          </div>
        </section>
        <div className="memberPage">
          <div className="container">
            <div className="form-container">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Members;
