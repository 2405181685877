import axios from 'axios';
import { SET_LOCATION, FETCHING_LOCATION } from '../constants/location';

export const setLocation = payload => ({
  type: SET_LOCATION,
  payload
});

export const fetchingLocation = () => ({
  type: FETCHING_LOCATION
});

export const fetchLocation = () => dispatch => {
  return axios.get(process.env.REACT_APP_API_URL_BASE + '/v1/ip-location')
    .then(response => response.data.location)
    .then(payload => dispatch(setLocation(payload)))
    .catch(error => dispatch(setLocation(null)));
}
