import React from "react";
import { Link, Switch, Route } from "react-router-dom";

export default function TopBanner() {
  return (
    <Switch>
      <Route
        path="/skyhi-status/"
        exact
        render={() => <span />}
      />
      <Route
        render={() => (
          <div className="republic__banner">
            <p>
              SkyHi service has been paused due to COVID-19. Click{" "}
              <Link to="/skyhi-status/">here</Link> for more information.
            </p>
          </div>
        )}
      />
    </Switch>
  );
}
