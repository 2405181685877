import React from 'react';
import { Animated } from 'react-animated-css'
import BillingAddressForm from './BillingAddressForm';

const BillingAddress = ({ isVisible, handleSubmit, initialValues, initialValuesReady }) => (
  <Animated animationIn="fadeIn" isVisible={isVisible}>
    <div className={`inputwrap ${isVisible ? 'not-collapsed' : 'is-collapsed'}`}>
      <div className="input-collapse">
        <h4 className="text-left">Billing Address</h4>
        <br />
        {initialValuesReady ? (
          <BillingAddressForm
            handleSubmit={handleSubmit}
            initialValues={initialValues}
          />
        ) : null}
      </div>
    </div>
  </Animated>
);

export default BillingAddress;
