import React, { Component } from 'react';
import { Animated } from 'react-animated-css';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../utils';
import badge from '../../../../images/badge.png';

class AnetCreditCardForm extends Component {
  state = {
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    issuer: '',
    focused: ''
  };

  submitPaymentProfile = async billingAddress => {
    const formData = this.getFormData();

    const secureData = {};
    const authData = {};

    const paymentData = {
      cardNumber: formData.number,
      month: formData.month,
      year: formData.year,
      cardCode: formData.cvc,
      zip: billingAddress.zip,
    };

    authData.clientKey = process.env.REACT_APP_ANET_CLIENT_KEY;
    authData.apiLoginID = process.env.REACT_APP_ANET_LOGIN_ID;

    secureData.cardData = paymentData;
    secureData.authData = authData;

    const paymentToken = await this.dispatchPaymentInfo(secureData);

    return {
      paymentToken,
      profileInfo: this.formatProfileInfo(formData, billingAddress)
    };
  };

  dispatchPaymentInfo = data => new Promise((resolve, reject) => {
    window.Accept.dispatchData(data, response => {
      if (response.messages.resultCode === 'Error') {
        return reject({ message: response.messages.message[0].text });
      }

      resolve(response.opaqueData.dataValue);
    });
  });

  formatProfileInfo = (formData, billingAddress) => ({
    first_name: formData.firstName,
    last_name: formData.lastName,
    email: billingAddress.email,
    phone_number: billingAddress.phone_number,
    billing_address: {
      address_line_1: billingAddress.address_line_1,
      address_line_2: billingAddress.address_line_2,
      city: billingAddress.city,
      state: billingAddress.region,
      country: billingAddress.country,
      zip_code: billingAddress.zip
    }
  });

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit();
  };
  
  getFormData = () => {
    const { number, name, expiry, cvc } = [...this.form.elements].filter(d => d.name).reduce((acc, d) => {
      acc[d.name] = d.value;
      return acc;
    }, {});

    return {
      number: number.replace(/\s+/g, ''),
      month: expiry.substring(0, 2).trim(),
      year: expiry.slice(-2),
      cvc,
      firstName: name.split(/\s+/)[0],
      lastName: name.split(/\s+/).slice(1).join(' ')
    };
  };

  handleBlur = () => {
    this.setState({ focused: '' });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleInputFocus = ({ target }) => {
    this.setState({ focused: target.name });
  };

  setIssuerCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  render() {
    const { number, name, expiry, cvc, issuer, focused } = this.state;
    return (
      <form ref={c => { this.form = c; }} onSubmit={this.handleSubmit}>
        <div className="formgroup">
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.setIssuerCallback}
          />
          <div className={`edit-card-link ${this.props.isVisible ? 'is-shown' : ''}`} onClick={this.props.handleToggle}>
            Edit Card Info
          </div>
          <br />
          <Animated animationIn="fadeIn" isVisible={this.props.isVisible}>
            <div className={`inputwrap ${this.props.isVisible ? '' : 'is-collapsed'}`}>
              <div className="input-collapse">
                <div className="wrap-input100">
                  <input
                    type="tel"
                    name="number"
                    className="input100"
                    placeholder="Card Number"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                  <span className="focus-input100" />
                </div>
                <div className="wrap-input100">
                  <input
                    type="text"
                    name="name"
                    className="input100"
                    placeholder="Name"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                  <span className="focus-input100" />
                </div>
                <div className="input-flex-wrapper">
                  <div className="wrap-input100">
                    <input
                      type="tel"
                      name="expiry"
                      className="input100"
                      placeholder="Exp. mm/dd"
                      pattern="\d\d/\d\d"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                    <span className="focus-input100" />
                  </div>
                  <div className="wrap-input100 last">
                    <input
                      type="tel"
                      name="cvc"
                      className="input100"
                      placeholder="CVC"
                      pattern="\d{3,4}"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                      onBlur={this.handleBlur}
                    />
                    <span className="focus-input100" />
                  </div>
                </div>
                <input type="hidden" name="issuer" value={issuer} />
                <div className="btn btn-submit-payment">
                  <button className="btn btn-blue">Continue</button>
                  <img src={badge} className="img-fluid authbadge" alt={badge} />
                </div>
              </div>
            </div>
          </Animated>
        </div>
      </form>
    );
  }
}

export default AnetCreditCardForm;
