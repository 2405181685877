import axios from 'axios';

import {
  SET_DESTINATIONS_ALL,
  SET_DESTINATIONS_FEATURED,
  SET_DESTINATIONS_META
} from '../constants/destinations';

const setDestinationsAll = payload => ({
  type: SET_DESTINATIONS_ALL,
  payload
});

const setDestinationsFeatured = payload => ({
  type: SET_DESTINATIONS_FEATURED,
  payload
});

const setDestinationsMeta = payload => ({
  type: SET_DESTINATIONS_META,
  payload
});

export const fetchDestinationsAll = () => dispatch =>
  axios.get(process.env.REACT_APP_API_URL_BASE + '/v1/airports/featured/all')
    .then(response => {
      dispatch(setDestinationsAll(response.data.airports));
      return response;
    });

export const fetchDestinationsFeatured = coords => dispatch =>
  axios.get(process.env.REACT_APP_API_URL_BASE + '/v1/airports/featured', {
    params: {
      lat: coords.latitude,
      long: coords.longitude,
    }
  })
    .then(response => {
      dispatch(setDestinationsFeatured(response.data.airports));
      return response;
    });

export const fetchDestinationsMeta = () => dispatch =>
  axios.get(process.env.REACT_APP_API_URL_BASE + '/v1/flights/meta')
    .then(response => {
      dispatch(setDestinationsMeta(response.data));
      return response;
    });
