import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import rootReducer from './rootReducer';
import App from './App';
import AuthProvider from './AuthProvider';
import LocationProvider from './LocationProvider';
import SettingsProvider from './SettingsProvider';

const BrowserApp = () => {
  // Create the redux store. There might be initial state embedded in the dom
  const initialStateNode = document.getElementById('prerender-state');
  const initialState = initialStateNode ? JSON.parse(initialStateNode.innerHTML) : {};
  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
  // Load the stripe library
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(publishableKey);

  return (
    <HelmetProvider>
      <Elements stripe={stripePromise}>
        <ReduxProvider store={store}>
          <AuthProvider>
            <SettingsProvider>
              <LocationProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </LocationProvider>
            </SettingsProvider>
          </AuthProvider>
        </ReduxProvider>
      </Elements>
    </HelmetProvider>
  );
}

export default BrowserApp;
