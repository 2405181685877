import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import isBrowser from '../../../isBrowser';

const DecemberTermsOfServiceContents = lazy(() => import('./DecemberTermsOfServiceContents'));

const contents = isBrowser() ? (
  <Suspense fallback={<span />}>
    <DecemberTermsOfServiceContents />
  </Suspense>
) : <span />;

const DecemberTermsOfService = () => (
  <div>
    <Helmet>
      <title>Terms of Service (December 2017)</title>
    </Helmet>
    {contents}
  </div>
);

export default DecemberTermsOfService;
