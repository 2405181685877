import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPaymentInfo } from '../../../../actions/paymentInfo';
import PaymentFlow from './PaymentFlow';
import UpdatePaymentHeader from './UpdatePaymentHeader';
import api from '../../../../api';


const UPDATE_BRANCH_LINK = 'https://wfbz.app.link/XVVgT2G3nV';

class UpdatePayment extends Component {
  componentDidMount() {
    this.fetchPaymentInfo();
  }

  fetchPaymentInfo = () => {
    this.props.fetchPaymentInfo(localStorage.jwt);
  }

  submitProfileInfo = async ({ token, profile }) => {
    const jwt = localStorage.jwt;
    return api.subscription.updateSubscription({ token, profile, jwt });
  };

  handleSuccessfulPaymentConfirmation = () => {
    this.props.history.push('/members/account/payment-info/');
  };

  render() {
    return (
      <PaymentFlow
        branchLink={UPDATE_BRANCH_LINK}
        submitProfileInfo={this.submitProfileInfo}
        headerComponent={UpdatePaymentHeader}
        onSuccessfulPayment={this.fetchPaymentInfo}
        onSuccessfulPaymentConfirmation={this.handleSuccessfulPaymentConfirmation}
        successText="Your credit card has been successfully updated"
        initialValuesReady={this.props.initialValuesReady}
        initialValues={this.props.initialValues}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValuesReady: !!(!state.paymentInfo.fetching || state.paymentInfo.data.email),
  initialValues: {
    email: state.paymentInfo.data.email,
    phone_number: state.paymentInfo.data.phone_number,
    country: state.paymentInfo.data.billing_address.country,
    region: state.paymentInfo.data.billing_address.state,
    address_line_1: state.paymentInfo.data.billing_address.address_line_1,
    address_line_2: state.paymentInfo.data.billing_address.address_line_2,
    city: state.paymentInfo.data.billing_address.city,
    zip: state.paymentInfo.data.billing_address.zip_code
  }
});

export default connect(mapStateToProps, { fetchPaymentInfo })(withRouter(UpdatePayment));
