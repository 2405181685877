import React from 'react';
import PropTypes from 'prop-types';
import LoggedInRoute from './LoggedInRoute';

const PrePaymentRoute = ({ component, placeholder }) => (
  <LoggedInRoute
    component={component}
    match={({ hasSubscription, filledOutAccountInfo, pastShutoff }) =>
      filledOutAccountInfo && !hasSubscription && !pastShutoff
    }
    redirect={({ filledOutAccountInfo, pastShutoff }) =>
      pastShutoff
        ? "/skyhi-status"
        : filledOutAccountInfo
          ? "/members/account/"
          : "/members/account-info/"
    }
    placeholder={placeholder}
  />
);

PrePaymentRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  placeholder: PropTypes.elementType.isRequired
};

PrePaymentRoute.defaultProps = {
  placeholder: () => <span />
};

export default PrePaymentRoute;
