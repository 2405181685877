import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import axios from 'axios';
import { grabUser } from './actions/user';
import {
  AUTH_PENDING,
  AUTH_LOGGED_IN
} from './constants/setAuth';
import moment from 'moment';

/**
 * Render the given component when the user has been fetched from the server
 */
class UserRoute extends Component {
  componentDidMount() {
    if (this.props.authStatus === AUTH_LOGGED_IN) {
      this.props.grabUser(localStorage.jwt);
      this.postParams('cmd userRoute');
    }
  }

  // If the auth status changes to AUTH_LOGGED_IN, we want to initiate the user fetch.
  componentDidUpdate(prevProps) {
    if (this.props.authStatus === AUTH_LOGGED_IN && prevProps.authStatus !== AUTH_LOGGED_IN) {
      this.props.grabUser(localStorage.jwt);
      this.postParams('cdu userRoute');
    }
  }

  postParams = params => {
    // axios({
    //   url: process.env.REACT_APP_API_URL_BASE + '/v1/meta/print',
    //   method: 'post',
    //   params,
    // });
  };

  renderRedirect(info) {
    const path = this.props.redirect(info);
    return <Redirect to={path} />;
  }

  renderComponent() {
    const Component = this.props.component;
    return <Component />;
  }

  renderConditionally() {
    const { authStatus, filledOutAccountInfo, hasSubscription, pastShutoff } = this.props;
    const info = { authStatus, filledOutAccountInfo, hasSubscription, pastShutoff };

    if (this.props.match(info)) {
      // This route matches the current user data, go ahead and render
      return this.renderComponent();
    }

    // We need to redirect
    return this.renderRedirect(info);
  }

  // This can be used to render a spinner or something while we wait for info from the server
  renderPlaceholder() {
    const Placeholder = this.props.placeholder;
    return <Placeholder />;
  }

  render() {
    if (this.props.authStatus === AUTH_PENDING) {
      return this.renderPlaceholder();
    }
    if (this.props.shutoffDate === null || (this.props.authStatus === AUTH_LOGGED_IN && !this.props.finishedFetchingUser)) {
      return this.renderPlaceholder();
    }

    return this.renderConditionally();
  }
}

UserRoute.propTypes = {
  // The component to be rendered
  component: PropTypes.elementType.isRequired,
  // If we need to redirect, this function is called to find the redirect path.
  redirect: PropTypes.func.isRequired,
  // A function that accepts { authStatus: string, hasSubscription: bool, filledOutAccountInfo: bool }
  // and returns true if the route should be rendered. If the route shouldn't be rendered, then
  // a Redirect is rendered based on the result of the redirect prop.
  match: PropTypes.func.isRequired,

  grabUser: PropTypes.func.isRequired,
  authStatus: PropTypes.string.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  filledOutAccountInfo: PropTypes.bool.isRequired,
  finishedFetchingUser: PropTypes.bool.isRequired,
  pastShutoff: PropTypes.bool.isRequired,
  placeholder: PropTypes.elementType.isRequired
};

UserRoute.defaultProps = {
  placeholder: () => <span />
};

const mapStateToProps = state => {
  const user = state.user;
  const authStatus = state.auth.status;
  const shutoffDate = state.settings.plan_b.shutoff_date;
  const pastShutoff = state.settings.plan_b.initiated &&
    moment().isAfter(moment(shutoffDate));

  if (!user.user.first_name) {
    return {
      authStatus,
      pastShutoff,
      shutoffDate,
      finishedFetchingUser: false,
      filledOutAccountInfo: false,
      hasSubscription: false,
    };
  }

  return {
    authStatus,
    pastShutoff,
    shutoffDate,
    finishedFetchingUser: true,
    filledOutAccountInfo: user.meta.disclaimers.length > 0,
    hasSubscription: !!user.meta.subscription_status,
  };
};

export default connect(
  mapStateToProps,
  { grabUser }
)(UserRoute);
