import React from 'react';
import PropTypes from 'prop-types';
import UserRoute from './UserRoute';

import {
  AUTH_LOGGED_OUT
} from './constants/setAuth';

const LoggedInRoute = ({ component, match, redirect, placeholder }) => (
  <UserRoute
    component={component}
    match={({ authStatus, ...rest }) =>
      authStatus === AUTH_LOGGED_OUT ? false : match(rest)
    }
    redirect={({ authStatus, ...rest }) =>
      authStatus === AUTH_LOGGED_OUT ? '/login' : redirect(rest)
    }
    placeholder={placeholder}
  />
);

LoggedInRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  match: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  placeholder: PropTypes.elementType.isRequired
};

LoggedInRoute.defaultProps = {
  placeholder: () => <span />
};

export default LoggedInRoute;
