import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => (
        <div id="terms-page">
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <div className="hero">
                <div className="container">
                    <h2>Privacy and Cookie Policy</h2>
                    <a href="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/SkyHi+-+Privacy+Policy.pdf">Download Privacy Policy</a>
                </div>
            </div>

            <div className="container terms_of_service clearfix">

                <p className="p1"><span className="s1"><strong>SkyHi Travel, Inc.</strong></span></p>
                <p className="p1"><span className="s1"><strong>Privacy and Cookie Policy</strong></span></p>
                <p className="p1"><span className="s1"><strong>Last Updated: June 6, 2017</strong></span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">SkyHi Travel, Inc. (&ldquo;<strong>SkyHi</strong>&rdquo;) is committed to protecting your privacy.&nbsp; We have prepared this Privacy Policy to describe to you our practices regarding the Personal Data (as defined below) we collect from users of our websites with links to this Privacy Policy (the &ldquo;<strong>Site</strong>&rdquo;), our mobile application (the &ldquo;<strong>Application</strong>&rdquo;), and our services available through the Site and Application (<strong>&ldquo;Services&rdquo;</strong>).&nbsp;</span></p>
                <ol className="ol1">
                    <li className="li3"><span className="s1"><strong>Questions; Contacting SkyHi</strong>.&nbsp; If you have any questions or comments about our Privacy Policy, please contact us at the following:</span></li>
                </ol>
                <p className="p4"><span className="s1">SkyHi Travel, Inc.</span></p>
                <p className="p4"><span className="s1">13614 Sunset Drive</span></p>
                <p className="p4"><span className="s1">Apple Valley, CA 92308</span></p>
                <p className="p4"><span className="s1">Attn: Support</span></p>
                <p className="p4"><span className="s1">E-mail: support@flyskyhi.com</span></p>
                <p className="p5">&nbsp;</p>
                <ol className="ol1">
                    <li className="li3"><span className="s1"><strong>User Consent.&nbsp; </strong>By visiting our Site, using our Application, or using our Services, you agree to the terms of this Privacy Policy and you expressly consent to the collection, use and disclosure of your Personal Data in accordance with this Privacy Policy.</span></li>
                    <li className="li3"><span className="s1"><strong>A Note About Children.&nbsp; </strong>We do not intentionally gather Personal Data from visitors who are under the age of 13.&nbsp; If we discover that a child under 13 has submitted Personal Data to SkyHi, we will attempt to delete the information as soon as possible.&nbsp; If you believe that we might have any Personal Data from a child under 13, please contact us at support@flyskyhi.com.</span></li>
                    <li className="li3"><span className="s1"><strong>A Note to Users Outside of the United States.&nbsp; Your Personal Data may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding the processing of Personal Data may be less protective than the laws in your country.&nbsp; By providing your data, you consent to such processing and transfer.&nbsp;</strong></span></li>
                    <li className="li3"><span className="s1"><strong>Types of Data We Collect And Use.&nbsp; </strong>Subject to Sections 11 and 12, <strong>&ldquo;Personal Data&rdquo;</strong> means data that allows someone to identify or contact you as an individual, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about you that is associated with or linked to any of the foregoing data.&nbsp; <strong>&ldquo;Anonymous Data&rdquo;</strong> means data that does not, by itself or in combination with other data that, alone, would be anonymous, permit the identification of individual persons and that is not associated with or linked to Personal Data.&nbsp; We and our authorized service providers collect and use Personal Data and Anonymous Data, as described below.</span></li>
                    <ol className="ol2">
                        <li className="li3"><span className="s1"><strong>Information You Provide to Us.&nbsp;</strong></span></li>
                    </ol></ol>
                <ul>
                    <li className="li6"><span className="s1">We will collect your first and last name, e-mail address, and password when you create an account to log in to our Services (&ldquo;<strong>Account</strong>&rdquo;). Your Account information is used to identify you as a user in our system. In addition, we will send you a welcome e-mail to verify the ownership of the e-mail address you provided for your Account. We may also use your e-mail to send you administrative e-mail notifications, such as security, or support and maintenance advisories.&nbsp;</span></li>
                    <li className="li6"><span className="s1">In order to process your airline ticket orders, we will collect all necessary information to process and issue you an airline ticket, including without limitation, your full legal name, address, birthdate, gender, frequent flyer number, redress number, known traveler number, contact information, passport information, and payment information.</span></li>
                    <li className="li6"><span className="s1">If you apply to be a SkyHi Ambassador, we will collect your first and last name, e-mail address, location, social media information, and other information in connection with your public online profile as required in order to assess your qualifications as a potential SkyHi Ambassador.</span></li>
                    <li className="li6"><span className="s1">In order to provide a tailored Application experience, we will collect information regarding your travel preferences, frequency, and types of travel.</span></li>
                    <li className="li6"><span className="s1">In order to improve the Application experience, we will collect feedback regarding your experience and ask questions about what features you may want to see in the Application.</span></li>
                    <li className="li6"><span className="s1">If you subscribe to our e-mail newsletter, we will collect your e-mail address, name, and travel preferences.</span></li>
                    <li className="li6"><span className="s1">If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in your e-mail to us, in order to send you a reply. &nbsp;</span></li>
                    <li className="li6"><span className="s1">We will also collect and/or use Personal Data at other points in our Site, Application, or Services that state that Personal Data is being collected and/or used for any other purposes disclosed to you at that time, pursuant to your consent.</span></li>
                </ul>
                <p className="p7">&nbsp;</p>
                <ol className="ol1"><ol className="ol2">
                    <li className="li3"><span className="s1"><strong>Information You Provide to Social Networking Sites.</strong> &nbsp;</span></li>
                </ol></ol>
                <ul>
                    <li className="li6"><span className="s1">If you elect to sign in to your Account via a social networking site (&ldquo;<strong>SNS</strong>&rdquo;) or login to your SNS account to post a comment on our blog, you are allowing us to access information stored by the SNS, including, without limitation, information in your public profile and your e-mail address, and you are agreeing to the SNS&rsquo;s terms of use in your use of the Services.&nbsp; Any information that we collect from your SNS account may depend on the privacy settings you have selected with that SNS, so please consult the SNS&rsquo;s privacy policy and data practices. We will use information collected from the SNS for the purposes of tailoring your user experience on our Services and enabling you to share content. You can revoke our access to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the applicable SNS.</span></li>
                </ul>
                <p className="p7">&nbsp;</p>
                <ol className="ol1"><ol className="ol2">
                    <li className="li3"><span className="s1"><strong>Information Collected via Technology.</strong></span></li>
                </ol></ol>
                <ul>
                    <li className="li6"><span className="s3">Information Collected by Our Servers</span><span className="s1">.&nbsp; To provide improved administration of our Site, Application, and Services to you, and to measure and analyze audience traffic and improve the quality of your experience with our Site Application and Services, our servers (which may be hosted by a third-party service provider) may collect information from your computer or device, including but not limited to:</span></li>
                    <ul>
                        <li className="li6"><span className="s1">The date and time of your visit and the web pages, content, and advertisements you view and links you click on while navigating within our Services;</span></li>
                        <li className="li6"><span className="s1">Information about the type of content accessed via our Services;</span></li>
                        <li className="li6"><span className="s1">The site you visited before and after visiting our Site;</span></li>
                        <li className="li6"><span className="s1">Your Internet Protocol (IP) address (a numerical address assigned to your computer by your Internet service provider so that other computers connected to the Internet can communicate with your browser online) that can sometimes be used to derive your general geographic area;</span></li>
                        <li className="li6"><span className="s1">Search terms you enter using our Services or on a referral site;</span></li>
                        <li className="li6"><span className="s1">Unique identifiers, including non-global mobile device identification numbers;</span></li>
                        <li className="li6"><span className="s1">Information about your device such as your device type, screen size, browser type, language and other settings, memory capacity, plug-ins, Internet domain, advertising identifiers, TCP configuration parameters, operating system, carrier code, time zone and the names, versions and package IDs of software installed on your device; </span><span className="s4">and</span></li>
                        <li className="li6"><span className="s1">Information collected through cookies and other tracking technologies (see additional descriptions of these terms below).</span></li>
                    </ul>
                    <li className="li6"><span className="s3">Cookies</span><span className="s1">.&nbsp; Like many online services, we use cookies to collect information.&nbsp; <strong>&ldquo;Cookies&rdquo;</strong> are small pieces of information that a website sends to your computer&rsquo;s hard drive while you are viewing the website.&nbsp; We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Services. For example, we may use Cookies to recognize you as a previous visitor, and save and remember your preferences and settings, and to deliver appropriate interest-based content to you, including by displaying promotional material tailored to you. All Cookies, and the third party analytics cookies described below, will expire after 30 days.&nbsp;</span></li>
                    <li className="li6"><span className="s3">Social Media Cookies.</span><span className="s1"> These cookies are used when you share information using a social media sharing button or &ldquo;like&rdquo; button on our Site or Application or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this.</span></li>
                    <li className="li6"><span className="s3">Pixel Tag</span><span className="s1">s. In addition, we use <strong>&ldquo;Pixel Tags</strong>&rdquo; (also referred to as clear Gifs, Web beacons, or Web bugs).&nbsp; Pixel Tags are tiny graphic images with a unique identifier, similar in function to Cookies, that are used to track online movements of Web users.&nbsp; In contrast to Cookies, which are stored on a user&rsquo;s computer hard drive, Pixel Tags are embedded invisibly in Web pages.&nbsp; Pixel Tags also allow us to send e-mail messages in a format users can read, and they tell us whether e-mails have been opened to ensure that we are sending only messages that are of interest to our users.&nbsp;</span></li>
                    <li className="li6"><span className="s3">Ad Targeting</span><span className="s1">.&nbsp; Third parties, including Facebook, may use cookies, web beacons, and other storage technologies to collect or receive information from the Site, the Application, and elsewhere on the internet and use that information to provide measurement services and target ads.</span></li>
                    <li className="li6"><span className="s3">Do Not Track</span><span className="s1">. We do not currently respond to &ldquo;do not track&rdquo; signals from web browsers.&nbsp;</span></li>
                    <li className="li6"><span className="s3">Analytics Services</span><span className="s1">. We use third-party analytics services including Google Analytics (&ldquo;<strong>Analytics Services</strong>&rdquo;), to help analyze how users use our Site.&nbsp; The information generated by the Cookies or other technologies about your use of our Site (the &ldquo;<strong>Analytics Information</strong>&rdquo;) is transmitted to the Analytics Services. The information gathered does not identify any individual visitor. The information is aggregated and therefore anonymous. The Analytics Services use Analytics Information to compile reports on user activity. The Analytics Services may also transfer the Analytics Information to third parties where required to do so by law, or where such third parties process Analytics Information on their behalf. Each Analytics Service&rsquo;s ability to use and share Analytics Information is restricted by such Analytics Service&rsquo;s terms of use and privacy policy.&nbsp; By using our Site, you consent to the processing of data about you by Analytics Services in the manner and for the purposes set out above. For a full list of Analytics Services, please contact us at support@flyskyhi.com.&nbsp; To learn more about how Google uses data, visit&nbsp; <a href="http://www.google.com/policies/privacy/partners/"><span className="s5">www.google.com/policies/privacy/partners/</span></a>. You can prevent the use of Google Analytics relating to your use of [our Site/Application(s)/Services] by downloading and installing the browser plugin available via this link: <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB"><span className="s5">http://tools.google.com/dlpage/gaoptout?hl=en-GB</span></a></span><span className="s5">.</span></li>
                    <li className="li6"><span className="s3">Location Tracking</span><span className="s1">.&nbsp; If you are using our Application and have enabled location services on your phone or if you are using our Application-related Site, we collect your location information to determine your closest airport, determine destinations you can fly to based on that location, and confirm whether you have actually taken the flight that you booked through our Services. By using the location-based services, you authorize us to: (i) locate your hardware; (ii) record, compile and display your location; and (iii) publish your location to third parties designated by you by means of location publication controls available within the applications (for example, settings, user preferences). As part of the location-based services, we may also collect and store certain information about the users who elect to use such location-based services, such as a device ID. This information will be used to provide you the location-based services. We may use third-party providers to help provide location-based services through mobile systems and we may provide the information to such providers to enable them to provide their location-based services, provided that such providers may use the information in accordance with this Privacy Policy.</span></li>
                    <li className="li6"><span className="s3">Automated Decision Making and Profiling. </span><span className="s1">We may use your personal data for the purposes of automated decision-making when displaying flights and offers based on your preferences (for instance, we may show you flights for beach destinations on offer if you seem to buy tickets for such flights regularly). We may also use automated decision making in order to fulfil obligations imposed by law, in which case we will inform you of any such processing and provide you with an opportunity to object.</span></li>
                </ul>
                <p className="p7">&nbsp;</p>
                <ol className="ol2">
                    <li className="li6"><span className="s1"><strong>Information Collected from You About Others</strong>.&nbsp; If you decide to invite a third party to create an Account, we will collect your and the third party&rsquo;s name and e-mail address in order to send an e-mail and follow up with the third party.&nbsp; We rely upon you to obtain whatever consents from the third party that may be required by law to allow us to access and upload the third party&rsquo;s name and e-mail address as required above.&nbsp; You or the third party may contact us at contact@flyskyhi.com to request the removal of this information from our database. &nbsp;</span></li>
                </ol>
                <p className="p8">&nbsp;</p>
                <ol className="ol2">
                    <li className="li6"><span className="s1"><strong>User Testimonials and Feedback.</strong>&nbsp; We often receive testimonials and comments from users who have had positive experiences with our Services.&nbsp; We occasionally publish such content.&nbsp; We obtain the user&rsquo;s consent prior to posting his or her Personal Data along with the testimonial. &nbsp;</span></li>
                </ol>
                <p className="p9">&nbsp;</p>
                <ol className="ol2">
                    <li className="li6"><span className="s1"><strong>Prohibited activity. </strong>As further described in Section 6(e), we and our authorized service providers may use your Personal Data to prevent prohibited or illegal activity, to enforce our Terms of Use Agreement (<a href="http://flyskyhi.com/legal/terms-of-use"><span className="s5">flyskyhi.com/legal/terms-of-use</span></a>), or as required to comply with applicable law.&nbsp;</span></li>
                </ol>
                <p className="p9">&nbsp;</p>
                <ol className="ol2">
                    <li className="li6"><span className="s1"><strong>Creation of Anonymous Data.&nbsp; </strong>We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you.&nbsp; We reserve the right to use Anonymous Data for any purpose and disclose Anonymous Data to third parties in our sole discretion.</span></li>
                </ol>
                <p className="p8">&nbsp;</p>
                <ol className="ol1">
                    <li className="li3"><span className="s1"><strong>Disclosure of Your Personal Data.&nbsp; </strong>We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy.</span></li>
                    <ol className="ol2">
                        <li className="li3"><span className="s1"><strong>Public Posts. </strong>Any content you post to public areas of the Services is available for public viewing.&nbsp; Once displayed on publicly viewable areas of our Services, your content can be collected and used by others.&nbsp; We cannot control who reads your postings or what other users may do with the content that you voluntarily post, so it is very important that you do not include Personal Data that you do not want to make public in your posts.&nbsp; Once you have posted information publicly, while you will still be able to edit and delete it on the Services, you will not be able to edit or delete such information after it has been cached, collected, and stored elsewhere by others (e.g., search engines).</span></li>
                        <li className="li3"><span className="s1"><strong>Social Networking Sites.</strong>&nbsp; Some of our Services may enable you to post content to SNSs.&nbsp; If you choose to do this, we will provide information to such SNSs in accordance with your</span><span className="s1">elections.&nbsp; You acknowledge and agree that you are solely responsible for your use of those SNSs and that it is your responsibility to review the terms of use and privacy policy of the third party provider of such SNSs.&nbsp; We will not be responsible or liable for: (i) the availability or accuracy of such SNSs; (ii) the content, products, or services of such SNSs; or (iii) your use of any such SNSs.&nbsp; Please note that any comments you post to our blog is posted to Medium, which is a SNS, and therefore will be publicly available on Medium as well as our blog.&nbsp; Your Medium username and profile will be publicly displayed next to your comment. Your use of Medium will be subject to Medium&rsquo;s terms of use and privacy policy which you should read carefully.</span></li>
                        <li className="li3"><span className="s1"><strong>Affiliates</strong>.&nbsp; We may share some or all of your Personal Data with our parent company, subsidiaries, joint ventures, or other companies under common control with SkyHi (&ldquo;<strong>Affiliates</strong>&rdquo;) in order to provide you better service and improve user experience, in which case we will require our Affiliates to honor this Privacy Policy.&nbsp; We will provide additional notice and ask for your consent if we wish to share your Personal Data with our Affiliates in a materially different way than discussed in this Privacy Policy.</span></li>
                        <li className="li3"><span className="s1"><strong>Corporate Restructuring.&nbsp;</strong> We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving the sale, transfer, or divestiture of all or a portion of our business or assets.&nbsp; In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.&nbsp;</span></li>
                        <li className="li3"><span className="s1"><strong>Other Disclosures</strong>.&nbsp; Regardless of any choices you make regarding your Personal Data (as described below), SkyHi may disclose Personal Data if it believes in good faith that such disclosure is appropriate (i) in connection with any legal investigation or proceeding; (ii) as required or permitted by relevant laws or to respond to subpoenas or warrants served on SkyHi; (iii) to protect or defend the rights or property of SkyHi, its affiliates or its users; and/or (iv) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, our applicable Terms of Use Agreement, or any other contractual arrangement.</span></li>
                    </ol>
                    <li className="li6"><span className="s1"><strong>Third Party Service Providers.&nbsp; </strong>We may share your Personal Data with third-party service providers that conduct quality assurance testing, facilitate creation of accounts, provide technical support, and/or provide other services to SkyHi such as booking and issuing airline tickets that you purchase and providing tailored travel services within the SkyHi Application. For information about how we or our foreign-based service providers process your Personal Data, please contact us at <a href="mailto:support@flyskyhi.com"><span className="s5">support@flyskyhi.com</span></a> or visit flyskyhi.com/contact. &nbsp;</span></li>
                </ol>
                <p className="p9">&nbsp;</p>
                <p className="p4"><span className="s3"><strong>Payments Processing</strong></span><span className="s1"><strong>:</strong> For online payments and/or Automated Clearing House (ACH) payouts, we use the payment services of Authorize.Net. We do not process, record or maintain your credit card or bank account information.&nbsp; For more information on how payments are handled, or to understand the data security and privacy afforded such information, please refer to https://www.authorize.net/company/privacy/.&nbsp;</span></p>
                <p className="p8">&nbsp;</p>
                <ol className="ol1">
                    <li className="li6"><span className="s1"><strong>Third-Party Websites and Applications.</strong>&nbsp; Our Services may contain links to third-party websites and applications.&nbsp; When you click on a link to any other website or application, you will leave our Site or Application and go to another site or application, and another entity may collect Personal Data or Anonymous Data from you.&nbsp; We have no control over, do not review, and cannot be responsible for, these outside websites, applications, or their content.&nbsp; Please be aware that the terms of this Privacy Policy do not apply to these outside websites and applications, or to any collection of your Personal Data after you click on links to such outside websites or applications.&nbsp; We encourage you to read the privacy policies of every website you visit and application you use.&nbsp; The links to third-party websites or applications are for your convenience and do not signify our endorsement of such third parties or their products, content or services.</span></li>
                </ol>
                <p className="p9">&nbsp;</p>
                <ol className="ol1">
                    <li className="li3"><span className="s1"><strong>Your Choices Regarding Information.</strong>&nbsp; You have several choices regarding the use of information on our Service:</span></li>
                    <ol className="ol2">
                        <li className="li3"><span className="s7"><strong>E-mail Communications.</strong></span><span className="s1">&nbsp; We may periodically send you free newsletters and e-mails that directly promote the use of our Site or Services.&nbsp; When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to &ldquo;opt-out&rdquo; by following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly (please see contact information above).&nbsp; Despite your indicated preferences for commercial e-mail messages, we may continue to send you account or service-related communications, including notices of any updates to our Terms of Use Agreement or Privacy Policy.</span></li>
                        <li className="li3"><span className="s7"><strong>Cookies.</strong></span><span className="s1">&nbsp; If you decide at any time that you no longer wish to accept Cookies for any of the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your browser&rsquo;s technical information. If you do not accept Cookies, however, you may not be able to use all portions of the Service or all functionality of the Services.&nbsp; If you have any questions about how to disable or modify Cookies, please let us know at the contact information provided above. Further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a> and <a href="http://www.youronlinechoices.com.uk"><span className="s5">www.youronlinechoices.com.uk</span></a>.</span></li>
                        <li className="li3"><span className="s1"><strong>Analytics Services.&nbsp;</strong> You may opt-out of tracking by certain of our analytics services by visiting the following: <a href="https://mixpanel.com/optout/"><span className="s5">https://mixpanel.com/optout/</span></a> for Mixpanel and <a href="https://tools.google.com/dlpage/gaoptout"><span className="s5">https://tools.google.com/dlpage/gaoptout</span></a> for Google Analytics. For a full list of our Analytics Services and other opt-out options, please contact us at support@flyskyhi.com.</span></li>
                        <li className="li3"><span className="s1"><strong>Ad Targeting.</strong>&nbsp; You may opt-out of tracking for ad targeting purposes by visiting <a href="http://www.aboutads.info/choices"><span className="s8">http://www.aboutads.info/choices</span></a></span><span className="s4">&nbsp;and&nbsp;<a href="http://www.youronlinechoices.eu/"><span className="s3">http://www.youronlinechoices.eu</span></a></span><span className="s1">.&nbsp;</span></li>
                        <li className="li3"><span className="s7"><strong>De-Linking SNS.&nbsp;</strong></span><span className="s1"> Please refer to the privacy settings of the SNS to determine how you may adjust our permissions and manage the interactivity between the Services and your social media account.</span></li>
                        <li className="li3"><span className="s7"><strong>Accessing, Changing or Deleting Your Personal Data.&nbsp;</strong></span><span className="s1"> You may access and/or change any of your Personal Data in your Account by editing your Personal Data within your Account or by sending an e-mail to us at support@flyskyhi.com.&nbsp; You may request deletion of your Personal Data by us, and we will use commercially reasonable efforts to honor your request, but please note that we may be required to keep such information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements).&nbsp; When we delete any information, it will be deleted from the active database, but may remain in our archives.&nbsp; We may also retain your information for fraud prevention or similar purposes.</span></li>
                        <li className="li3"><span className="s1"><strong>Application.</strong>&nbsp; You can stop all futurecollection of information by the Application by uninstalling the Application using the standard uninstall processes supported by your mobile device and/or the mobile application marketplace or network you use.</span></li>
                        <li className="li3"><span className="s1"><strong>Location Tracking.&nbsp; </strong>If you do not want location information to be automatically collected by us, you can disable location services on your phone.&nbsp; Please note that you may not be able to use some or all functionalities of the Services if you decide to disable location services.</span></li>
                    </ol>
                    <li className="li3"><span className="s1"><strong>Changes to This Privacy Policy.&nbsp; </strong>This Privacy Policy may be updated from time to time for any reason.&nbsp; We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at flyskyhi.com/legal/privacy-policy and we will change the &ldquo;Last Updated&rdquo; date above.&nbsp; You should consult this Privacy Policy regularly for any changes.</span></li>
                    <li className="li3"><span className="s1"><strong>Provisions Specific to European Users.</strong>&nbsp;</span></li>
                    <ol className="ol2">
                        <li className="li3"><span className="s1"><strong>Who is the data controller?</strong> For the purposes of Directive 95/46/EC of the European Parliament (the&nbsp;"EU&nbsp;Directive") and any successor or equivalent legislation in the applicable jurisdiction, the data controller of your Personal Data (as defined below) is SkyHi.&nbsp;</span></li>
                        <li className="li3"><span className="s1"><strong>&ldquo;Personal Data&rdquo;</strong> extends the definition of "personal information" (as outlined above), to include any information by which you can be actually be identified or could be identifiable, whether simply from that information alone, or in combination with any other information which is in our control and includes personal data as defined in the EU Directive. Each reference to personal information in this Privacy Policy shall, for users based in the European Economic Area (the "EEA"), be read as references to Personal Data.</span></li>
                        <li className="li3"><span className="s1"><strong>Sensitive Personal Data.</strong> Subject to the following paragraph, we ask that you not send us, and you not disclose, any sensitive personal data (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through the Site, Application and Services or otherwise to us.&nbsp;</span></li>
                    </ol></ol>
                <p className="p10"><span className="s1">If you send or disclose any sensitive personal data to us when you submit user generated content to our Site and Application, you consent to our processing and use of such sensitive personal data in accordance with this Privacy Policy. If you do not consent to our processing and use of such sensitive personal data, you must not submit such user generated content to our Site or Application.</span></p>
                <ol className="ol1"><ol className="ol2">
                    <li className="li3"><span className="s1"><strong>International Data Transfer. </strong>Wemay store and process your data outside the EEA. The data, including Personal Data, that we collect from you may be transferred to, and stored at, a destination outside the EEA. It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff may be engaged in, among other things, setting up and managing your account and the provision of support services. By checking the tick box at <a href="http://flyskyhi.com/join"><span className="s5">flyskyhi.com/join</span></a> or <a href="http://flyskyhi.com/account"><span className="s5">flyskyhi.com/account</span></a>, you agree to this transfer, storing or processing. SkyHi will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</span></li>
                    <li className="li3"><span className="s1"><strong>Retention.</strong> We will only retain your personal data for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law (for example for regulatory purposes).</span></li>
                    <li className="li3"><span className="s1"><strong>Your rights.&nbsp;</strong></span></li>
                </ol></ol>
                <ul>
                    <li className="li6"><span className="s1"><strong>Opt-out.</strong> You may contact us anytime to opt-out of: (i) direct marketing communications; (ii) automated decision making and/or profiling; (iii) our collection of sensitive data; (iv) any new processing or your personal data we carry out beyond the original purpose; or (v) the transfer of your personal data outside the EEA. Please note that your use of some of the Site, Application or Services may be ineffective upon opt-out.&nbsp;</span></li>
                    <li className="li6"><span className="s1"><strong>Your Ad Choices.</strong> Some of the third party services providers and/or advertisers may be members of the Network European Interactive Digital Advertising Alliance ("EDAA") Self-Regulatory Program for Online Behavioural Advertising. To learn more, visit <a href="http://www.edaa.eu/edaa-for-users"><span className="s5">http://www.edaa.eu/edaa-for-users</span></a> which provides information regarding targeted advertising and the "opt-out" procedures of EDAA members.</span></li>
                    <li className="li6"><span className="s1"><strong>Access.</strong> You may access the information we hold about you at any time via your account or by contacting us directly.</span></li>
                    <li className="li6"><span className="s1">Amend. You can also contact us to update or correct any inaccuracies in your personal data.</span></li>
                    <li className="li6"><span className="s1"><strong>Move.</strong> Your data is portable &ndash; i.e. you to have the flexibility to move your data to other service providers as you wish.</span></li>
                    <li className="li6"><span className="s1"><strong>Erase and forget.</strong> In certain situations, for example when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.</span></li>
                </ul>
                <p className="p8">&nbsp;</p>
                <ol className="ol1">
                    <li className="li3"><span className="s1"><strong>Provisions Specific to Canadian Users.</strong></span></li>
                    <ol className="ol2">
                        <li className="li3"><span className="s1">The definition of Personal Data and Anonymous Data in Section 5 is deleted and replaced with the following:</span></li>
                    </ol></ol>
                <p className="p10"><span className="s1"><strong>&ldquo;Personal Data&rdquo;</strong> means data that allows someone to identify or contact you as an individual, including, for example, your name, address, telephone number, e-mail address, as well as any other information about you that is associated with or linked to any of the foregoing data.&nbsp; <strong>&ldquo;Anonymous Data&rdquo;</strong> means data that does not, by itself or in combination with other data that, alone, would be anonymous, permit the identification of individual persons and that is not associated with or linked to Personal Data.&rdquo;</span></p>
                <ol className="ol1"><ol className="ol2">
                    <li className="li3"><span className="s1">The following language is added to the end of Section 5(d):</span></li>
                </ol></ol>
                <p className="p10"><span className="s1">&ldquo;For third parties who reside in Canada, you agree to invite only third parties with whom you have a &ldquo;personal relationship&rdquo; or a &ldquo;family relationship.&rdquo; A &ldquo;personal relationship&rdquo; means the relationship between an individual who sends a message and the individual to whom the message is sent, if those individuals have had direct, voluntary, two-way communications and it would be reasonable to conclude that they have a personal relationship, taking into consideration any relevant factors such as the sharing of interests, experiences, opinions and information evidenced in the communications, the frequency of communication, the length of time since the parties communicated or whether the parties have met in person. A &ldquo;family relationship&rdquo; means the relationship between an individual who sends a message and the individual to whom the message is sent if those individuals are related to one another through a marriage, common-law partnership or any legal parent-child relationship and those individuals have had direct, voluntary, two-way communication.&rdquo;</span></p>

            </div>

        </div>

    )

export default PrivacyPolicy;