import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

const CountryField = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched }
}) => (
  <CountryDropdown
    name={name}
    value={value}
    valueType="short"
    onChange={newValue => {
      setFieldValue(name, newValue);
      setFieldTouched(name, true);
    }}
  />
);

const RegionField = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched, values },
  countryFieldName
}) => (
  <RegionDropdown
    name={name}
    value={value}
    valueType="short"
    country={values[countryFieldName]}
    countryValueType="short"
    onChange={newValue => {
      setFieldValue(name, newValue);
      setFieldTouched(name, true);
    }}
  />
);

const BillingAddressForm = ({
  errors,
  touched
}) => (
  <Form>
    <div className="inputwrap">
      <div className="wrap-input100">
        <Field
          type="email"
          name="email"
          className={`input100 ${errors.email && touched.email && 'is-invalid'}`}
          placeholder="Email"
        />
        <span className="focus-input100" />
      </div>
      {touched.email && errors.email && <div className="error-text">{errors.email}</div>}

      <div className="wrap-input100">
        <Field
          type="text"
          name="phone_number"
          className={`input100 ${errors.phone_number && touched.phone_number && 'is-invalid'} `}
          placeholder="Phone Number"
        />
        <span className="focus-input100" />
      </div>
      {touched.phone_number && errors.phone_number && <div className="error-text">{errors.phone_number}</div>}

      <div className="wrap-input100">
        <span className={`input100 country-select ${errors.country && touched.country && 'is-invalid'}`}>
          <Field
            name="country"
            component={CountryField}
          />
        </span>
        <span className="focus-input100" />
      </div>
      {touched.country && errors.country && <div className="error-text">{errors.country}</div>}

      <div className="wrap-input100">
        <span className={`input100 country-select ${errors.region && touched.region && 'is-invalid'}`}>
          <Field
            name="region"
            countryFieldName="country"
            component={RegionField}
          />
        </span>
        <span className="focus-input100" />
      </div>
      {touched.region && errors.region && <div className="error-text">{errors.region}</div>}

      <div className="wrap-input100">
        <Field
          type="text"
          name="address_line_1"
          className={`input100 ${errors.address_line_1 && touched.address_line_1 && 'is-invalid'} `}
          placeholder="Street Address"
        />
        <span className="focus-input100" />
      </div>
      {touched.address_line_1 && errors.address_line_1 && <div className="error-text">{errors.address_line_1}</div>}

      <div className="wrap-input100">
        <Field
          type="text"
          name="address_line_2"
          className={`input100 ${errors.address_line_2 && 'is-invalid'} `}
          placeholder="Apartment, Suite, Bldg"
        />
        <span className="focus-input100" />
      </div>
      {touched.address_line_2 && errors.address_line_2 && <div className="error-text">{errors.address_line_2}</div>}

      <div className="wrap-input100">
        <Field
          type="text"
          name="city"
          className={`input100 ${errors.city && touched.city && 'is-invalid'} `}
          placeholder="City"
        />
        <span className="focus-input100" />
      </div>
      {touched.city && errors.city && <div className="error-text">{errors.city}</div>}

      <div className="wrap-input100">
        <Field
          type="text"
          name="zip"
          className={`input100 ${errors.zip && touched.zip && 'is-invalid'} `}
          placeholder="Zip Code"
        />
        <span className="focus-input100" />
      </div>
      {touched.zip && errors.zip && <div className="error-text">{errors.zip}</div>}

      <div className="wrap-input100-button">
        <button className="btn btn-blue" type="submit">
          Submit
        </button>
      </div>
    </div>
  </Form>
);

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues({ initialValues }) {
    return {
      email: initialValues.email || '',
      phone_number: initialValues.phone_number || '',
      country: initialValues.country || '',
      address_line_1: initialValues.address_line_1 || '',
      address_line_2: initialValues.address_line_2 || '',
      city: initialValues.city || '',
      region: initialValues.region || '',
      zip: initialValues.zip || '',
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Email not valid')
      .required('Email is a required field'),
    phone_number: Yup.string().required('Phone Number is a required field'),
    country: Yup.string().required('Country is a required field'),
    region: Yup.string().required('Region is a required field'),
    address_line_1: Yup.string().required('Street Address is a required field'),
    city: Yup.string().required('City is a required field'),
    zip: Yup.string().required('Zip Code is a required field'),
  }),
  handleSubmit(values, { props }) {
    props.handleSubmit(values);
  },
})(BillingAddressForm);
