import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGlobalSettings } from './actions/settings';

class SettingsProvider extends Component {
  componentDidMount() {
    this.props.fetchGlobalSettings();
  }

  render() {
    return this.props.children;
  }
}

export default connect(null, { fetchGlobalSettings })(SettingsProvider);
