import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateUser } from '../../../../actions/user';
import { AccountInformationForm } from './AccountInformationForm';
import Compliance from './Compliance';
import { Animated } from 'react-animated-css';
import Spinner from '../../Spinner';

class AccountInfo extends Component {
  state = {
    isCollapsed: true,
    isHidden: false,
    userState: {},
    disclaimers: {},
    phoneError: false,
  };
  submitUser = user =>
    this.props
      .updateUser(user)
      .catch(error => {
        if (error.response.status === 422) {
          this.setState({ isCollapsed: true, phoneError: true });
          // window.location.reload();
        }
        console.error(error.response);
      });

  addUser = user => {
    this.setState({ userState: user });
    this.showCompliance();
  };

  updateCompliance = compliance => {
    const user = {
      jwt: this.state.userState.jwt,
      values: { ...this.state.userState.newUser, disclaimers: compliance },
    };
    this.submitUser(user);
  };

  showCompliance = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  };

  renderForm() {
    const { user } = this.props.userAccount;

    const first_name = user.user.first_name;
    const last_name = user.user.last_name;
    const email = user.user.email;
    const phone_number = user.user.phone_number;

    return (
      <AccountInformationForm
        first_name={first_name}
        last_name={last_name}
        email={email}
        phone_number={phone_number}
        addUser={this.addUser}
      />
    );
  }

  render() {
    return (
      <div className="account-page onboarding-page-flow">
        <div className="container">
          <div className="section-form form-container">
            <div className="account-info-form">
              <Link className="small back-arrow" to="/">
                &larr; Home
              </Link>
              <div className={`${this.state.isCollapsed ? '' : 'is-shown'}`}>
                <h2>Account Information</h2>
                <br />
                {this.props.placeholder ? <Spinner /> : this.renderForm()}
                {this.state.phoneError ? (
                  <div className="alert alert-danger">
                    Looks like the phone number you entered is invalid. Please include your
                    country code if outside the US. If you feel this
                    is incorrect, please contact us at contact@flyskyhi.com
                  </div>
                ) : null}
              </div>
              <Animated
                animationIn="fadeIn"
                isVisible={!this.state.isCollapsed}
                className={`${this.state.isCollapsed ? 'displaynone' : ''}`}
              >
                <Compliance isCollapsed={this.state.isCollapsed} updateCompliance={this.updateCompliance} />
              </Animated>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = userAccount => ({
  userAccount,
});

export default connect(
  mapStateToProps,
  { updateUser }
)(AccountInfo);
