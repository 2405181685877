import React from 'react';
import PropTypes from 'prop-types';
import LoggedInRoute from './LoggedInRoute';

const AuthorizedRoute = ({ component, placeholder }) => (
  <LoggedInRoute
    component={component}
    match={({ hasSubscription }) => hasSubscription}
    redirect={({ filledOutAccountInfo }) =>
      !filledOutAccountInfo ? '/members/account-info' : '/members/skyhi-info'
    }
    placeholder={placeholder}
  />
);

AuthorizedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  placeholder: PropTypes.elementType.isRequired
};

AuthorizedRoute.defaultProps = {
  placeholder: () => <span />
};

export default AuthorizedRoute;
