import React from 'react';
import moment from 'moment';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

const AccountForm = ({ values, errors, touched, isSubmitting }) => (
  <Form>
    <div className="inputwrap">
      <div className="wrap-input100">
        <Field
          type="text"
          name="first_name"
          className={`input100 ${errors.first_name && 'is-invalid'} `}
          placeholder="First Name"
        />
        <span className="focus-input100" />
      </div>
      {touched.first_name && errors.first_name && <div className="error-text">{errors.first_name}</div>}
      <div className="wrap-input100">
        <Field
          type="text"
          name="last_name"
          className={`input100 ${errors.last_name && 'is-invalid'} `}
          placeholder="Last Name"
        />
        <span className="focus-input100" />
      </div>
      {touched.last_name && errors.last_name && <div className="error-text">{errors.last_name}</div>}
      <div className="wrap-input100">
        <Field type="email" name="email" className={`input100 ${errors.email && 'is-invalid'} `} placeholder="Email" />
        <span className="focus-input100" />
      </div>
      {touched.email && errors.email && <div className="error-text">{errors.email}</div>}
      <div className="wrap-input100">
        <Field
          type="email"
          name="confirmEmail"
          className={`input100 ${errors.confirmEmail && 'is-invalid'} `}
          placeholder="Confirm Email"
        />
        <span className="focus-input100" />
      </div>
      {touched.confirmEmail && errors.confirmEmail && <div className="error-text">{errors.confirmEmail}</div>}

      <div className="wrap-input100">
        <Field
          type="text"
          name="phone_number"
          className={`input100 ${errors.phone_number && 'is-invalid'} `}
          placeholder="Phone Number"
        />
        <span className="focus-input100" />
      </div>
      {touched.phone_number && errors.phone_number && <div className="error-text">{errors.phone_number}</div>}

      <label>Date of Birth</label>
      <div className={`input-flex-wrapper`}>
        <div className="wrap-input100" style={{ height: '62px' }}>
          <Field component="select" name="month" className={`input100 minimal ${errors.month && 'is-invalid'}`}>
            <option value="Month">Month</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </Field>
          <span className="focus-input100" />
        </div>
        <div className="wrap-input100" style={{ height: '62px' }}>
          <Field component="select" name="day" className={`input100 minimal ${errors.day && 'is-invalid'}`}>
            <option value="Day">Day</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </Field>
          <span className="focus-input100" />.
        </div>
        <div className="wrap-input100 last" style={{ height: '62px' }}>
          <Field
            type="text"
            name="year"
            maxLength={4}
            className={`input100 minimal ${errors.year && 'is-invalid'}`}
            placeholder="Year"
          />
          <span className="focus-input100" />
        </div>
      </div>
      {touched.month &&
        errors.month && (
          <div>
            <div className="error-text">{errors.month}</div>
            <br />
          </div>
        )}

      {touched.day &&
        errors.day && (
          <div>
            <div className="error-text">{errors.day}</div>
            <br />
          </div>
        )}
      {touched.year &&
        errors.year && (
          <div>
            <div className="error-text">{errors.year}</div>
            <br />
          </div>
        )}
      <div className="wrap-input100-button">
        <button className="btn btn-blue" type="submit">
          Submit
        </button>
      </div>
    </div>
  </Form>
);
/* eslint-disable */
export const AccountInformationForm = withFormik({
  mapPropsToValues({ first_name, last_name, email, phone_number }) {
    return {
      first_name: first_name || '',
      last_name: last_name || '',
      email: email || '',
      confirmEmail: '',
      phone_number: phone_number || '',
      month: '',
      day: '',
      year: '',
    };
  },
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required('Your first name is required!'),
    last_name: Yup.string().required('Your last name is required!'),
    email: Yup.string()
      .email('Email not valid')
      .required('Email is required!'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Emails must match!')
      .required('Email confirmation is required!'),
    phone_number: Yup.string().required('Phone number is required!'),
    month: Yup.string().required(),
    day: Yup.string().required(),
    year: Yup.string()
      .required()
      .min(4),
  }),
  handleSubmit(values, { props, setErrors, setFieldError, setSubmitting }) {
    const jwt = localStorage.jwt;
    if (values.day === 'Day') {
      setFieldError('day', 'You must select a day!');
    }
    if (values.month === 'Month') {
      setFieldError('month', 'You must select a month!');
    }
    const determineBirthday = () => {
      const bd = {
        birthday: `${values.month} ${values.day} ${values.year}`,
      };
      if (moment(bd.birthday).isValid()) {
        return new Date(bd.birthday).toISOString();
      }
      return null;
    };
    const newUser = {
      ...values,
      birthday: determineBirthday(),
    };
    const user = { jwt, newUser };
    props.addUser(user);
  },
})(AccountForm);
