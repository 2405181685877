import React from 'react';
import Moment from 'react-moment';

const creditText = amount => {
  if (amount > 250) {
    return `You get $${amount.toLocaleString()} of flight credit!`;
  }
  
    return `You get ${amount / 35} $35 credits towards your flight${amount === 35 ? '' : 's'}!`;
  
};

const untilText = (client, trialMonths) => {
  if (client) {
    return (
      <span>
        until <Moment format="MM/DD/YYYY" add={{ months: trialMonths }}>{new Date()}</Moment>
      </span>
    );
  }
  
    return (
      <span>
        {`for ${trialMonths} month${trialMonths === 1 ? '' : 's'}`}
      </span>
    );
  
};

const PricingText = props => (
  <div className="boarding-pass-rules-membership">
    <span className="boarding-text">MEMBERSHIP FEE</span>
    {props.trialMonths && props.trialMonths > 0 ? (
      <span>
        <span className="boarding-price boarding-promo-price">
          {props.trialAmount}
          /mo
        </span>
        <br />
        <span className="boarding-price boarding-price-sub text-center">
          (reg {props.subscriptionFee}
          /mo)
        </span>
        <br />
        {untilText(props.client, props.trialMonths)}
        <br />
        <span className="boarding-price boarding-price-sub text-center">
          Membership will cost {props.subscriptionFee}
          /mo after
        </span>
      </span>
    ) : (
      <span className="boarding-price boarding-promo-price">{props.subscriptionFee} /mo</span>
    )}
    <div className="boarding-pass-membership">
      {props.initialCreditAmount > 0 ? (
        <span className="boarding-price boarding-promo-flights">{creditText(props.initialCreditAmount)}</span>
      ) : null}
    </div>
  </div>
);

export default PricingText;
