import { SET_AUTH } from '../constants/auth';
import { AUTH_PENDING } from '../constants/setAuth';

export default function auth(state = { status: AUTH_PENDING }, action) {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
}
