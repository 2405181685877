import { SET_GLOBAL_SETTINGS } from '../constants/settings';

const defaultState = {
  payments: {
    use_stripe: true
  },
  plan_b: {
    initiated: false,
    shutoff_date: null
  }
};

export default function settings(state = defaultState, action) {
  switch (action.type) {
    case SET_GLOBAL_SETTINGS:
      return action.payload;

    default:
      return state;
  }
}
