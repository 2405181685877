import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TopBanner from "../../../TopBanner";
import logo from "../../../images/logo@3x.svg";
import { logout } from "../../../actions/auth";
import { getStatus, setStatus } from "../../../actions/status";
import { auth } from "../../../firebase";
import { AUTH_LOGGED_IN } from "../../../constants/setAuth";

import appleAppStoreButton from "../../../images/download_app-store@2x.png";
import googlePlayStoreButton from "../../../images/download_google-play@2x.png";

class TopNavbar extends Component {
  constructor(props) {
    super(props);
    this.fetchedStatus = false;
    this.state = {
      isOpen: false,
    };
  }

  fetchStatus() {
    if (this.props.authStatus === AUTH_LOGGED_IN && !this.fetchedStatus) {
      this.fetchedStatus = true;
      this.props.getStatus(localStorage.jwt);
    }
  }

  componentDidMount() {
    this.fetchStatus();
  }

  componentDidUpdate() {
    this.fetchStatus();
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  submitLogout = () => {
    this.props
      .logout(localStorage.jwt)
      .then(() => this.props.setStatus("pending"))
      .catch(() => this.props.setStatus("pending"));
  };

  logout = () => {
    auth()
      .signOut()
      .then(this.submitLogout)
      .catch((err) => {
        console.error(
          "ERROR SIGNING OUT WITH FIREBASE; ATTEMPTING TO DE-AUTH WITH SKYHI"
        );
        console.error(err);
        this.submitLogout();
      });
  };

  renderLogin = () => {
    if (this.props.authStatus !== AUTH_LOGGED_IN) {
      return (
        <NavItem>
          <NavLink tag={Link} to="/login/" className="">
            SIGN IN / SIGN UP
          </NavLink>
        </NavItem>
      );
    }
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          MY ACCOUNT
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <NavLink
              tag={Link}
              to={
                this.props.status === "pending"
                  ? "/members/skyhi-info/"
                  : "/members/account"
              }
            >
              Account
            </NavLink>
          </DropdownItem>
          <DropdownItem>
            <NavLink onClick={this.logout}>Logout</NavLink>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  statusBarCopy() {
    switch (this.props.status) {
      case "terminated":
      case "terminated_hold":
        return `There was a problem with your monthly payment. \
Update your payment info on your account page to continue flying SkyHi!`;
      case "cancelled":
        return `Your membership has been cancelled. \
Go to the membership screen on the app to re-activate your membership!`;
      case "hold":
        return `Your membership has been frozen. \
Go to the membership screen on the app to re-activate your membership!`;
      default:
        return null;
    }
  }

  renderStatusBar = () => (
    <Switch>
      <Route path="/members/account/edit-payment" render={() => null} />
      <Route
        render={() => {
          const copy = this.statusBarCopy();
          if (copy === null) {
            return null;
          }
          return (
            <span className="status-bar">
              <p>{copy}</p>
            </span>
          );
        }}
      />
    </Switch>
  );

  renderPageNavItem = (location, text) => (
    <Switch>
      <Route
        path={location}
        render={() => (
          <NavItem>
            <NavLink
              tag={Link}
              to={location}
              onClick={this.toggle}
              className="active"
            >
              {text}
            </NavLink>
          </NavItem>
        )}
      />
      <Route
        render={() => (
          <NavItem>
            <NavLink tag={Link} to={location} onClick={this.toggle}>
              {text}
            </NavLink>
          </NavItem>
        )}
      />
    </Switch>
  );

  render() {
    return (
      <React.Fragment>
        <Navbar light expand="lg" className="navbar-expand-lg sticky-top">
          <div className="container safari-center">
            <NavbarBrand to="/" tag={Link}>
              <img src={logo} alt="SkyHi" className="navlogo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.renderPageNavItem("/how-youll-fly/", "How You'll Fly")}
                {this.renderPageNavItem("/destinations/", "Destinations")}
                {this.renderPageNavItem("/why-skyhi/", "Why SkyHi")}
                <NavItem>
                  <NavLink tag="a" href="http://help.flyskyhi.com">
                    FAQ
                  </NavLink>
                </NavItem>
                {this.renderLogin()}
                <div className="only-mobile">
                  <hr />
                  <NavItem className="applink">
                    <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
                      <img
                        src={appleAppStoreButton}
                        className="img-fluid navbar-app"
                        alt="iphone"
                      />
                    </a>
                  </NavItem>
                  <NavItem className="applink">
                    <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
                      <img
                        src={googlePlayStoreButton}
                        className="img-fluid navbar-app"
                        alt="googleplay"
                      />
                    </a>
                  </NavItem>
                </div>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
        <TopBanner />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStatus,
      getStatus,
      logout,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  authStatus: state.auth.status,
  status: state.status,
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(TopNavbar);
