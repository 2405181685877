import React from 'react';
import { Link } from "react-router-dom";

const CTA = (props) => {
  return (
    <div className="ctabar">
      <div className="contianer text-center">
        <Link to={props.link}>{props.text}</Link>
      </div>
    </div>
  )
}

export default CTA;