import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBookings } from '../../../../actions/bookings';
import Booking from './Booking';
import NoBookings from './NoBookings';
import Spinner from '../../Spinner';

import appleAppStoreButton from '../../../../images/download_app-store@2x.png';
import googlePlayStoreButton from '../../../../images/download_google-play@2x.png';

class Bookings extends Component {
  componentDidMount() {
    this.props.getBookings(localStorage.jwt);
  }

  renderContents() {
    return (
      <Fragment>
        <p className="text-center">Use the SkyHi app to book your next trip!</p>
        <br />
        <div className="flexRow">
          <div className="skyhi-info-apps">
            <div className="flex-icons-download">
              <div className="icon-1">
                <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
                  <img
                    src={appleAppStoreButton}
                    className="img-fluid"
                    alt="iphone"
                  />
                </a>
              </div>
              <div className="icon-1">
                <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
                  <img
                    src={googlePlayStoreButton}
                    className="img-fluid"
                    alt="googleplay"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        {this.props.bookings.map((booking, i) => (
          <Booking booking={booking} key={i} />
        ))}
      </Fragment>
    );
  }

  render() {
    const { bookings, fetching } = this.props;

    if (bookings.length > 0 || fetching) {
      return (
        <Fragment>
          <Helmet>
            <title>Trips</title>
          </Helmet>
          <h2>My Trips</h2>
          {bookings.length === 0 ? <Spinner /> : this.renderContents()}
          <div className="arrow-container">
            <Link className="small back-arrow" to="/members/account/">
              &larr; My Account
            </Link>
            <Link className="small back-arrow" to="/members/account/payment-info/">
              Payment Info &rarr;
            </Link>
          </div>
        </Fragment>
      );
    }
    return <NoBookings />;
  }
}

const mapStateToProps = state => ({
  bookings: state.bookings.list,
  fetching: state.bookings.fetching
});

export default connect(mapStateToProps, { getBookings })(Bookings);
