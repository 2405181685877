import React from 'react';
import { Helmet } from 'react-helmet-async';
import { airlineData } from '../../../../data';

import AirlineLogo from './AirlineLogo';

const Baggage = (props) => (
  <div className="baggagepage">
    <Helmet>
      <title>Baggage Info</title>
    </Helmet>
    <div className="container">
      <h2>Airlines Baggage Info</h2>
      <div className="baggageflex">
        {
          airlineData.map(airline => (
            <div key={airline.Code} className="baggageinfobox">
              <div><AirlineLogo code={airline.Code} /></div>
              <h4>{airline.Name}</h4>
              <div><a href={airline.Baggage}>Baggage Policy</a></div>
            </div>
          ))
        }
      </div>
    </div>
  </div>
)

export default Baggage;
