import React from 'react';

const Spinner = () => (
  <div className="spinner-container">
    <div className="la-ball-spin-clockwise la-2x">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Spinner;
