import React from 'react';
import Moment from 'react-moment';
import Segment from './Segment';
import plane from '../../../../images/single_green_plane.svg';
import { convertBookingTime } from '../../utils';

const Booking = ({ booking }) => (
  <div className="bookings-box">
    <h2>
      {booking.flight.destination_city}, {booking.flight.destination_region}
    </h2>
    <p>
      <Moment format="LL">{booking.flight.departure}</Moment>
    </p>
    <hr />
    <div className="trip-details">
      <div className="flight">
        <h2>{booking.flight.origin}</h2>
        <img src={plane} alt={plane} />
        <h2>{booking.flight.destination}</h2>
      </div>
      <div className="trip-duration">{convertBookingTime(booking.flight.elapsed_time)}</div>
      <div className="confirmation">
        <h2>Ticket #{booking.ticket}</h2>
        <h2>Confirmation #{booking.confirmation}</h2>
      </div>
      {booking.flight.segments.map((segment, i) => (
        <Segment segment={segment} key={i} />
      ))}
    </div>
  </div>
);

export default Booking;
