import firebase from 'firebase/app';
import 'firebase/auth';
const auth = firebase.auth;

// Production
const config = {
  apiKey: 'AIzaSyA9gGijrmqDWZ0fjxpSrL9Kmyq_j5BXDGM',
  authDomain: 'skyhi-164219.firebaseapp.com',
  databaseURL: 'https://skyhi-164219.firebaseio.com',
  projectId: 'skyhi-164219',
  storageBucket: 'skyhi-164219.appspot.com',
  messagingSenderId: '623168361556',
};

// Development
// const config = {
//   apiKey: 'AIzaSyAj0JBjg29cy7r063uwyQB2gviZ55kGss4',
//   authDomain: 'skyhi-test-6dbb2.firebaseapp.com',
//   databaseURL: 'https://skyhi-test-6dbb2.firebaseio.com',
//   projectId: 'skyhi-test-6dbb2',
//   storageBucket: 'skyhi-test-6dbb2.appspot.com',
//   messagingSenderId: '72720119512',
// };

firebase.initializeApp(config);

export { firebase, auth };
export const facebookProvider = new firebase.auth.FacebookAuthProvider().addScope('user_birthday');
export const googleProvider = new firebase.auth.GoogleAuthProvider();
