import React from "react";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";
import "./styles.scss";
import "./ball-spin-clockwise.css";
import axios from "axios";
import App from "./BrowserApp";

// prod
// ReactPixel.init('1930310280632423', {}, { debug: true, autoConfig: false });

// dev
ReactPixel.init("1930310280632423", {}, { debug: true, autoConfig: false });

ReactPixel.pageView();
ReactPixel.fbq("track", "PageView");

setTimeout(() => {
  axios.post(process.env.REACT_APP_API_URL_BASE + "/v1/meta/print", {
    metum: {
      href: window.location.href,
      screen: {
        availTop: window.screen.availTop,
        availLeft: window.screen.availLeft,
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        colorDepth: window.screen.colorDepth,
        height: window.screen.height,
        width: window.screen.width,
        pixelDepth: window.screen.pixelDepth,
      },
      userAgent: window.navigator.userAgent,
      referrer: document.referrer,
    },
  });
}, 0);

// Handle query params outside of react router
const urlParams = new URLSearchParams(window.location.search);
// Is there a jwt from query params?
if (urlParams.has("auth")) {
  localStorage.jwt = urlParams.get("auth");
}
// Should we link back to the app after payment?
if (urlParams.has("referrer") && urlParams.get("referrer") === "app") {
  localStorage.fromApp = "true";
}

if (document.getElementById("app-is-rendered")) {
  // Found prerendered react markup, we can just hydrate
  ReactDOM.hydrate(<App />, document.getElementById("root"));
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}
