import { SET_BOOKINGS, FETCHING_BOOKINGS } from '../constants/bookings';

import api from '../api';

export const setBookings = payload => ({
  type: SET_BOOKINGS,
  payload,
});

export const fetchingBookings = () => ({
  type: FETCHING_BOOKINGS
});

export const getBookings = jwt => dispatch => {
  dispatch(fetchingBookings());
  return api.user.bookings(jwt).then(payload => dispatch(setBookings(payload.data.bookings)));
}
