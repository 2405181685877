import React, { Component } from 'react';

const cityImages = {
  ABQ: () => require('../../../../../images/city_icons/albuquerque.svg'),
  BWI: () => require('../../../../../images/city_icons/baltimore.svg'),
  BUD: () => require('../../../../../images/city_icons/budapest.svg'),
  YYC: () => require('../../../../../images/city_icons/calgary.svg'),
  DUB: () => require('../../../../../images/city_icons/dublin.svg'),
  FLL: () => require('../../../../../images/city_icons/fort-lauderdale.svg'),
  HAM: () => require('../../../../../images/city_icons/hamburg.svg'),
  HEL: () => require('../../../../../images/city_icons/Helsinki.svg'),
  LGB: () => require('../../../../../images/city_icons/longbeach.svg'),
  LYS: () => require('../../../../../images/city_icons/lyon.svg'),
  MXP: () => require('../../../../../images/city_icons/milan.svg'),
  MYR: () => require('../../../../../images/city_icons/myrtle.svg'),
  EWR: () => require('../../../../../images/city_icons/newark.svg'),
  NCE: () => require('../../../../../images/city_icons/nice.svg'),
  OAK: () => require('../../../../../images/city_icons/Oakland.svg'),
  PVD: () => require('../../../../../images/city_icons/providence.svg'),
  RDU: () => require('../../../../../images/city_icons/raleigh.svg'),
  SMF: () => require('../../../../../images/city_icons/sacramento.svg'),
  SJC: () => require('../../../../../images/city_icons/san_jose.svg'),
  SOF: () => require('../../../../../images/city_icons/sofia.svg'),
  TPA: () => require('../../../../../images/city_icons/tampa.svg'),
  TOL: () => require('../../../../../images/city_icons/toledo.svg'),
  VCE: () => require('../../../../../images/city_icons/venice.svg'),
  YYJ: () => require('../../../../../images/city_icons/Victoria.svg'),
  PBI: () => require('../../../../../images/city_icons/west-palm-beach.svg'),
  ZAG: () => require('../../../../../images/city_icons/zagreb.svg'),
  SFO: () => require('../../../../../images/city_icons/san_francisco.svg'),
  JFK: () => require('../../../../../images/city_icons/new_york.svg'),
  LAX: () => require('../../../../../images/city_icons/los_angeles.svg'),
  ATL: () => require('../../../../../images/city_icons/atlanta.svg'),
  MDW: () => require('../../../../../images/city_icons/chicago.svg'),
  MIA: () => require('../../../../../images/city_icons/miami.svg'),
  AUS: () => require('../../../../../images/city_icons/austin.svg'),
  MSY: () => require('../../../../../images/city_icons/new_orleans.svg'),
  BNA: () => require('../../../../../images/city_icons/nashville.svg'),
  BFI: () => require('../../../../../images/city_icons/seattle.svg'),
  LAS: () => require('../../../../../images/city_icons/las_vegas.svg'),
  PDX: () => require('../../../../../images/city_icons/portland.svg'),
  DFW: () => require('../../../../../images/city_icons/dallas.svg'),
  DEN: () => require('../../../../../images/city_icons/denver.svg'),
  MSP: () => require('../../../../../images/city_icons/minneapolis.svg'),
  BOS: () => require('../../../../../images/city_icons/boston.svg'),
  IAD: () => require('../../../../../images/city_icons/washington_dc.svg'),
  SAN: () => require('../../../../../images/city_icons/san_diego.svg'),
  PHX: () => require('../../../../../images/city_icons/phoenix.svg'),
  PHL: () => require('../../../../../images/city_icons/philadelphia.svg'),
  HOU: () => require('../../../../../images/city_icons/houston.svg'),
  SLC: () => require('../../../../../images/city_icons/salt_lake_city.svg'),
  DTW: () => require('../../../../../images/city_icons/detroit.svg'),
  MCO: () => require('../../../../../images/city_icons/orlando.svg'),
  CHS: () => require('../../../../../images/city_icons/charleston.svg'),
  CLE: () => require('../../../../../images/city_icons/cleveland.svg'),
  CMH: () => require('../../../../../images/city_icons/columbus.svg'),
  IND: () => require('../../../../../images/city_icons/indiana.svg'),
  MKC: () => require('../../../../../images/city_icons/kansas.svg'),
  MKE: () => require('../../../../../images/city_icons/milwaukee.svg'),
  STL: () => require('../../../../../images/city_icons/st_louis.svg'),
  SJD: () => require('../../../../../images/city_icons/cabo.svg'),
  MEX: () => require('../../../../../images/city_icons/mexicocity.svg'),
  CUN: () => require('../../../../../images/city_icons/cancun.svg'),
  YVR: () => require('../../../../../images/city_icons/vancouver.svg'),
  YUL: () => require('../../../../../images/city_icons/montreal.svg'),
  YYZ: () => require('../../../../../images/city_icons/toronto.svg'),
  LHR: () => require('../../../../../images/city_icons/london.svg'),
  BCN: () => require('../../../../../images/city_icons/barcelona.svg'),
  MAD: () => require('../../../../../images/city_icons/madrid.svg'),
  FCO: () => require('../../../../../images/city_icons/rome.svg'),
  CDG: () => require('../../../../../images/city_icons/paris.svg'),
  FRA: () => require('../../../../../images/city_icons/frankfurt.svg'),
  TXL: () => require('../../../../../images/city_icons/berlin.svg'),
  ARN: () => require('../../../../../images/city_icons/stockholm.svg'),
  VIE: () => require('../../../../../images/city_icons/vienna.svg'),
  ATH: () => require('../../../../../images/city_icons/athens.svg'),
  RKV: () => require('../../../../../images/city_icons/reykjavik.svg'),
  GVA: () => require('../../../../../images/city_icons/geneva.svg'),
  WAW: () => require('../../../../../images/city_icons/warsaw.svg'),
  LIS: () => require('../../../../../images/city_icons/lisbon.svg'),
  CPH: () => require('../../../../../images/city_icons/copenhagen.svg'),
  AMS: () => require('../../../../../images/city_icons/amsterdam.svg'),
  ZRH: () => require('../../../../../images/city_icons/zurich.svg'),
  MUC: () => require('../../../../../images/city_icons/munich.svg'),
  BRU: () => require('../../../../../images/city_icons/brussels.svg'),
  MLA: () => require('../../../../../images/city_icons/malta.svg'),
  PRG: () => require('../../../../../images/city_icons/prague.svg'),
  NAP: () => require('../../../../../images/city_icons/naples.svg'),

  city1: () => require('../../../../../images/city_icons/city1.svg'),
  city2: () => require('../../../../../images/city_icons/city2.svg'),
  seaside: () => require('../../../../../images/city_icons/city_seaside.svg'),
  mountain: () => require('../../../../../images/city_icons/city_mountain.svg'),
};

class City extends Component {
  state = {
    cityIcon: '',
  };

  findCityIcon = city => {
    let icon = cityImages[city.code];
    if (!icon) {
      icon = cityImages[city.default_image];
    }
    return icon && icon();
  };

  showPrice = price => {
    if (price) {
      return (
        <span className="price-tag">
          <p>{this.props.destination.price_per_flight}</p>
        </span>
      );
    }
  };

  render() {
    return (
      <div className="citybox">
        {this.showPrice(this.props.destination.price_per_flight)}
        <div className="city-text-flex">
          <h4>{this.props.destination.city}</h4>
          <h3>
            {this.props.destination.region_long}, {this.props.destination.country}
          </h3>
        </div>
        <div className="city-image-flex">
          <img src={this.findCityIcon(this.props.destination)} className="img-fluid" alt={this.props.alt} />
        </div>
      </div>
    );
  }
}

export default City;
