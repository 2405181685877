import { SET_INVITE } from '../constants/invite';

const defaultState = {
  inviteCode: 'default',
  trialMonths: 0,
  trialAmount: '$0',
  initialCreditAmount: 0,
  subscriptionFee: '$199'
};

export default function invite(state = defaultState, action) {
  switch (action.type) {
    case SET_INVITE:
      return action.payload
    default:
      return state;
  }
}
