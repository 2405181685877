import React from 'react';

import ad1 from '../../../../images/ads/01_La-SF@2x.jpg';
import ad2 from '../../../../images/ads/02_La-chicago@2x.jpg';
import ad3 from '../../../../images/ads/03_La-nyc@2x.jpg';

const Places = () => (
  <div className="container">
    <br />
    <h2 className="text-center">Popular Routes</h2>
    <div className="placesimages">
      <div className="placeimage">
        <img src={ad1} className="img-fluid" alt="ad1" />
      </div>
      <div className="placeimage">
        <img src={ad2} className="img-fluid" alt="ad2" />
      </div>
      <div className="placeimage">
        <img src={ad3} className="img-fluid" alt="ad3" />
      </div>
    </div>
    <br />
  </div>
);

export default Places;
