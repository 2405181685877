import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import phone from '../../../../images/iphone_X_1.svg';
import phone2 from '../../../../images/iphone_X_2@1x.svg';
import phone3 from '../../../../images/iphone_X_3@1x.svg';
import circleconfirm from '../../../../images/circle-confirm.svg';
import circlelocation from '../../../../images/Circle-Location.svg';
import circleseat from '../../../../images/Circle-seat.svg';
import flynear from '../../../../images/FlyNear1@2x.png';
import flynearmobile from '../../../../images/FlyNear2_mobile@2x.png';

import skyhibasics1 from '../../../../images/skyhibasics1.svg';
import skyhibasics2 from '../../../../images/skyhibasics2.svg';
import skyhibasics3 from '../../../../images/skyhibasics3.svg';
import skyhibasics4 from '../../../../images/skyhibasics4.svg';
import skyhibasics5 from '../../../../images/skyhibasics5.svg';
import flatrate1 from '../../../../images/flatrate1.svg';
import flatrate2 from '../../../../images/flatrate2.svg';
import flatrate3 from '../../../../images/flatrate3.svg';

import Header from '../components/Header';
import Join from '../components/Join';

const Howfly = () => (
  <section className="how_youll_fly">
    <Helmet>
      <title>How You'll Fly</title>
    </Helmet>
    <Header title="How you'll fly" subheader="Here's how SkyHi will take you places." />
    <section className="skyhi-basics">
      <div className="container">
        <h2 className="text-center">SkyHi Basics</h2>
        <br />
        <div className="basics-flex">
          <div className="basics">
            <img src={skyhibasics1} className="img-fluid" alt="skyhibasics" />
            <h3 className="basics-pad">Members pay $199/mo</h3>
          </div>
          <div className="basics">
            <img src={skyhibasics2} className="img-fluid" alt="skyhibasics" />
            <h3 className="basics-pad">Flights are one way</h3>
          </div>
          <div className="basics">
            <img src={skyhibasics3} className="img-fluid" alt="skyhibasics" />
            <h3 className="basics-pad">Book flights for the upcoming 30 days</h3>
          </div>
          <div className="basics">
            <img src={skyhibasics4} className="img-fluid" alt="skyhibasics" />
            <h3 className="basics-pad">Book up to 5 flat rate flights each month</h3>
          </div>
          <div className="basics">
            <img src={skyhibasics5} className="img-fluid" alt="skyhibasics" />
            <h3 className="basics-pad">Flat rate flights start at $35</h3>
          </div>
        </div>
      </div>
    </section>
    <section className="skyhi-basics-rates">
      <div className="container">
        <h2 className="text-center">Skyhi's Three Flat Rates Based on How Far You'll Go</h2>
        <br />
        <br />
        <div className="basics-flex">
          <div className="basics">
            <img src={flatrate1} className="img-fluid" alt="flatrate" />
            <h3 className="basics-rules-pad">For Flights Between 0 - 1000 Miles</h3>
          </div>
          <div className="basics">
            <img src={flatrate2} className="img-fluid" alt="flatrate" />
            <h3 className="basics-rules-pad">For Flights Between 1000 - 2000 Miles</h3>
          </div>
          <div className="basics">
            <img src={flatrate3} className="img-fluid" alt="flatrate" />
            <h3 className="basics-rules-pad">For Flights Between 2000 - 3000 Miles</h3>
          </div>
        </div>
        <div className="bar">
          <h3>PAY THE SAME COST PER ROUTE EVERYTIME</h3>
        </div>
      </div>
    </section>
    <section className="infographic-desktop">
      <div className="container">
        <img src={flynear} className="img-fluid" alt="cities" />
      </div>
    </section>
    <section className="infographic-mobile">
      <div className="container">
        <img src={flynearmobile} className="img-fluid" alt="cities" />
      </div>
    </section>
    <div className="destinationsbar">
      <div className="contianer text-center">
        <Link to="/destinations/">See where we'll take you</Link>
      </div>
    </div>
    <section className="appscreens">
      <div className="container">
        <h2 className="text-center">Get out of town in a snap</h2>
        <br />
        <br />
        <div className="screen-row row">
          <div className="col-lg-4 col-md-8 screen-center">
            <div className="screen text-center">
              <div className="screen-icon">
                <img src={circlelocation} className="img-fluid" alt="location" />
              </div>
              <div className="screen-content">
                <p>Pick where you want to go from any of our SkyHi cities</p>
              </div>
              <div className="screen-img">
                <img src={phone} className="img-fluid" alt="cities" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-8 screen-center">
            <div className="screen text-center">
              <div className="screen-icon">
                <img src={circleseat} className="img-fluid" alt="seat" />
              </div>
              <div className="screen-content">
                <p>Claim a seat on any available flight of your choice</p>
              </div>
              <div className="screen-img">
                <img src={phone2} className="img-fluid" alt="flights" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-8 screen-center">
            <div className="screen text-center">
              <div className="screen-icon">
                <img src={circleconfirm} className="img-fluid" alt="confirm" />
              </div>
              <div className="screen-content">
                <p>Get your confirmation and get ready to fly</p>
              </div>
              <div className="screen-img">
                <img src={phone3} className="img-fluid" alt="claim" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Join css="join-blue" title="Get lost with SkyHi." subtext="SkyHi - the flat rate flying club to whatever's next" />
  </section>
);

export default Howfly;
