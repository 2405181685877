import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import axios from 'axios';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import City from './components/City';
import Join from '../components/Join';
import Header from '../components/Header';

import {
  fetchDestinationsAll,
  fetchDestinationsFeatured,
  fetchDestinationsMeta
} from '../../../../actions/destinations';

class Destinations extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      dropDownValue: 'Select',
      selectedAirport: null,
      cityIcon: '',
      suggestion: '',
      success: false,
    };
  }

  componentDidMount() {
    this.props.fetchDestinationsAll();
    this.props.fetchDestinationsMeta();
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  changeValue = (e, airport) => {
    this.setState({ dropDownValue: e.currentTarget.textContent });
    this.setState({ selectedAirport: airport });
    this.props.fetchDestinationsFeatured(airport);
  };

  renderStats = () => {
    return (
      <div className="statsflex">
        <div className="stats-box">
          <p className="number">{this.props.numberOfDestinations}</p>
          <p className="stat">Cities</p>
        </div>
        <div className="stats-box">
          <p className="number">{this.props.numberOfFlights}</p>
          <p className="stat">Flights</p>
        </div>
        <div className="stats-box">
          <p className="number">{this.props.numberOfRoutes}</p>
          <p className="stat">Routes</p>
        </div>
      </div>
    );
  };

  renderForm = () => {
    if (this.state && this.state.success === true) {
      return (
        <div className="cities-form column">
          <h3>Thanks for the suggestion!</h3>
          <h3>
            We
            <span>&#39;</span>
            re adding cities all the time!
          </h3>
        </div>
      );
    }
    return (
      <div className="cities-form">
        <div className="city-form-header">
          <h3>Have an idea for another city to add?</h3>
        </div>
        <form className="form-inline city-vote" onSubmit={this.submitCity}>
          <span className="">
            <input
              type="text"
              className="city-vote-input"
              placeholder="Your Suggestion"
              value={this.state.suggestion}
              onChange={this.handleChange}
            />
          </span>
          <button type="submit" className="btn-city">
            Submit
          </button>
        </form>
      </div>
    );
  };

  submitCity = e => {
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL_BASE + '/v1/city-request', {
        name: this.state.suggestion,
      })
      .then(this.setState({ success: true }));
  };

  handleChange = e => {
    this.setState({ suggestion: e.target.value });
  };

  render() {
    const data = this.state.selectedAirport && this.props.featured.length > 0
      ? this.props.featured
      : this.props.destinations;
    const cities = this.props.destinations.map(airport => (
      <DropdownItem onClick={e => this.changeValue(e, airport)} key={airport.id} value={airport.id}>
        {airport.city || 'Select'}
      </DropdownItem>
    ));
    const city = data.map(destination => (
      <City
        destination={destination}
        img={this.state.cityIcon}
        key={destination.id}
        alt={destination.city}
      />
    ));
    return (
      <div className="destinations">
        <Helmet>
          <title>Destinations</title>
        </Helmet>
        <Header title="Oh the places you'll go" subheader="Find out where SkyHi will take you." />
        <div className="light-blue">
          <div className="container">
            <div className="">{this.renderStats()}</div>
          </div>
        </div>
        <div className="origin">
          <div className="container">
            <span className="dropdown-inline tofrom">Where can I fly from</span>
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle
                caret
                tag="span"
                onClick={this.toggle}
                data-toggle="dropdown tofrom"
                aria-expanded={this.state.dropdownOpen}
              >
                {this.state.dropDownValue}
              </DropdownToggle>
              <DropdownMenu>{cities}</DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <section className="cities-select-section">
          <div className="container">
            <div className="citiesflex">{city}</div>
          </div>
        </section>
        <section className="more-cities">
          <div className="container">{this.renderForm()}</div>
        </section>
        <Join
          css="join-blue"
          title="Get lost with SkyHi."
          subtext="SkyHi - the flat rate flying club to whatever's next."
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  numberOfDestinations: state.destinations.meta.number_of_destinations,
  numberOfRoutes: state.destinations.meta.number_of_routes,
  numberOfFlights: state.destinations.meta.number_of_flights,
  destinations: state.destinations.index,
  featured: state.destinations.featured
});

export default connect(mapStateToProps, {
  fetchDestinationsAll,
  fetchDestinationsFeatured,
  fetchDestinationsMeta
})(Destinations);
