import React from 'react';
import CTA from '../../components/ctaBar';

import planes from '../../../../../images/rules/Rules_Icons-01.svg';
import line from '../../../../../images/rules/Rules_Icons-02.svg';
import time from '../../../../../images/rules/Rules_Icons-03.svg';
import money from '../../../../../images/rules/Rules_Icons-04.svg';

const Rules = () => (
  <div>
    <section className="rules-section">
      <div className="container">
        <div className="rules-flex-row rules-5-flights">
          <div className="rules-flex-box">
            <h3 className="rules-box-header">5 Flights</h3>
            <p>
              Hop from city to city or just get away for the weekend. SkyHi lets you fly up to five times a month
              without breaking the bank. Pay for only the flights you book. Your number of flights resets to five at the
              beginning of each billing cycle.
            </p>
          </div>
          <div className="rules-flex-img rules-plane-svg">
            <img src={planes} className="img-fluid" alt="plane-svg" />
          </div>
        </div>

        <div className="rules-flex-row rules-1500-miles">
          <div className="rules-flex-box">
            <h3 className="rules-box-header">Flights start at $35</h3>
            <p>
              Fly on any of our SkyHi routes and always pay a flat fee. Flights up to 1000 miles cost just $35. Need to
              go further? Fly up to 2000 miles for just $75 and fly up to 3000 miles for just $120 every time. You can
              book any of our routes regardless of where you are. We’re adding more cities all the time. See where you
              can fly SkyHi to from your city.
            </p>
          </div>
          <div className="rules-flex-img">
            <img src={line} className="" alt="line-svg" />
          </div>
        </div>

        <div className="rules-flex-row rules-10-days">
          <div className="rules-flex-box">
            <h3 className="rules-box-header">30 Days</h3>
            <p>
              Stop planning weeks in advance. SkyHi surfaces departing flights within the next 30 days, taking the
              headache out of booking last-minute tickets. Simply open the app, click where you want to go, and reserve.
              No fuss. No muss.
            </p>
          </div>
          <div className="rules-flex-img">
            <img src={time} className="img-fluid" alt="time" />
          </div>
        </div>
        <div className="rules-flex-row rules-1-price">
          <div className="rules-flex-box">
            <h3 className="rules-box-header">Flat Rate Pricing</h3>
            <p>
              Stop refreshing that list of flights, weeks before you ever want to leave, hoping for the price to drop.
              Know what you're paying, regardless of the day, time, or airline you fly.
            </p>
          </div>
          <div className="rules-flex-img">
            <img src={money} className="img-fluid" alt="price" />
          </div>
        </div>
      </div>
    </section>
    <CTA text="Explore our destinations" link="/destinations/" />
  </div>
);

export default Rules;
