import { SET_AUTH } from '../constants/auth';
import api from '../api';
import { auth } from '../firebase';

import {
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT
} from '../constants/setAuth';

export const setAuthStatus = authStatus => ({
  type: SET_AUTH,
  payload: authStatus
});

// When setting auth status to logged out, ensure that the jwt token gets
// removed from localStorage
const setLoggedOut = () => dispatch => {
  localStorage.removeItem('jwt');
  dispatch(setAuthStatus(AUTH_LOGGED_OUT));
};

// Given an API response from either login or refresh, update the jwt
// and set the auth status to logged in.
const setLoggedIn = payload => dispatch => {
  localStorage.jwt = payload.headers['x-token'];
  dispatch(setAuthStatus(AUTH_LOGGED_IN));
};

// Try to sign out with the firebase SDK, setting the authStatus to logged out whether or
// not the firebase.auth().signOut() call succeeds.
const forceLogout = () => dispatch =>
  auth().signOut()
    .then(response => dispatch(setLoggedOut()))
    .catch(err => dispatch(setLoggedOut()));

// Given an id token from firebase, try to authenticate with the SkyHi server to get
// a jwt token. A jwt token is required for any SkyHi API that does user authentication.
export const login = idToken => dispatch =>
  api.auth.login(idToken)
    .then(payload => dispatch(setLoggedIn(payload)))
    .catch(err => {
      // Shouldn't happen; the firebase SDK should never provide a bad id token.
      // An error here could be connection related, but that seems unlikely because
      // the internet would've needed to be connected in order to fetch the id token
      // in the first place.
      console.error('ERROR AUTHENTICATING WITH SKYHI');
      console.error('ERROR FROM REQUEST:');
      console.error(err);
      console.error('FIREBASE ID TOKEN:');
      console.error(idToken);
      dispatch(forceLogout());
    });

// Send the sign out request to the SkyHi server
export const logout = jwt => dispatch =>
  // At this point we've at least tried to signOut with the firebase SDK. Attempt
  // to sign out with SkyHi. If successful, every existing jwt token will have been
  // invalidated for this user. However, the user might not have to re-login on other
  // devices/on the app, because an id token might be present from the firebase SDK.
  api.auth.logout(jwt)
    .then(payload => dispatch(setLoggedOut()))
    // If error, we still consider ourselves logged out
    .catch(err => dispatch(setLoggedOut()));

// Attempt to trade a firebase id token for a valid jwt token
export const submitIdToken = () => dispatch => {
  return new Promise((resolve, reject) => {
    // currentUser should never be null here, because we wait for onAuthStateChanged to
    // report that we're signed in before we try calling this. Still, it's better to be safe.
    const currentUser = auth().currentUser;
    if (currentUser === null) {
      console.error('submitIdToken: FIREBASE CURRENT USER IS NULL');
      dispatch(forceLogout());
      reject();
      return;
    }

    currentUser.getIdToken()
      .then(idToken => {
        dispatch(login(idToken)).then(resolve).catch(reject);
      })
      .catch(err => {
        // Failed to fetch an id token. This is a weird state to be in, because currentUser
        // isn't null. The best we can do is tell the firebase SDK to logout.
        console.error('submitIdToken: UNABLE TO FETCH ID TOKEN, ENSURING FIREBASE LOGOUT');
        console.error(err);
        dispatch(forceLogout());
        reject();
      });
  });
}

const checkFirebaseLoginStatus = () => dispatch => {
  // We currently don't have a valid jwt token. Check if we're logged in with firebase,
  // and if so, attempt to authenticate with the SkyHi server.
  const unsubscribe = auth().onAuthStateChanged(loggedIn => {
    // onAuthStateChanged is an observer that calls its handler everytime
    // the firebase login status changes, but we only want to get the initial
    // login status.
    unsubscribe();

    if (loggedIn) {
      dispatch(submitIdToken())
        // Prevent uncaught exception warning if we reject
        .catch(() => null);
    } else {
      dispatch(setLoggedOut());
    }
  });
};

// Given a jwt token, get a fresh jwt from the server. This is used to test whether a given
// jwt is valid and if so, to fetch a new jwt that's guaranteed to live at least 24 hours.
const refreshJwt = jwt => dispatch =>
  api.auth.refresh(jwt)
    .then(payload => dispatch(setLoggedIn(payload)))
    .catch(err => dispatch(checkFirebaseLoginStatus()));

// Determine whether or not the user is logged in and set the auth status in redux
// appropriately. This gets called as soon as the app mounts and the firebase SDK 
// finishes initializing. It also gets called in intervals to refresh the jwt token.
export const setAuth = () => dispatch => {
  if (localStorage.jwt) {
    // We have a jwt token present. Either we were previously logged in with firebase,
    // or else it was passed via url in the "auth" query param. In both cases, we try
    // to refresh the token, and if that fails, we'll set the authStatus based on the
    // firebase login status.
    dispatch(refreshJwt(localStorage.jwt));
    return;
  }
  // There's no jwt token present, so we're relying on the firebase SDK to tell us
  // whether we're logged in.
  dispatch(checkFirebaseLoginStatus());
};
