import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import PromoForm from './PromoForm';
import { grabUser, addPromo } from '../../../../actions/user';

const defaultState = {
  error: false,
  response: ''
};

class Promo extends Component {
  state = defaultState;

  clearState = () => {
    if (this.state.error || this.state.response !== '') {
      this.setState(defaultState);
    }
  };

  promoError = error => {
    const errorMessage = error.message;
    if (errorMessage.includes('404')) {
      this.setState({ error: true, response: 'Looks like that promo code doesn\'t exist!' });
    } else {
      this.setState({ error: true, response: 'Looks like something went wrong.' });
    }
  };

  submitPromo = (promo, setSubmitting) => {
    const user = {
      jwt: promo.jwt,
      token: promo.values.promo,
      overwrite: true
    };

    if (user.token === '' || user.token === this.props.promo) {
      setSubmitting(false);
      return;
    }
    this.props.addPromo(user)
      .then(() => {
        Cookies.set('invite_code', user.token);
        return this.props.grabUser(user.jwt)
          .then(() => this.setState({ error: false, response: 'Your promo has been applied!' }))
          .catch(() => this.setState({ error: true, response: 'Something went wrong!' }));
      })
      .catch(error => {
        this.promoError(error);
      })
      .then(() => setSubmitting(false));
  };

  render() {
    return (
      <div className={`inputwrap `}>
        <div className="input-collapse">
          <h4 className="text-left">Promo</h4>
          <PromoForm
            promo={this.props.promo}
            submitPromo={this.submitPromo}
            reset={this.clearState}  
          />
          {this.state.error ? (
            <span className="error-message">
              <p className="small promo-error">{this.state.response}</p>
            </span>
          ) : (
            <p className="small promo-success">{this.state.response}</p>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addPromo,
      grabUser,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(Promo);
