import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import redstripe from '../../../../images/RedStripe@2x.png';
import PricingText from './PricingText';
import PromoToggle from './PromoToggle';
import Spinner from '../../Spinner';
import { submitPromo } from '../../../../actions/user';
import { fetchDestinationsMeta } from '../../../../actions/destinations';
import {
  AUTH_PENDING
} from '../../../../constants/setAuth';

import appleAppStoreButton from '../../../../images/download_app-store@2x.png';
import googlePlayStoreButton from '../../../../images/download_google-play@2x.png';

class PaymentLanding extends Component {
  componentDidMount() {
    this.ranDidMount = false;
    if (!this.isPlaceholder()) {
      this.didMount();
    }
  }

  componentDidUpdate() {
    if (!this.isPlaceholder()) {
      this.didMount();
    }
  }

  didMount() {
    if (!this.ranDidMount) {
      this.ranDidMount = true;

      ReactPixel.track('InitiateCheckout');
      this.props.fetchDestinationsMeta();
      this.props.submitPromo(this.props.promo)
        .catch(error => console.error(error));
    }
  }

  isPlaceholder = () => {
    return this.props.authStatus === AUTH_PENDING;
  }

  renderContents() {
    return (
      <div>
        <div className="boarding-pass">
          <div className="boarding-pass-header">
            <span>SKYHI MEMBERSHIP</span>
          </div>
          <div className="boarding-pass-rules">
            <PricingText
              trialAmount={this.props.trialAmount}
              trialMonths={this.props.trialMonths}
              initialCreditAmount={this.props.initialCreditAmount}
              subscriptionFee={this.props.subscriptionFee}
            />
          </div>

          <div className="boarding-pass-flight-credits mobile-pad">
            <span className="boarding-text">5 FLIGHTS PER MONTH</span>
            <div className="flight-price-flex">
              <div className="flight-price">
                <h2>$35</h2>
                <p className="small">up to 1000 miles</p>
              </div>
              <div className="flight-price">
                <h2>$75</h2>
                <p className="small">up to 2000 miles</p>
              </div>
              <div className="flight-price">
                <h2>$120</h2>
                <p className="small">up to 3000 miles</p>
              </div>
            </div>
          </div>
          <div className="boarding-pass-flight-period">
            <span className="boarding-text">
              Choose any flight we offer departing in the upcoming 30 days and book with just one tap
            </span>
          </div>
          <div className="boarding-pass-flight-period">
            <span className="boarding-text">Hold up to two tickets at any time</span>
          </div>
          <div className="boarding-pass-flight-period">
            <span className="boarding-text">
              There are currently {this.props.numDestinations} cities, {this.props.numRoutes} routes,
              and {this.props.numFlights} flights available right now to book.
            </span>
          </div>
          <div className="boarding-pass-subscription mobile-pad">
            <span>Membership starts</span>
            <span className="membership-start-date">{moment().format('dddd, MMMM D, YYYY')}</span>
          </div>
          <div className="boarding-red-stripe">
            <img src={redstripe} className="img-fluid" alt={redstripe} />
          </div>
        </div>
        <div className="text-center">
          <br />
          <Link to="/members/payment-details/" className="btn btn-blue">
            Get Started
          </Link>
        </div>
        <br />
        <div className="not-ready">
        <p className="text-center small">
          Not ready to become a member yet? Download the app to view how it works and preview flights. Make sure
          to head over to our <a href="http://help.flyskyhi.com">FAQs</a> for full details
        </p>
        <br />
        <div className="skyhi-info-apps">
          <div className="flex-icons-download">
            <div className="icon-1">
              <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
                <img
                  src={appleAppStoreButton}
                  className="img-fluid"
                  alt="iphone"
                />
              </a>
            </div>
            <div className="icon-1">
              <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
                <img
                  src={googlePlayStoreButton}
                  className="img-fluid"
                  alt="googleplay"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

  render() {
    return (
      <div className="account-page onboarding-page-flow">
        <div className="container">
          <div className="form-container section-form skyhi-info-page">
            <Link className="small back-arrow" to="/">
              &larr; Home
            </Link>
            <h2>Let's Review How SkyHi Works</h2>
            <br />
            <p>SkyHi is the first subscription service that allows you to travel for a fixed cost every time.</p>
            <div className="show-promo">
              {this.isPlaceholder() ? (
                <div className="small have-promo">Loading...</div>
              ) : (
                <PromoToggle promo={this.props.promo} />
              )}
            </div>
            {this.isPlaceholder() ? <Spinner /> : this.renderContents()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  promo: state.user.meta.alpha_token ? state.user.meta.alpha_token.promo : null,
  trialAmount: state.user.meta.alpha_token ? state.user.meta.alpha_token.trial_amount : null,
  trialMonths: state.user.meta.alpha_token ? state.user.meta.alpha_token.trial_months : 0,
  subscriptionFee: state.user.meta.alpha_token ? state.user.meta.alpha_token.monthly_amount : '$199',
  initialCreditAmount: state.user.user.total_flight_credits,
  authStatus: state.auth.status,
  numDestinations: state.destinations.meta.number_of_destinations,
  numRoutes: state.destinations.meta.number_of_routes,
  numFlights: state.destinations.meta.number_of_flights
});

export default connect(
  mapStateToProps,
  { submitPromo, fetchDestinationsMeta }
)(PaymentLanding);
