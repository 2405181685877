import { FETCHING_BOOKINGS, SET_BOOKINGS } from '../constants/bookings';

const defaultState = {
  list: [],
  fetching: false
};

export default function bookings(state = defaultState, action) {
  switch (action.type) {
    case FETCHING_BOOKINGS:
      return {
        ...state,
        fetching: true
      };

    case SET_BOOKINGS:
      return {
        fetching: false,
        list: action.payload
      };

    default:
      return state;
  }
}
