import { SET_PAYMENT_INFO, FETCHING_PAYMENT_INFO } from '../constants/paymentInfo';
import api from '../api';

export const setPaymentInfo = payload => ({
  type: SET_PAYMENT_INFO,
  payload
});

export const fetchingPaymentInfo = () => ({
  type: FETCHING_PAYMENT_INFO
});

export const fetchPaymentInfo = jwt => dispatch => {
  dispatch(fetchingPaymentInfo());
  return api.subscription.fetchPaymentInfo(jwt).then(
    payload => dispatch(setPaymentInfo(payload.data.payment_info))
  );
};
