import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import appleAppStoreButton from '../../../../images/download_app-store@2x.png';
import googlePlayStoreButton from '../../../../images/download_google-play@2x.png';

const NoBookings = () => (
  <div className="no__bookings">
    <div className="container">
      <Helmet>
        <title>Trips</title>
      </Helmet>
      <h2>Looks like you haven't taken any trips yet!</h2>
      <br />
      <p className="text-center">
        Download the app to view how it works and preview flights. Make sure to head over to our{' '}
        <a href="http://help.flyskyhi.com">FAQs</a> for full details
      </p>
      <br />

      <br />
      <div className="skyhi-info-apps">
        <div className="flex-icons-download">
          <div className="icon-1">
            <a href="https://itunes.apple.com/us/app/skyhi/id1232965774?ls=1&mt=8">
              <img
                src={appleAppStoreButton}
                className="img-fluid"
                alt="iphone"
              />
            </a>
          </div>
          <div className="icon-1">
            <a href="https://play.google.com/store/apps/details?id=com.flyskyhi.androidapp">
              <img
                src={googlePlayStoreButton}
                className="img-fluid"
                alt="googleplay"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div className="arrow-container">
      <Link className="small back-arrow" to="/members/account">
        &larr; My Account
      </Link>
      <Link className="small back-arrow" to="/members/account/edit-payment">
        Update Payment &rarr;
      </Link>
    </div>
  </div>
);

export default NoBookings;
