import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAuth } from './actions/auth';
import { firebase } from './firebase';

import {
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT
} from './constants/setAuth';

/**
 * The purpose of this component is to determine the auth status,
 * and ensure that the auth status is correct and up to date
 */
class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
  }

  handleFirebaseSDKNotLoaded() {
    this.interval = setInterval(() => {
      if (firebase.apps.length === 1) {
        clearInterval(this.interval);
        this.interval = null;
        this.props.setAuth();
      }
    }, 100);
  }

  componentDidMount() {
    if (firebase.apps.length === 1) {
      this.props.setAuth();
    } else {
      this.handleFirebaseSDKNotLoaded();
    }
  }

  // If the user is logged in, their jwt token will expire in 24 hours. If the user
  // happens to keep their browser tab open for 24 hours, things will break. This
  // prevents that from happening by triggering a refresh every hour
  componentDidUpdate() {
    if (this.props.authStatus === AUTH_LOGGED_IN && this.interval === null) {
      this.interval = setInterval(() => this.props.setAuth(), 1000 * 60 * 60);
    } else if (this.props.authStatus === AUTH_LOGGED_OUT && this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  render() {
    return this.props.children;
  }
}

AuthProvider.propTypes = {
  setAuth: PropTypes.func.isRequired,
  authStatus: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  authStatus: state.auth.status,
});

export default connect(
  mapStateToProps,
  { setAuth }
)(AuthProvider);
