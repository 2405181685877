import {
  SET_DESTINATIONS_ALL,
  SET_DESTINATIONS_FEATURED,
  SET_DESTINATIONS_META
} from '../constants/destinations';

const defaultState = {
  index: [],
  featured: [],
  meta: {
    number_of_destinations: 73,
    number_of_routes: 2652,
    number_of_flights: 95997
  }
};

export default function destinations(state = defaultState, action) {
  switch (action.type) {
    case SET_DESTINATIONS_ALL:
      return {
        ...state,
        index: action.payload
      };
    case SET_DESTINATIONS_FEATURED:
      return {
        ...state,
        featured: action.payload
      };
    case SET_DESTINATIONS_META:
      return {
        ...state,
        meta: action.payload,
      };
    default:
      return state;
  }
}
