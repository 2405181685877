import axios from 'axios';

import { SET_INVITE } from '../constants/invite';

const setInvite = payload => ({
  type: SET_INVITE,
  payload,
});

export const validateInvite = inviteCode => dispatch =>
  axios.post(process.env.REACT_APP_API_URL_BASE + '/v1/invite-code', { link_segment: inviteCode })
    .then(response => {
      dispatch(setInvite({
        inviteCode: inviteCode,
        trialMonths: response.data.trial_months,
        trialAmount: response.data.trial_amount,
        initialCreditAmount: response.data.initial_credit_amount,
        subscriptionFee: response.data.subscription_plan.monthly_fee
      }));
      return response;
    });
