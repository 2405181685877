import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import CreditCard from './CreditCard';
import BillingAddress from './BillingAddress';
import Loader from './Loader';
import api from '../../../../api';
import ERROR from '../../../../images/payment-error.gif';
import SUCCESS from '../../../../images/payment-success.gif';
import PENDING from '../../../../images/payment_pending.gif';


const CARD_STEP = 'card';
const ADDRESS_STEP = 'address';

const defaultLoaderState = {
  title: '',
  image: '',
  text: '',
  linkBack: false,
  continue: false
};

class PaymentFlow extends Component {
  static propTypes = {
    // Deep link to push mobile user to after submitting the form
    branchLink: PropTypes.string.isRequired,
    // Async function that submits the paymentToken and profileInfo
    // to our server
    submitProfileInfo: PropTypes.func.isRequired,
    // Text displayed after payment succeeds
    successText: PropTypes.string.isRequired,
    // Component to render above the form
    headerComponent: PropTypes.elementType,
    // Initial values for the billing address form
    initialValues: PropTypes.object,
    // Used to possibly fetch initial values asyncronously
    initialValuesReady: PropTypes.bool,
    // Called after pressing continue on the credit card form, but
    // before submitting any data
    onCardFormSubmit: PropTypes.func,
    // Called after the entire payment flow succeeds, but before the user presses
    // the "Time to Fly!" button on the success screen
    onSuccessfulPayment: PropTypes.func,
    // Called after the user presses the "Time to Fly!" button on the success screen
    // if the user isn't pushed back to the app with a deeplink
    onSuccessfulPaymentConfirmation: PropTypes.func
  };

  static defaultProps = {
    headerComponent: () => null,
    initialValues: {},
    initialValuesReady: true,
    onCardFormSubmit: () => null,
    onSuccessfulPayment: () => null,
    onSuccessfulPaymentConfirmation: () => null
  };

  state = {
    step: CARD_STEP,
    showLoader: false,
    loaderState: defaultLoaderState,
    billingAddressValues: {}
  };

  creditCardFormRef = createRef();

  handleError = (error = {}) => {
    let message = null;
    const baseMessage = 'Looks like there was an issue processing your payment';

    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message;
    }
    else if (error.message) {
      message = error.message;
    }

    this.setState({
      showLoader: true,
      loaderState: {
        title: 'Oops!!',
        image: ERROR,
        text: message === null ? `${baseMessage}. Please try again, or contact us` : `${baseMessage}: ${message}`,
        linkBack: true,
        continue: false
      }
    });
  };

  handleSuccessfulPayment = () => {
    this.props.onSuccessfulPayment();
    this.setState({
      showLoader: true,
      loaderState: {
        title: 'Ready for lift off!',
        image: SUCCESS,
        text: this.props.successText,
        linkBack: false,
        continue: true
      }
    });
  }

  // Called when the user hits the "Time to Fly!" button in the Loader component
  handleSuccessfulPaymentConfirmation = () => {
    if (localStorage.fromApp) {
      // Deeplink back to app
      window.location = this.props.branchLink;
    }
    else {
      this.props.onSuccessfulPaymentConfirmation();
    }
  };

  clearLoader = () => {
    this.setState({
      step: CARD_STEP,
      showLoader: false,
      loaderState: defaultLoaderState
    });
  };

  setPaymentPending = () => {
    this.setState({
      showLoader: true,
      loaderState: {
        title: 'Processing Payment',
        image: PENDING,
        text: 'Processing Payment...',
        linkBack: false,
        continue: false
      }
    });
  };

  showCardForm = () => {
    this.setState({ step: CARD_STEP });
  };

  showAddressForm = () => {
    this.setState({ step: ADDRESS_STEP });
  };

  handleCardFormSubmit = () => {
    this.props.onCardFormSubmit();
    this.showAddressForm();
  };

  handleSubmit = async billingAddress => {
    try {
      this.setState({ billingAddressValues: billingAddress });
      const {
        paymentToken,
        profileInfo
      } = await this.creditCardFormRef.current.submitPaymentProfile(billingAddress);
      
      // We've submitted the credit card info to the payment provider; we can now setup the Loader which
      // causes CreditCard and BillingAddress to unrender.
      this.setPaymentPending();

      // The parent component chooses how to submit the data
      await this.props.submitProfileInfo({
        token: paymentToken,
        profile: profileInfo
      });

      // The subscription is setup asyncronously on the server, so we need to poll until the user
      // has a subscription status other than "payment_pending"
      const newSubscriptionStatus = await this.pollForPaymentResult();

      if (newSubscriptionStatus === 'terminated'
            || newSubscriptionStatus === 'terminated_hold'
            || newSubscriptionStatus === 'cancelled') {
        // Initial payment failed
        this.handleError();
      }
      else {
        // Success
        this.handleSuccessfulPayment();
      }
    } catch(error) {
      this.handleError(error);
    }
  };

  pollForPaymentResult() {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        api.auth.getUser(localStorage.jwt)
          .then(response => {
            // Check for subscription status other than payment_pending
            const subscriptionStatus = response.data.meta.subscription_status;
            if (subscriptionStatus !== 'payment_pending') {
              // Done polling
              clearInterval(interval);
              resolve(subscriptionStatus);
            }
          })
          .catch(error => {
            console.error(error);
            clearInterval(interval);
            reject(error);
          });
      }, 2000); // Poll every 2 seconds
    });
  }

  renderForms() {
    const Header = this.props.headerComponent;
    const billingAddressValues = this.state.billingAddressValues.email ?
                                              this.state.billingAddressValues :
                                              this.props.initialValues;
    return (
      <div className="form-container">
        <Header />
        <CreditCard
          ref={this.creditCardFormRef}
          isVisible={this.state.step === CARD_STEP}
          handleToggle={this.showCardForm}
          handleSubmit={this.handleCardFormSubmit}
        />
        <BillingAddress
          isVisible={this.state.step === ADDRESS_STEP}
          handleSubmit={this.handleSubmit}
          initialValues={billingAddressValues}
          initialValuesReady={this.props.initialValuesReady}
        />
      </div>
    );
  }

  renderLoader() {
    return (
      <Loader
        loaderState={this.state.loaderState}
        continue={this.handleSuccessfulPaymentConfirmation}
        clear={this.clearLoader}
      />
    );
  }

  render() {
    return (
      <div className="account-page onboarding-page-flow">
        {this.state.showLoader ? this.renderLoader() : this.renderForms()}
      </div>
    );
  }
}

export default PaymentFlow;
