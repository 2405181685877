import React, { Component } from "react";
import axios from "axios";

class Status extends Component {
  state = {
    fullname: "",
    email: "",
    success: false,
  };

  submitEmail = (e) => {
    e.preventDefault();
    axios
      .post(
        process.env.REACT_APP_API_URL_BASE +
          "/v1/mailing-list/subscribe-status",
        {
          fullname: this.state.fullname,
          email: this.state.email,
        }
      )
      .then(() => this.setState({ success: true }));
  };

  renderForm() {
    return (
      <form className="form-status" onSubmit={this.submitEmail}>
        <div className="input input--hideo">
          <input
            type="text"
            className="input__field input__field--hideo"
            placeholder="Full Name"
            value={this.state.fullname}
            onChange={(e) => {
              this.setState({ fullname: e.target.value });
            }}
          />
          <input
            type="email"
            className="input__field input__field--hideo"
            placeholder="Your Email"
            value={this.state.email}
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
          />
        </div>
        <button type="submit" className="btn btn-blue">
          Sign Up
        </button>
      </form>
    );
  }

  renderSuccess() {
    return (
      <div
        className="republic__banner"
        style={{
          position: "inherit",
          marginTop: "20px",
          backgroundColor: "#385A80",
          borderRadius: "5px",
        }}
      >
        <p style={{ marginTop: 0, textAlign: "start" }}>
          Thanks! We'll be in touch!
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="status__page">
        <div className="top-hero">
          <h1>Boarding Status...</h1>
        </div>
        <div className="container">
          <p>
            For much of the year, the world has been thrown into a whirlwind of
            uncertainty as COVID-19 spread across the world, and has truly
            affected everyone. Since the pandemic began, our team has been using
            this time wisely, working to bring important updates for when it
            will be safe to fly again. As time progressed, it was important that
            we continuously evaluated the state of the industry and the world as
            a whole. It became clear, travel will remain changed for some time.
            That is why, in order to keep the mission and vision of SkyHi alive
            for the future, we have decided to pause the ability to book flights
            and create new memberships via the SkyHi platform.{" "}
          </p>
          <p>
            In the months leading up to the event that changed the world, SkyHi
            was experiencing our best year yet. As the industry started to
            realize the magnitude of it all, major changes were implemented
            across the board. Due to an unprecedented lowering in demand due to
            the pandemic, airlines have limited the number of routes and
            aircraft and frequency of flights to cope. This, in turn, had a
            pronounced effect on our flight providers and airline partners, and
            greatly limited our ability to offer our member flights that were
            previously in abundance, changing the experience of our loyal
            customer base.
          </p>
          <p>
            Anyone will still be able to download the SkyHi app and search our
            available routes, but as of August 24, 2020 at 12:01am EST, it will
            not be possible to book flights via SkyHi. This way, past and
            potential members will be able to see changes in inventory as
            flights, routes, and planes return.{" "}
          </p>
          <p>
            As the country gains control of the virus and travel begins to
            return, our team will be in touch with updates as to what is going
            on, and what the path forward looks like as events unfold.
          </p>
          <p>
            To everyone who has been a part of the SkyHi journey thus far, thank
            you. You helped us prove the excitement and demand for new
            innovations in travel, like our membership for flat-rate flights,
            That's because of all of you.
          </p>
          <p>See you up in the clouds again soon,</p>
          <p>The SkyHi Team</p>
          <br />
          <p>
            Make sure to stay connected by signing up for our newsletter, where
            future information regarding SkyHi will be announced.{" "}
          </p>
          {this.state.success ? this.renderSuccess() : this.renderForm()}
        </div>
      </div>
    );
  }
}

export default Status;
