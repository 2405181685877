import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const NewTermsOfService = () => (
  <div id="terms-page">
    <Helmet>
      <title>Terms of Service</title>
    </Helmet>
    <div className="hero">
      <div className="container">
        <h2 className="">Terms of Use</h2>
        <h4 className="text-center no-pad-up">August 19, 2018</h4>
        <a href="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/SkyHi+-+Terms+of+Use+8-19-18.pdf">
          <h3>Download Terms of Use</h3>
        </a>
        <Link to="/legal/past-terms/">
          <h3 className="pastterms">See Past Terms of Service</h3>
        </Link>
        <br />
      </div>
    </div>
  </div>
);

export default NewTermsOfService;
