import { combineReducers } from 'redux';

import auth from './reducers/auth';
import user from './reducers/user';
import invite from './reducers/invite';
import status from './reducers/status';
import destinations from './reducers/destinations';
import bookings from './reducers/bookings';
import paymentInfo from './reducers/paymentInfo';
import location from './reducers/location';
import settings from './reducers/settings';

export default combineReducers({
  auth,
  user,
  invite,
  status,
  destinations,
  bookings,
  paymentInfo,
  location,
  settings,
});
