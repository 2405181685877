import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchLocation } from './actions/location';

class LocationProvider extends Component {
  componentDidMount() {
    this.props.fetchLocation();
  }

  render() {
    return this.props.children;
  }
}

export default connect(null, { fetchLocation })(LocationProvider);
