import React from 'react';
import { Link } from 'react-router-dom';

const Join = props => (
  <section className={`join-section ${props.css}`}>
    <div className="container">
      <div className="row join-stuff">
        <div className="col-sm-12 text-center join-section-content">
          <p className="join-header">Sign up. Start Flying.</p>
          <br />
          <p className="join-body">
            SkyHi - the flat rate flying club to whatever
            <span>&#39;</span>s next.
          </p>
          <br />
          <Link to="/login/" id="lead" className="btn btn-blue mobile-left">
            Join Now{' '}
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default Join;
