import React, { Suspense, lazy } from 'react';
import Moment from 'react-moment';
import { convertBookingTime } from '../../utils';

const AirlineLogo = lazy(() => import('./AirlineLogo'));

const Segment = ({ segment }) => (
  <div className="flight__segment">
    <div className="segment-row">
      <span>
        {segment.airline_name} {segment.flight_number}
      </span>
      <div className="segment__airline">
        <Suspense fallback={<span />}>
          <AirlineLogo airlineCode={segment.airline_code} />
        </Suspense>
      </div>
    </div>
    <div className="segment-row">
      <div className="segment">{segment.origin}</div>
      <div className="segment__time">
        <span>{convertBookingTime(segment.elapsed_time)}</span>
      </div>
      <div className="segment">{segment.destination}</div>
    </div>
    <div className="segment-row">
      <div className="segment">
        <Moment format="LT">{segment.departure_time}</Moment>
      </div>
      <div className="segment">
        <Moment format="LT">{segment.arrival_time}</Moment>
      </div>
    </div>
    {segment.layover_time ? (
      <div className="segment-layover">
        <span>
          {Math.floor(segment.layover_time / 60)}
          h:
          {segment.layover_time % 60}m layover in {segment.destination}
        </span>
      </div>
    ) : null}
  </div>
);

export default Segment;
