import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const TermsOfService = () => (
  <div id="terms-page">
    <Helmet>
      <title>Past Terms of Service</title>
    </Helmet>
    <div className="hero">
      <div className="container">
        <h2>Terms of Use</h2>
        <Link to="/legal/terms/">
          <h3>See Current Terms of Service (August 19, 2018)</h3>
        </Link>
        <Link to="/legal/terms-february2018/">
          <h3>See Past Terms of Service (February 19, 2018)</h3>
        </Link>
        <Link to="/legal/terms-december2017/">
          <h3>See Past Terms of Service (December 4, 2017)</h3>
        </Link>
        <Link to="/legal/terms-june2017/">
          <h3>See Past Terms of Service (June 6, 2017)</h3>
        </Link>
        <br />
        <br />
      </div>
    </div>
  </div>
);

export default TermsOfService;
