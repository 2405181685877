import React, { Suspense, lazy } from "react";
import Banner from "../home/components/Banner";
import Rules from "../home/components/Rules";
import Join from "../../public/components/Join";
import isBrowser from "../../../../isBrowser";

const LogoSectionAsync = lazy(() => import("../components/LogoSection"));
// Avoid "ReactDOMServer does not yet support Suspense" error during prerender
const logoSection = isBrowser() ? (
  <Suspense fallback={<span />}>
    <LogoSectionAsync id="press2" css="press-flex" />
  </Suspense>
) : (
  <span />
);

const Home = () => (
  <div className="Home">
    <Banner />
    <Rules />
    <section className="press-partners">{logoSection}</section>
    <Join
      title="Sign up. Start Flying."
      subtext="SkyHi - the flat rate flying club to whatever's next"
      css="join-yellow"
    />
  </div>
);

export default Home;
