import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import moment from 'moment';
import Cookies from 'js-cookie';
import mobile from '../../../../images/iphoneX-crop@1x.svg';
import planes from '../../../../images/5planes@2x.png';
import redstripe from '../../../../images/RedStripe@2x.png';
import phone from '../../../../images/iphone_X_1.svg';
import phone2 from '../../../../images/iphone_X_2@1x.svg';
import phone3 from '../../../../images/iphone_X_3@1x.svg';
import seat from '../../../../images/seat.svg';
import location from '../../../../images/location.svg';
import check from '../../../../images/check.svg';
import { validateInvite } from '../../../../actions/invite';
import { fetchDestinationsAll } from '../../../../actions/destinations';
import PricingText from '../Payment/PricingText';
import Places from '../Payment/Places';
import isBrowser from '../../../../isBrowser';

const Airlines = lazy(() => import('../../../pages/public/home/components/Airlines'))
// Avoid "ReactDOMServer does not yet support Suspense" error during prerender
const airlines = isBrowser() ? (
  <Suspense fallback={<span />}>
    <Airlines />
  </Suspense>
) : <span />;

class InviteLanding extends Component {
  state = {
    client: false,
  };

  componentDidMount() {
    this.handleInviteCode();
    this.props.fetchDestinationsAll();
    this.setState({ client: true });
    ReactPixel.track('Lead');
  }

  submitNewInviteCode = inviteCode => {
    // Check with the server whether this is a valid invite code
    this.props
      .validateInvite(inviteCode)
      // Save this invite code in cookies so that we can submit it on behalf of the
      // user later when they sign in and go through the create account flow
      .then(() => Cookies.set('invite_code', inviteCode))
      .catch(error => console.error(error));
  };

  handleInviteCode = () => {
    // Get the invite code from url. The url is in the form /invited/<invite-code>
    const urlComponents = this.props.location.pathname.split('/');
    let inviteCode = urlComponents[urlComponents.length - 1].toLowerCase();
    // Does the url end with a trailing slash?
    if (inviteCode === '') {
      inviteCode = urlComponents[urlComponents.length - 2].toLowerCase();
    }

    // Don't submit invite code if user navigated to /invited, /invited/,
    // /invited/default, or /invited/invited
    if (inviteCode.match(/^\s*$/) || inviteCode === 'default' || inviteCode === 'invited') {
      return;
    }

    // If the user already has a valid invite_code cookie, we don't want to overwrite it. Validate
    // the old invite code, and redirect to its page if it's valid.
    const oldInviteCode = Cookies.get('invite_code');
    if (oldInviteCode && oldInviteCode !== inviteCode) {
      this.props
        .validateInvite(oldInviteCode)
        .then(() => {
          // The old invite code from the cookie is valid, so we want to redirect to its page
          this.props.history.push(`/invited/${oldInviteCode}/`);
        })
        .catch(() => {
          // Old invite code not valid; try submitting new one
          this.submitNewInviteCode(inviteCode);
        });
    } else {
      this.submitNewInviteCode(inviteCode);
    }
  };

  renderCityList() {
    if (this.props.cityList.length > 0) {
      return (
        <span>
          {this.props.cityList.map(city => (
            <li key={city.toUpperCase()}>{city.toUpperCase()}</li>
          ))}
        </span>
      );
    }
    return (
      <span>
        <li key="AMSTERDAM">AMSTERDAM</li>
        <li key="ATHENS">ATHENS</li>
        <li key="ATLANTA">ATLANTA</li>
        <li key="AUSTIN">AUSTIN</li>
        <li key="BARCELONA">BARCELONA</li>
        <li key="BERLIN">BERLIN</li>
        <li key="BOSTON">BOSTON</li>
        <li key="COPENHAGEN">COPENHAGEN</li>
        <li key="CHICAGO">CHICAGO</li>
        <li key="DALLAS">DALLAS</li>
        <li key="DENVER">DENVER</li>
        <li key="FRANKFURT">FRANKFURT</li>
        <li key="GENEVA">GENEVA</li>
        <li key="LAS VEGAS">LAS VEGAS</li>
        <li key="LISBON">LISBON</li>
        <li key="LONDON">LONDON</li>
        <li key="LOS ANGELES">LOS ANGELES</li>
        <li key="MADRID">MADRID</li>
        <li key="MIAMI">MIAMI</li>
        <li key="MINNEAPOLIS">MINNEAPOLIS</li>
        <li key="MONTREAL">MONTREAL</li>
        <li key="NASHVILLE">NASHVILLE</li>
        <li key="NEW ORLEANS">NEW ORLEANS</li>
        <li key="NEW YORK">NEW YORK</li>
        <li key="PARIS">PARIS</li>
        <li key="PHOENIX">PHOENIX</li>
        <li key="PORTLAND">PORTLAND</li>
        <li key="REYKJAVIK">REYKJAVIK</li>
        <li key="ROME">ROME</li>
        <li key="SAN DIEGO">SAN DIEGO</li>
        <li key="SAN FRANCISCO">SAN FRANCISCO</li>
        <li key="SEATTLE">SEATTLE</li>
        <li key="STOCKHOLM">STOCKHOLM</li>
        <li key="TORONTO">TORONTO</li>
        <li key="VANCOUVER">VANCOUVER</li>
        <li key="VIENNA">VIENNA</li>
        <li key="WARSAW">WARSAW</li>
        <li key="WASHINGTON D.C.">WASHINGTON D.C.</li>
        <li key="ZURICH">ZURICH</li>
      </span>
    );
  }

  render() {
    return (
      <div className="alphainfo">
        <div className="flyabove">
          <div className="container">
            <div className="flyabove-flex">
              <h2>Fly Above The Rest.</h2>
              <p>
                Always <strong>fixed</strong> pricing, always <strong>one tap</strong> booking.
              </p>
              <p>Flights start at just $35</p>
              <br />
              {this.props.inviteCode !== 'default' ? (
                <div>
                  <div className="promo-box-top">
                    <div className="promo-text-top">
                      <h3>{this.props.inviteCode} promo!</h3>
                    </div>
                    <PricingText
                      client={this.state.client}
                      trialAmount={this.props.trialAmount}
                      trialMonths={this.props.trialMonths}
                      initialCreditAmount={this.props.initialCreditAmount}
                      subscriptionFee={this.props.subscriptionFee}
                    />
                  </div>
                </div>
              ) : null}
              <Link to="/members/skyhi-info/" className="btn btn-blue">
                Get Started
              </Link>
              <img src={mobile} className="img-fluid d-flex" alt={mobile} />
            </div>
          </div>
        </div>

        <div className="descriptor how-it-works-techstars">
          <div className="container">
            <h2 className="text-center">No More Pricing Games.</h2>
            <p className="text-center">
              Stop searching for flights based off of price. With SkyHi you have the freedom to fly for the same price
              every time.
            </p>
            <div className="descriptor-flex">
              <div className="describe">
                <h3>How We Do It</h3>
                <p className="smaller">
                  SkyHi is the first subscription service that allows you to travel for a fixed cost every time. Choose
                  any flight we offer departing in the upcoming 30 days and book with just one tap. We work with flight
                  providers to showcase flights that will likely go unsold, and allow you to book for a fraction of the
                  price. Be sure to check out our <a href="http://help.flyskyhi.com/">FAQs</a> for full details.
                </p>
                <br />
                <Link to="/members/skyhi-info/" className="btn btn-blue">
                  Get Started
                </Link>
              </div>
              <div className="boarding">
                <br />
                <div className="boarding-pass">
                  <div className="boarding-pass-header">
                    <span>YOUR SKYHI MEMBERSHIP</span>
                  </div>

                  <div className="boarding-pass-rules">
                    <PricingText
                      client={this.state.client}
                      trialAmount={this.props.trialAmount}
                      trialMonths={this.props.trialMonths}
                      initialCreditAmount={this.props.initialCreditAmount}
                      subscriptionFee={this.props.subscriptionFee}
                    />
                    <div className="boarding-pass-rules-flights">
                      <span className="boarding-text">5 FLIGHTS PER MONTH</span>
                      <img src={planes} className="img-fluid" alt="5 planes" />
                    </div>
                  </div>

                  <div className="boarding-pass-flight-credits">
                    <span>Earn flight credits!</span>
                    <span className="boarding-price credits">
                      Start sharing your referral code to earn credits towards flights!
                    </span>
                  </div>

                  <div className="boarding-pass-subscription">
                    <span>Membership starts</span>
                    <span className="membership-start-date">
                      {this.state.client ? moment().format('dddd, MMMM D, YYYY') : 'today'}
                    </span>
                  </div>
                  <div className="boarding-red-stripe">
                    <img src={redstripe} className="img-fluid" alt={redstripe} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container">
            <section className="appscreens">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2>HOW IT WORKS</h2>
                    <p>
                      Fly on any of our SkyHi routes and always pay a flat fee. Flights up to 1000 miles cost just $35.
                      Need to go further? Fly up to 2000 miles for just $75 and fly up to 3000 miles for just $120 every
                      time. You can book any of our routes regardless of where you are.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div className="screen-row row">
                  <div className="col-lg-4 col-md-8 screen-center">
                    <div className="screen text-center">
                      <div className="screen-icon">
                        <img src={location} className="img-fluid" alt="location" />
                      </div>
                      <div className="screen-content">
                        <p>Pick where you want to go from any of our SkyHi cities</p>
                      </div>
                      <div className="screen-img">
                        <img src={phone} className="img-fluid" alt="cities" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-8 screen-center">
                    <div className="screen text-center">
                      <div className="screen-icon">
                        <img src={seat} className="img-fluid" alt="seat" />
                      </div>
                      <div className="screen-content">
                        <p>Claim a seat on any available flight of your choice</p>
                      </div>
                      <div className="screen-img">
                        <img src={phone2} className="img-fluid" alt="flights" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-8 screen-center">
                    <div className="screen text-center">
                      <div className="screen-icon">
                        <img src={check} className="img-fluid" alt="confirm" />
                      </div>
                      <div className="screen-content">
                        <p>Get your confirmation and get ready to fly</p>
                      </div>
                      <div className="screen-img">
                        <img src={phone3} className="img-fluid" alt="claim" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <br />
                  <Link to="/members/skyhi-info/" className="btn btn-blue">
                    Get Started
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/*       <FAQ /> */}
        <Places />
        <div className="invite-press">
          <div className="container">
            <h2 className="text-center">SkyHi Buzz</h2>
            <div className="press-flex">
              <div className="press-item">
                <a
                  href="https://www.huffingtonpost.com/entry/how-skyhi-is-revolutionizing-how-you-travel_us_5a37ec00e4b0e1b4472ae870"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/huffpost_logo.png"
                    className="img-fluid mx-auto d-block"
                    alt="gadgetreview"
                  />
                </a>
              </div>
              <div className="press-item">
                <a href="http://www.gadgetreview.com/skyhi-review" target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/Gadget-Review_logo_1.png"
                    className="img-fluid mx-auto d-block"
                    alt="gadgetreview"
                  />
                </a>
              </div>
              <div className="press-item">
                <a
                  href="https://www.digitaltrends.com/cool-tech/skyhi-flight-subscription-app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/digital-Trends_Logo_1.png"
                    className="img-fluid mx-auto d-block"
                    alt="digitaltrends"
                  />
                </a>
              </div>

              <div className="press-item">
                <a
                  href="https://lounge.travelmassive.com/t/startup-roadtest-skyhi-app/3914/2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/TravelMassive_Logo_1.png"
                    className="img-fluid mx-auto d-block"
                    alt="travelmassive"
                  />
                </a>
              </div>
              <div className="press-item">
                <a
                  href="http://blog.twip.co/2017/10/11/fly-skyhi-with-ceo-rama-poola/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/Twip_logo_1.png"
                    className="img-fluid mx-auto d-block"
                    alt="twp"
                  />
                </a>
              </div>
              <div className="press-item">
                <a href="https://www.youtube.com/watch?v=97rL_Su7m9o" target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/me-convention_logo_1.png"
                    className="img-fluid mx-auto d-block"
                    alt="meconv"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        {airlines}
        <div className="map">
          <div className="container">
            <div className="row title">
              <div className="col-sm-10 offset-sm-1 text-center">
                <h1>Oh The Places You'll Go</h1>
                <p>SkyHi currently flies to these cities.</p>
                <ul className="flex-list destinations-list-invite">{this.renderCityList()}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className="destinationsbar">
          <br />
          <div className="contianer text-center">
            <Link to="/destinations/">Check out SkyHi routes</Link>
          </div>
          <br />
        </div>
        <div className="pricing-bottom">
          <div className="boarding-pass">
            <div className="boarding-pass-header">
              <span>YOUR SKYHI MEMBERSHIP</span>
            </div>
            <div className="boarding-pass-rules">
              <PricingText
                client={this.state.client}
                trialAmount={this.props.trialAmount}
                trialMonths={this.props.trialMonths}
                initialCreditAmount={this.props.initialCreditAmount}
                subscriptionFee={this.props.subscriptionFee}
              />
              <div className="boarding-pass-rules-flights">
                <span className="boarding-text">5 FLIGHTS PER MONTH</span>
                <img src={planes} className="img-fluid" alt="5 planes" />
              </div>
            </div>

            <div className="boarding-pass-flight-credits">
              <span>Earn flight credits!</span>
              <span className="boarding-price credits">
                Start sharing your referral code to earn credits towards flights!
              </span>
            </div>

            <div className="boarding-pass-subscription">
              <span>Membership starts</span>
              <span className="membership-start-date">
                {this.state.client ? moment().format('dddd, MMMM D, YYYY') : 'today'}
              </span>
            </div>
            <div className="boarding-red-stripe">
              <img src={redstripe} className="img-fluid" alt={redstripe} />
            </div>
          </div>
          <div className="text-center">
            <br />
            <Link to="/members/skyhi-info/" className="btn btn-blue">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    cityList: state.destinations.index.map(dest => dest.city.toUpperCase()),
    ...state.invite,
  }),
  { validateInvite, fetchDestinationsAll }
)(InviteLanding);
