import React, { Component } from 'react';

class Loader extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="loader-flex">
        <h2>{this.props.loaderState.title}</h2>
        <br />
        <img src={this.props.loaderState.image} className="img-fluid" alt={this.props.loaderState.image} />
        <br />
        <p className="loader-inner-text">{this.props.loaderState.text}</p>
        <br />
        {this.props.loaderState.linkBack ? (
          <button onClick={this.props.clear} className="btn btn-blue">
            Fix it!
          </button>
        ) : null}
        {this.props.loaderState.continue ? (
          <button className="btn btn-blue" onClick={this.props.continue}>
            Time to fly!
          </button>
        ) : null}
      </div>
    );
  }
}

export default Loader;
