import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnetCreditCardForm from './AnetCreditCardForm';
import StripeCreditCardForm from './StripeCreditCardForm';

class CreditCard extends Component {
  submitPaymentProfile = async billingAddress => {
    return await this.formRef.submitPaymentProfile(billingAddress);
  }

  formComponent() {
    return this.props.useStripe ? StripeCreditCardForm : AnetCreditCardForm;
  }

  render() {
    const Form = this.formComponent();
    return <Form ref={c => { this.formRef = c; }} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  useStripe: state.settings.payments.use_stripe
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(CreditCard);
