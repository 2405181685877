import React from 'react';
import { Link } from 'react-router-dom';
import phone from '../../../../../images/home/hiw_iphone-home.svg';
import mobile from '../../../../../images/home/Phone-crop_1@1x.svg';

const Banner = () => (
  <div>
    <section id="intro" className="intro-section">
      <div className="desktop-intro">
        <div className="container">
          <div className="intro-block">
            <div className="intro-fly section">
              <div className="intro-header">
                <h1 className="">No more pricing games.</h1>
                <h2 className="text-left">Fly for the same price every time.</h2>
                <p className="intro-text-desktop">
                  SkyHi makes it easy for you to claim seats on flights in North America and Europe for one low monthly
                  price. Become a member for just $199 a month. You’ll be able to take up to 5 one way flights per month
                  on any of our routes. Flights start at just $35.
                </p>

                
                <Link to="/login/" id="lead" className="btn btn-blue mobile-left">
                  Join Now
                </Link>
              </div>
              <div className="hero-phone">
                <img src={phone} className="img-fluid d-flex" alt="phone" />
              </div>
              <div className="hero-phone-mobile">
                <img src={mobile} className="img-fluid d-flex" alt="mobilephone" />
              </div>
            </div>
          </div>
          <div className="arrowdown">
            <p className="text-center">See How It Works</p>
            <div className="arrow bounce">
              <span className="fa fa-angle-double-down fa-2x" />
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-intro">
        <div className="hero-phone-mobile">
          <img src={mobile} className="img-fluid d-flex" alt="secondmobile" />
        </div>
        <div className="intro-block">
          <div className="intro-header">
            <h1 className="">No more pricing games.</h1>
            <h2 className="text-left">Fly for the same price every time.</h2>
            <p className="intro-text-desktop">
              SkyHi makes it easy for you to claim seats on flights in North America and Europe for one low monthly
              price. Become a member for just $199 a month. You’ll be able to take up to 5 one way flights per month on
              any of our routes. Each booking costs you just $35.
            </p>

            <Link to="/login/" id="lead" className="btn btn-blue mobile-left">
              Join Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Banner;
