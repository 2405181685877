import React, { Component, Fragment } from 'react';
import Card from 'react-credit-cards';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { fetchPaymentInfo } from '../../../../actions/paymentInfo';
import Spinner from '../../Spinner';

const TIMEOUT_BEFORE_CARD_ANIMATION = 10;

class PaymentInfo extends Component {
  state = {
    number: '',
    name: '',
    brand: ''
  };

  componentDidMount() {
    this.props.fetchPaymentInfo(localStorage.jwt);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetching && prevProps.fetching) {
      setTimeout(() => {
        const { first_name, last_name, brand } = this.props.paymentInfo;
        if (first_name) {
          this.setState({
            number: this.formatNumber(),
            name: `${first_name} ${last_name}`,
            brand
          });
        }
      }, TIMEOUT_BEFORE_CARD_ANIMATION);
    }
  }

  formatNumber() {
    let number = this.props.paymentInfo.card;
    if (!number) {
      return '';
    }
    number = number.replace(/X/g, '');
    number = '**** **** **** ' + number;
    return number;
  }

  renderInfoGroup() {
    const {
      first_name,
      last_name,
      email,
      phone_number,
      billing_address
    } = this.props.paymentInfo;
    return (
      <div className="info-group">
        <p className="subtitle">Name on Card</p>
        <p>{`${first_name} ${last_name}`}</p>
        <br />
        <p className="subtitle">Email</p>
        <p>{email}</p>
        <br />
        <p className="subtitle">Phone Number</p>
        <p>{phone_number}</p>
        <br />
        <p className="subtitle">Billing Address</p>
        <p>{billing_address.address_line_1}</p>
        <p>{`${billing_address.city}, ${billing_address.state}, ${billing_address.country}`}</p>
        <p>{billing_address.zip_code}</p>
        <br />
      </div>
    );
  }

  renderContents() {
    return (
      <Fragment>
        <Card
          number={this.state.number}
          expiry=""
          name={this.state.name}
          cvc=""
          preview={true}
          issuer={this.state.brand}
        />
        <br />
        {this.props.paymentInfo.first_name ? this.renderInfoGroup() : <p>Payment Info not found</p>}
        <Link className="btn btn-blue" to="/members/account/edit-payment">
          Update
        </Link>
        <br />
      </Fragment>
    );
  }

  render() {
    return (
      <div className="account-info-box">
        <Helmet>
          <title>Payment Info</title>
        </Helmet>
        <h4>Payment Information</h4>
        <br />
        {!this.props.paymentInfo.first_name && this.props.fetching ? <Spinner /> : this.renderContents()}
        <br />
        <div className="arrow-container">
          <Link className="small back-arrow" to="/members/account/trips/">
            &larr; My Trips
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  paymentInfo: state.paymentInfo.data,
  fetching: state.paymentInfo.fetching
});

export default connect(mapStateToProps, { fetchPaymentInfo })(PaymentInfo);
