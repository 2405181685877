import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { getStatus } from '../../../../actions/status';

class UpdatePaymentHeader extends Component {
  componentDidMount() {
    if (!this.props.placeholder) {
      this.props.getStatus(localStorage.jwt);
    }
  }

  renderTerminatedMessage() {
    if (this.props.status === 'terminated' || this.props.status === 'terminated_hold') {
      return (
        <span>
          <div className="alert alert-danger" role="alert">
            There was a problem with your monthly payment. Update your
            payment information below to retry the payment.
          </div>
          <hr />
        </span>
      );
    }
    else if (this.props.status === 'cancelled') {
      return (
        <span>
          <div className="alert alert-danger" role="alert">
            Your account has been cancelled. Update your payment information below to
            reactivate your account. Your card will be charged the full subscription fee
            as well as the reactivation fee as outlined in our terms of service.
          </div>
          <hr />
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Update Payment</title>
        </Helmet>
        <div style={{height: '5vh'}}>
          <Link className="small back-arrow" to="/members/account/payment-info/">
            &larr; Payment Info
          </Link>
        </div>
        <h2 style={{ fontSize: '28px' }}>Update Your Payment Information</h2>
        <br />
        <div className="alert alert-warning" role="alert">
          * Use the SkyHi App for all other account updates.
        </div>
        <br />
        <span className="yelow-border" />
        {this.renderTerminatedMessage()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  status: state.status
});

export default connect(mapStateToProps, { getStatus })(UpdatePaymentHeader);
