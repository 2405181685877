export const airlineData = [
  {
    "Code":"JP",
    "Name":"Adria Airways",
    "Baggage":"http://www.adria.si/en/article.cp2?cid=6662523E-A2FE-4E9D-A446-272D4E5BA929&linkid=top-article"
  },
  {
    "Code":"A3",
    "Name":"Aegean Airlines",
    "Baggage":"http://en.aegeanair.com/travel-information/baggage/baggage-allowance/"
  },
  {
    "Code":"EI",
    "Name":"Aer Lingus",
    "Carriage":"http://www.aerlingus.com/i18n/en/htmlPopups/conds_of_carriage.html",
    "Baggage":"http://www.aerlingus.com/help/help/baggageinformation/"
  },
  {
    "Code":"SU",
    "Name":"Aeroflot",
    "Carriage":"http://www.aeroflot.ru/cms/en/before_and_after_fly/pact",
    "Baggage":"http://www.aeroflot.ru/cms/en/before_and_after_fly/luggage"
  },
  {
    "Code":"AR",
    "Name":"Aerolineas Argentina",
    "Baggage":"http://www.aerolineas.com.ar/arg/main.asp?idSitio=CA&idPagina=50&idIdioma=en"
  },
  {
    "Code":"VW",
    "Name":"Aeromar",
    "Baggage":"http://www.aeromar.com.mx/faq/equipaje/"
  },
  {
    "Code":"AM",
    "Name":"Aeromexico",
    "Carriage":"http://www.aeromexico.com/us/TravelInformation/BeforeYouPurchase/RegulationsAndPolicies/conditions-of-contract.html",
    "Baggage":"https://www.aeromexico.com/en-us/travel-information/baggage"
  },
  {
    "Code":"BT",
    "Name":"Air Baltic",
    "Baggage":"http://www.airbaltic.com/en/baggage"
  },
  {
    "Code":"AB",
    "Name":"Air Berlin",
    "Carriage":" https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=1&cad=rja&uact=8&ved=0ahUKEwjnpZSjitjVAhVKQyYKHSKOBNAQFggmMAA&url=https%3A%2F%2Fwww.airberlin.com%2Fsite%2Faffiliate%2Funternehmen%2Fagb%2FABB_en.pdf&usg=AFQjCNGC7fK1_Tf3dHXw551Bxlyxbj4sHQ",
    "Baggage":"http://www.airberlin.com/en-CA/site/flug_gepaeck_luggage.php?et_cid=14015&et_lid=6760031&et_sub=[en-CA]service_gepack"
  },
  {
    "Code":"BP",
    "Name":"Air Botswana",
    "Baggage":"https://www.airbotswana.co.bw/baggage-liability"
  },
  {
    "Code":"AC",
    "Name":"Air Canada",
    "Carriage":"http://www.aircanada.com/en/travelinfo/before/contract.html",
    "Baggage":"http://www.aircanada.com/en/travelinfo/airport/baggage/index.html"
  },
  {
    "Code":"TX",
    "Name":"Air Caraibes",
    "Baggage":"http://www.aircaraibes.com/rubrique/detail/1940899575992681135/Bagages.html"
  },
  {
    "Code":"CA",
    "Name":"Air China",
    "Baggage":"https://www.airchina.us/US/GB/info/checked-baggage/"
  },
  {
    "Code":"XK",
    "Name":"Air Corsica",
    "Baggage":"http://www.aircorsica.com/infos-bagages/?language=en"
  },
  {
    "Code":"UX",
    "Name":"Air Europa",
    "Baggage":"http://www.aireuropa.com/waeam/us/estaticos/optional_service_charges.html"
  },
  {
    "Code":"AF",
    "Name":"Air France",
    "Baggage":"http://www.airfrance.us/US/en/common/voyage-en-avion/preparation-voyage/bagages-avion.htm"
  },
  {
    "Code":"AI",
    "Name":"Air India Limited",
    "Baggage":"http://airindia.com/baggage.htm"
  },
  {
    "Code":"3H",
    "Name":"Air Inuit",
    "Baggage":"http://www.airinuit.com/en/baggage/free-baggage-allowances"
  },
  {
    "Code":"MD",
    "Name":"Air Madagascar",
    "Baggage":"http://www.airmadagascar.com/fr/infos-voyageurs/bagages-avion-poids-reglementations"
  },
  {
    "Code":"KM",
    "Name":"Air Malta",
    "Baggage":"http://www.airmalta.com/baggage"
  },
  {
    "Code":"MK",
    "Name":"Air Mauritius",
    "Baggage":"http://www.airmauritius.com/baggage.htm"
  },
  {
    "Code":"SW",
    "Name":"Air Namibia",
    "Baggage":"http://www.airnamibia.com/travel-info-faq/about_luggage_baggage/"
  },
  {
    "Code":"NZ",
    "Name":"Air New Zealand",
    "Carriage":"http://www.airnewzealand.co.nz/conditions_of_carriage_info.htm",
    "Baggage":"http://www.airnewzealand.com/checked-in-baggage"
  },
  {
    "Code":"JU",
    "Name":"Air Serbia",
    "Baggage":"https://www.airserbia.com/en/baggage"
  },
  {
    "Code":"HM",
    "Name":"Air Seychelles",
    "Baggage":"https://www.airseychelles.com/en/before-you-fly/baggage-information"
  },
  {
    "Code":"VT",
    "Name":"Air Tahiti",
    "Baggage":"www.airtahiti.com/baggage"
  },
  {
    "Code":"TN",
    "Name":"Air Tahiti Nui",
    "Baggage":"https://www.airtahitinui.com/us-en/baggage-policies"
  },
  {
    "Code":"TS",
    "Name":"Air Transat",
    "Baggage":"http://www.airtransat.ca/en-CA/Travel-information/Baggage"
  },
  {
    "Code":"AK",
    "Name":"Airasia Sdn Bhd",
    "Baggage":"https://www.airasia.com/us/en/baggage-info/checked-baggage.page"
  },
  {
    "Code":"TL",
    "Name":"Airnorth",
    "Baggage":"http://www.flyairnorth.com/TravelInfo/Baggage.aspx"
  },
  {
    "Code":"AS",
    "Name":"Alaska Airlines",
    "Carriage":"http://www.alaskaair.com/www2/company/tariff/tariff_main_toc.asp",
    "Baggage":"http://www.alaskaair.com/content/travel-info/policies/baggage-checked.aspx"
  },
  {
    "Code":"AZ",
    "Name":"Alitalia S.A.I. S.P.",
    "Carriage":"http://www.alitalia.com/Images/CGT_en_0605_final_tcm9-10352.pdf",
    "Baggage":"https://www.alitalia.com/en_ca/fly-alitalia/baggage.html"
  },
  {
    "Code":"NH",
    "Name":"All Nippon Airways",
    "Baggage":"http://www.ana.co.jp/wws/us/e/asw_common/guide/baggage.html"
  },
  {
    "Code":"G4",
    "Name":"Allegiant Air",
    "Carriage":"http://www.allegiantair.com/aaContractOfCarriage.php",
    "Baggage":"http://www.allegiantair.com/baggage-info"
  },
  {
    "Code":"AA",
    "Name":"American Airlines",
    "Carriage":"https://www.aa.com/content/customerService/customerCommitment/conditionsOfCarriage.jhtml",
    "Baggage":"http://www.aa.com/i18n/utility/aacom_services_charges.jsp"
  },
  {
    "Code":"GP",
    "Name":"Apg Airlines",
    "Baggage":"http://www.apg-airlines.com/introductionPage.cms?process=introduction&lang=en"
  },
  {
    "Code":"IZ",
    "Name":"Arkia",
    "Baggage":"http://www.arkia.com/baggage-c115p"
  },
  {
    "Code":"R7",
    "Name":"Aserca",
    "Baggage":"http://www.asercaairlines.com/Serv02.aspx"
  },
  {
    "Code":"OZ",
    "Name":"Asiana Airlines",
    "Baggage":"http://us.flyasiana.com/C/en/homepage.do?menuId=003006002001000&menuType=CMS"
  },
  {
    "Code":"KP",
    "Name":"Asky",
    "Baggage":"http://flyasky.com/asky/en/Practical-Information/Luggage/Luggage-60.aspx"
  },
  {
    "Code":"KK",
    "Name":"Atlasglobal",
    "Baggage":"https://www.atlasglb.com/en/frequently-asked-questions#Baggage"
  },
  {
    "Code":"OS",
    "Name":"Austrian Airlines",
    "Carriage":"http://www.austrian.com/Info/TermsConditions/ConditionsOfCarriage.aspx",
    "Baggage":"http://www.austrian.com/Info/Flying/Baggage.aspx?sc_lang=en&cc=CA"
  },
  {
    "Code":"AV",
    "Name":"Avianca",
    "Baggage":"http://www.avianca.com/en-co/travel-information/plan-your-trip/baggage/"
  },
  {
    "Code":"AD",
    "Name":"Azul Linhas Aereas B",
    "Baggage":"https://www.voeazul.com.br/en/for-your-trip/information/checked-baggage"
  },
  {
    "Code":"UP",
    "Name":"Bahamasair",
    "Baggage":"http://bahamasair.com/optionalfees/"
  },
  {
    "Code":"PG",
    "Name":"Bangkok Airways",
    "Baggage":"http://www.bangkokair.com/eng/pages/view/baggage"
  },
  {
    "Code":"JV",
    "Name":"Bearskin Airlines",
    "Baggage":"http://www.bearskinairlines.com/baggage-policy"
  },
  {
    "Code":"BV",
    "Name":"Blue Panorama Airlines",
    "Baggage":"https://www.blue-panorama.com/luggage"
  },
  {
    "Code":"0B",
    "Name":"Blueair",
    "Baggage":"http://www.blueairweb.com/Carriage-Conditions/#ch9"
  },
  {
    "Code":"4B",
    "Name":"Boutique Air",
    "Baggage":"https://www.boutiqueair.com/p/baggage"
  },
  {
    "Code":"BA",
    "Name":"British Airways",
    "Carriage":"http://www.britishairways.com/travel/genconcarr/public/en_gb",
    "Baggage":"http://www.britishairways.com/travel/bagchk/public/en_ca"
  },
  {
    "Code":"SN",
    "Name":"Brussels Airlines",
    "Baggage":"https://www.brusselsairlines.com/en-be/practical-information/travel-info/before-the-flight/luggage-info/default.aspx"
  },
  {
    "Code":"FB",
    "Name":"Bulgaria Air",
    "Baggage":"https://www.air.bg/en/customer-support/your-baggage"
  },
  {
    "Code":"9K",
    "Name":"Cape Air",
    "Baggage":"https://www.capeair.com/flying_with_us/baggage.html"
  },
  {
    "Code":"BW",
    "Name":"Caribbean Airlines",
    "Baggage":"http://caribbean-airlines.com/index.php/plan-a-book/baggage-information"
  },
  {
    "Code":"CX",
    "Name":"Cathay Pacific",
    "Carriage":"http://www.airfarewatchdog.com/pages/3802676/airline-contracts-of-carriage/downloads.cathaypacific.com/cx/misc/conditionsofcarriage.pdf",
    "Baggage":"https://www.cathaypacific.com/cx/en_US/travel-information/baggage.html"
  },
  {
    "Code":"KX",
    "Name":"Cayman Airways",
    "Baggage":"www.caymanairways.com/baggage-policy"
  },
  {
    "Code":"CI",
    "Name":"China Airlines",
    "Baggage":"https://www.china-airlines.com/us/en/fly/prepare-for-the-fly/baggage/index"
  },
  {
    "Code":"MU",
    "Name":"China Eastern Airlines",
    "Baggage":"http://hk.ceair.com/muovc/main/en_HK/Static_pages/Baggage.html"
  },
  {
    "Code":"CZ",
    "Name":"China Southern Airlines",
    "Baggage":"http://global.csair.com/CZPortal/dyn/portal/DisplayPage?COUNTRY_SITE=US&SITE=J00YJ00Y&LANGUAGE=GB&PAGE=CABA"
  },
  {
    "Code":"DE",
    "Name":"Condor",
    "Baggage":"http://www.condor.com/eu/flight-info/baggage/current-information.jsp"
  },
  {
    "Code":"CM",
    "Name":"Copa Airlines",
    "Baggage":"http://www.copaair.com/sites/cc/en/informacion-de-viaje/Pages/tarifas-equipaje-y-de-servicios-opcionales.aspx"
  },
  {
    "Code":"SS",
    "Name":"Corsair",
    "Baggage":"http://www.corsair.ca/flight/services/corsairs-services/Luggages/hold-luggage"
  },
  {
    "Code":"OU",
    "Name":"Croatia Airlines",
    "Baggage":"http://www.croatiaairlines.com/Travel-info/Baggage/Checked-baggage"
  },
  {
    "Code":"OK",
    "Name":"Czech Airlines",
    "Baggage":"http://www.csa.cz/en/portal/info-and-services/travel-information/baggage2.htm"
  },
  {
    "Code":"DX",
    "Name":"Danish Air Transport",
    "Baggage":"https://dat.dk/faq"
  },
  {
    "Code":"F7",
    "Name":"Darwin Airline",
    "Baggage":"https://www.etihadregional.com/en-us/before-you-fly/baggage/"
  },
  {
    "Code":"DL",
    "Name":"Delta Air Lines",
    "Carriage":"http://www.delta.com/legal/contract_of_carriage/index.jsp",
    "Baggage":"https://www.delta.com/content/www/en_US/traveling-with-us/baggage/before-your-trip/checked.html"
  },
  {
    "Code":"U2",
    "Name":"Easyjet",
    "Carriage":"http://www.easyjet.com/EN/Book/regulations.html",
    "Baggage":"http://www.easyjet.com/EN/Planning/baggage"
  },
  {
    "Code":"MS",
    "Name":"Egyptair",
    "Baggage":"http://agents.egyptair.com/Pages/BaggageAllowance.aspx?OrderID=a"
  },
  {
    "Code":"LY",
    "Name":"El Al Israel Airline",
    "Baggage":"http://www.elal.co.il/ELAL/English/AllAboutYourFlight/BeforeYourFlight/LuggageAndBaggage/"
  },
  {
    "Code":"EK",
    "Name":"Emirates",
    "Baggage":"http://www.emirates.com/ca/English/help/faqs/FAQDetails.aspx?faqCategory=214913"
  },
  {
    "Code":"OV",
    "Name":"Estonian Air",
    "Baggage":"http://www.emirates.com/au/english/plan_book/essential_information/baggages/baggages.aspx"
  },
  {
    "Code":"ET",
    "Name":"Ethiopian Airlines",
    "Baggage":"https://www.ethiopianairlines.com/AA/EN/information/baggage"
  },
  {
    "Code":"EY",
    "Name":"Etihad Airways",
    "Baggage":"http://www.etihad.com/en-ca/before-you-fly/baggage-information/"
  },
  {
    "Code":"EW",
    "Name":"Eurowings",
    "Baggage":"https://www.eurowings.com/en/information/services/luggage.html"
  },
  {
    "Code":"BR",
    "Name":"Eva Air",
    "Baggage":"http://www.evaair.com/en-us/managing-your-trip/baggage-information/allowances/"
  },
  {
    "Code":"FJ",
    "Name":"Fiji Airways",
    "Baggage":"https://www.fijiairways.com/flight-information/baggage-allowances/"
  },
  {
    "Code":"AY",
    "Name":"Finnair",
    "Baggage":"http://www.finnair.com/CA/GB/information-services/baggage"
  },
  {
    "Code":"FY",
    "Name":"Firefly",
    "Baggage":"http://www.fireflyz.com.my/flights/checked-baggage"
  },
  {
    "Code":"7F",
    "Name":"First Air",
    "Baggage":"http://www.firstair.ca/flying/baggage/"
  },
  {
    "Code":"F8",
    "Name":"Flair Airlines",
    "Carriage":"https://flyflair.com/terms-conditions/",
    "Baggage":"https://flyflair.com/travel-info/baggage/"
  },
  {
    "Code":"BE",
    "Name":"Flybe",
    "Baggage":"http://www.flybe.com/flightInfo/baggage.htm"
  },
  {
    "Code":"FZ",
    "Name":"Flydubai",
    "Baggage":"http://www.flydubai.com/en/travel/baggage-policy/"
  },
  {
    "Code":"BF",
    "Name":"French Blue",
    "Baggage":"http://www.frenchblue.com/fr/services-tarifs/nos-services/bagages-cabine"
  },
  {
    "Code":"F9",
    "Name":"Frontier Airlines",
    "Carriage":"http://www.frontierairlines.com/faqs/contract-of-carriage-faqs",
    "Baggage":"https://www.flyfrontier.com/travel-information/baggage/"
  },
  {
    "Code":"GA",
    "Name":"Garuda Indonesia",
    "Baggage":"https://www.garuda-indonesia.com/id/en/garuda-indonesia-experience/on-ground/baggage/index.page?"
  },
  {
    "Code":"A9",
    "Name":"Georgian Airways",
    "Baggage":"https://www.georgian-airways.com/en/information/baggage/"
  },
  {
    "Code":"GM",
    "Name":"Germania Flug Ag",
    "Baggage":"https://www.flygermania.com/en/planning/baggage/"
  },
  {
    "Code":"4U",
    "Name":"Germanwings",
    "Baggage":"https://www.eurowings.com/en/information/services/luggage.html"
  },
  {
    "Code":"G3",
    "Name":"Gol Linhas Aereas S/",
    "Baggage":"http://www.voegol.com.br/en-us/viaje-sem-duvidas/fazendo-as-malas/bagagem-despachada/paginas/default.aspx"
  },
  {
    "Code":"ZK",
    "Name":"Great Lakes Aviation",
    "Baggage":"http://www.greatlakesav.com/"
  },
  {
    "Code":"GF",
    "Name":"Gulf Air",
    "Baggage":"http://www.gulfair.com/English/info/Baggage/Pages/Baggage_Allowances.aspx"
  },
  {
    "Code":"HU",
    "Name":"Hainan Airlines",
    "Baggage":"https://www.hainanairlines.com/US/US/BASV"
  },
  {
    "Code":"HA",
    "Name":"Hawaiian Airlines",
    "Baggage":"http://hawaiianair.custhelp.com/app/answers/detail/a_id/80/~/baggage-fees-%26-policies"
  },
  {
    "Code":"HX",
    "Name":"Hong Kong Airlines",
    "Baggage":"http://www.hongkongairlines.com/en_HK/services/baggage/checkedbaggage"
  },
  {
    "Code":"A5",
    "Name":"Hop",
    "Baggage":"http://www.hop.fr/en/info/baggage"
  },
  {
    "Code":"IB",
    "Name":"Iberia",
    "Carriage":"http://www.iberia.com/us/carriage-conditions/",
    "Baggage":"http://www.iberia.com/us/baggage/"
  },
  {
    "Code":"FI",
    "Name":"Icelandair",
    "Baggage":"http://www.icelandair.us/information/baggage-information/checked-baggage/"
  },
  {
    "Code":"7I",
    "Name":"Insel Air Internatio",
    "Baggage":"http://www.fly-inselair.com/traveler-info/baggage-information"
  },
  {
    "Code":"4O",
    "Name":"Interjet",
    "Baggage":"http://www.interjet.com.mx/OptionalServices.aspx?culture=en-US&Currency=USD"
  },
  {
    "Code":"WP",
    "Name":"Island Air",
    "Baggage":"http://www.islandair.com/flight-info/baggage/"
  },
  {
    "Code":"JL",
    "Name":"Japan Airlines",
    "Carriage":"http://www.jal.co.jp/en/carriage/index_c001.html",
    "Baggage":"http://www.jal.co.jp/en/inter/service/bag/"
  },
  {
    "Code":"9W",
    "Name":"Jet Airways",
    "Baggage":"http://www.jetairways.com/EN/PH/Baggage/FreeBaggageAllowances.aspx"
  },
  {
    "Code":"LS",
    "Name":"Jet2.com",
    "Carriage":"http://www.jet2.com/Terms.aspx",
    "Baggage":"http://www.jet2.com/new/baggage"
  },
  {
    "Code":"B6",
    "Name":"Jetblue",
    "Carriage":"http://www.jetblue.com/about/legal/legal.asp",
    "Baggage":"https://www.jetblue.com/travel/baggage/"
  },
  {
    "Code":"3K",
    "Name":"Jetstar Asia",
    "Baggage":"http://www.jetstar.com/us/en/planning-and-booking/baggage/checked-baggage"
  },
  {
    "Code":"KC",
    "Name":"Jsc Air Astana",
    "Baggage":"http://airastana.com/global/Information/Baggage-Information/Baggage-Allowance"
  },
  {
    "Code":"KL",
    "Name":"KLM Royal Dutch Airlines",
    "Carriage":"http://www.klm.com/travel/fi_en/meta/booking_conditions_carriage/index.htm",
    "Baggage":"http://www.klm.com/travel/ca_en/prepare_for_travel/baggage/baggage_allowance/index.htm"
  },
  {
    "Code":"KQ",
    "Name":"Kenya Airways",
    "Baggage":"https://www.kenya-airways.com/prepare-for-travel/baggage-information/baggage-allowance/en/"
  },
  {
    "Code":"KE",
    "Name":"Korean Air",
    "Baggage":"https://www.koreanair.com/global/en/traveling/baggage-services.html"
  },
  {
    "Code":"KU",
    "Name":"Kuwait Airways",
    "Baggage":"https://www.kuwaitairways.com/en/is/baggaage-service"
  },
  {
    "Code":"B0",
    "Name":"La Compagnie",
    "Baggage":"https://www.lacompagnie.com/en/plan-your-trip/formalities-before-flight#baggage"
  },
  {
    "Code":"LR",
    "Name":"Lacsa",
    "Baggage":"http://www.avianca.com/en-co/travel-information/plan-your-trip/baggage/"
  },
  {
    "Code":"TM",
    "Name":"Lam Mozambique",
    "Baggage":"http://www.lam.co.mz/en/Travel-Information/Baggage"
  },
  {
    "Code":"LP",
    "Name":"Lan Peru Sa",
    "Baggage":"https://www.latam.com/en_us/travel-information/baggage/carry-on-baggage/"
  },
  {
    "Code":"QV",
    "Name":"Lao Airlines",
    "Baggage":"http://www.laoairlines.com/?contentkey=pages&id=22"
  },
  {
    "Code":"JJ",
    "Name":"Latam Airlines Brasi",
    "Baggage":"https://www.latam.com/en_us/travel-information/baggage/carry-on-baggage/"
  },
  {
    "Code":"LI",
    "Name":"Liat",
    "Baggage":"http://www.liat.com/navSource.html?page_id=3"
  },
  {
    "Code":"LO",
    "Name":"Lot Polish Airlines",
    "Baggage":"http://www.lot.com/us/en/baggage"
  },
  {
    "Code":"LH",
    "Name":"Lufthansa",
    "Carriage":"http://www.lufthansa.com/online/portal/lh/cmn/generalinfo?l=en&nodeid=1818501",
    "Baggage":"http://www.lufthansa.com/ph/en/Carry-on-baggage#"
  },
  {
    "Code":"LG",
    "Name":"Luxair",
    "Baggage":"http://www.luxair.lu/cms/page?p=EN,58825,1314,,1,,"
  },
  {
    "Code":"MH",
    "Name":"Malaysia Airlines",
    "Baggage":"http://www.malaysiaairlines.com/my/en/plan/baggage.html"
  },
  {
    "Code":"2M",
    "Name":"Maya Island Air",
    "Baggage":"http://www.mayaislandair.com/belize-flights-baggage"
  },
  {
    "Code":"IG",
    "Name":"Meridiana Fly",
    "Baggage":"https://www.meridiana.it/en-en/beforetheflight/conditions.aspx#bagagliomano"
  },
  {
    "Code":"ME",
    "Name":"Middle East Airlines",
    "Baggage":"www.mea.com.lb/english/traveler-info/baggage-info/baggage-information"
  },
  {
    "Code":"MW",
    "Name":"Mokulele Flight Serv",
    "Baggage":"https://www.mokuleleairlines.com/travel-info/policies/#tab3"
  },
  {
    "Code":"HG",
    "Name":"Niki",
    "Baggage":"http://www.flyniki.com/en-GB/site/flug_gepaeck_luggage.php?name=baggage"
  },
  {
    "Code":"D8",
    "Name":"Norwegian Air Intern",
    "Baggage":"https://www.norwegian.com/us/travel-info/baggage/"
  },
  {
    "Code":"DY",
    "Name":"Norwegian Air Shuttl",
    "Baggage":"https://www.norwegian.com/uk/booking/booking-information/optional-charges/"
  },
  {
    "Code":"DI",
    "Name":"Norwegian Air Uk",
    "Baggage":"https://www.norwegian.com/uk/travel-info/baggage/"
  },
  {
    "Code":"OA",
    "Name":"Olympic Air",
    "Baggage":"https://www.olympicair.com/en/Info/Baggage/Limits"
  },
  {
    "Code":"WY",
    "Name":"Oman Air",
    "Baggage":"http://www.omanair.com/en/travel-info/baggage-services"
  },
  {
    "Code":"PC",
    "Name":"Pegasus Airlines",
    "Baggage":"https://www.flypgs.com/en/travel-services/flight-services/additional-baggage"
  },
  {
    "Code":"KS",
    "Name":"Penair",
    "Baggage":"http://www.penair.com/fly-with-us/fees"
  },
  {
    "Code":"PR",
    "Name":"Philippine Airlines",
    "Baggage":"https://www.philippineairlines.com/TravelInformation/BeforeYouFly/BaggageInformation/BaggageAllowance"
  },
  {
    "Code":"PD",
    "Name":"Porter Airlines",
    "Baggage":"https://www.flyporter.com/en-us/travel-information/baggage/checked-allowance"
  },
  {
    "Code":"PW",
    "Name":"Precision Air",
    "Baggage":"https://www.precisionairtz.com/travel/excess-baggage"
  },
  {
    "Code":"P0",
    "Name":"Proflight Commuter",
    "Baggage":"http://proflight-zambia.com/planning/baggage.php"
  },
  {
    "Code":"QF",
    "Name":"Qantas Airways",
    "Carriage":"http://www.qantas.com.au/info/flying/beforeYouTravel/conditionsCarriage",
    "Baggage":"http://www.qantas.com/travel/airlines/checked-baggage/global/en"
  },
  {
    "Code":"QR",
    "Name":"Qatar Airways",
    "Baggage":"http://www.qatarairways.com/us/en/baggage.page"
  },
  {
    "Code":"RW",
    "Name":"Republic Airlines",
    "Baggage":"http://www.rjet.com/en/Flying_with_us/Baggage.aspx"
  },
  {
    "Code":"AT",
    "Name":"Royal Air Maroc",
    "Baggage":"https://www.royalairmaroc.com/us-en/Travel-Info/Baggage-and-service-fees"
  },
  {
    "Code":"BI",
    "Name":"Royal Brunei",
    "Baggage":"https://www.flyroyalbrunei.com/en/brunei/information/essential-travel-information/#special-items"
  },
  {
    "Code":"RJ",
    "Name":"Royal Jordanian",
    "Baggage":"http://www.rj.com/en/baggage_allowance.html"
  },
  {
    "Code":"FR",
    "Name":"Ryanair",
    "Carriage":"http://www.ryanair.com/site/EN/conditions.php",
    "Baggage":"https://www.ryanair.com/ie/en/useful-info/help-centre/fees"
  },
  {
    "Code":"S3",
    "Name":"Santa Barbara Airlines",
    "Baggage":"https://www.sbairlines.com/seccion/equipaje"
  },
  {
    "Code":"S4",
    "Name":"Sata Azores Airlines",
    "Baggage":"http://www.sata.pt/en/useful-information/checked-baggage"
  },
  {
    "Code":"SV",
    "Name":"Saudi Arabian Airlin",
    "Baggage":"http://www.saudia.com/TRAVEL-INFORMATION/Baggage/Baggage-allowances"
  },
  {
    "Code":"SK",
    "Name":"Scandinavian Airline",
    "Baggage":"http://www.flysas.com/en/us/Travel-info/Baggage/?nssexc=true"
  },
  {
    "Code":"BB",
    "Name":"Seaborne Airlines",
    "Baggage":"http://www.seaborneairlines.com/news/seaborne-luggage-allowance/"
  },
  {
    "Code":"SC",
    "Name":"Shandong Airlines",
    "Baggage":"http://www.shandongair.com/"
  },
  {
    "Code":"ZH",
    "Name":"Shenzhen Airlines",
    "Baggage":"http://global.shenzhenair.com/zh/html/en/travelinfo/consignbaggage.html"
  },
  {
    "Code":"3M",
    "Name":"Silver Airways Corp",
    "Baggage":"http://www.silverairways.com/more-information/travel-policies/policy/optional-fees"
  },
  {
    "Code":"SQ",
    "Name":"Singapore Airlines",
    "Carriage":"http://www.singaporeair.com/jsp/cms/en_UK/global_footer/conditions-carriage.jsp",
    "Baggage":"http://www.singaporeair.com/en_UK/us/travel-info/baggage/"
  },
  {
    "Code":"GQ",
    "Name":"Skyexpress",
    "Baggage":"http://www.skyexpress.gr/en-us/usefulinformation/baggageessentials.aspx"
  },
  {
    "Code":"QS",
    "Name":"Smartwings",
    "Baggage":"http://www.smartwings.com/en/travel-info/frequently-asked-questions/"
  },
  {
    "Code":"IE",
    "Name":"Solomon Airlines",
    "Baggage":"http://www.flysolomons.com/index.php?option=com_content&view=article&id=89&Itemid=58"
  },
  {
    "Code":"SA",
    "Name":"South African Airway",
    "Baggage":"http://www.flysaa.com/sn/en/flyingSAA/baggage/excessBaggage.html"
  },
  {
    "Code":"NK",
    "Name":"Spirit Airlines",
    "Carriage":"http://www.spiritair.com/contractofcarriage.aspx",
    "Baggage":"https://www.spirit.com/OptionalServices#baggage-fees"
  },
  {
    "Code":"UL",
    "Name":"Srilankan Airlines",
    "Baggage":"http://www.srilankan.com/en-US/plan-and-book/luggage"
  },
  {
    "Code":"SY",
    "Name":"Sun Country",
    "Carriage":"http://www.suncountry.com/page/1/terms-conditions.jsp",
    "Baggage":"https://www.suncountry.com/Fly/Baggage/Carry-On-Baggage"
  },
  {
    "Code":"WG",
    "Name":"Sunwing Airlines Inc",
    "Baggage":"http://www.flysunwing.com/travelinfo/baggageinfo.asp#Checked_Allowance"
  },
  {
    "Code":"PY",
    "Name":"Surinam Airways",
    "Baggage":"http://www.flyslm.com/en/checked-baggage/"
  },
  {
    "Code":"LX",
    "Name":"Swiss International",
    "Carriage":"http://www.swiss.com/web/EN/general/footer_links/conditions_of_carriage/Pages/conditions_of_carriage.aspx",
    "Baggage":"https://www.swiss.com/us/EN/prepare/baggage"
  },
  {
    "Code":"DT",
    "Name":"Taag",
    "Baggage":"http://www.taag.com/en/Plan/Luggage"
  },
  {
    "Code":"TA",
    "Name":"Taca International A",
    "Baggage":"http://www.avianca.com/en-co/travel-information/plan-your-trip/baggage/"
  },
  {
    "Code":"VR",
    "Name":"Tacv Cabo Verde Airl",
    "Baggage":"https://flytacv.com/checked-luggage/?lang=en"
  },
  {
    "Code":"TP",
    "Name":"Tap Portugal",
    "Carriage":"https://www.flytap.com/en-pt/transport-conditions",
    "Baggage":"https://www.flytap.com/en-us/baggage"
  },
  {
    "Code":"RO",
    "Name":"Tarom",
    "Baggage":"http://www.tarom.ro/en/passenger-information/baggage/"
  },
  {
    "Code":"TG",
    "Name":"Thai Airways Interna",
    "Baggage":"http://www.thaiairways.com/en/Terms_condition/baggage_policy.page"
  },
  {
    "Code":"HQ",
    "Name":"Thomas Cook Airlines",
    "Baggage":"https://www.thomascookairlines.com/en/flight-preparation/check-in/baggage/lost-or-damaged-baggage.jsp"
  },
  {
    "Code":"IT",
    "Name":"Tigerair",
    "Baggage":"http://www.tigerair.com/sg/en/baggage.php"
  },
  {
    "Code":"TU",
    "Name":"Tunisair",
    "Baggage":"http://www.tunisair.com/site/template/en/pc_en.html"
  },
  {
    "Code":"TK",
    "Name":"Turkish Airlines",
    "Baggage":"https://p.turkishairlines.com/en-us/any-questions/free-baggage/index.html"
  },
  {
    "Code":"US",
    "Name":"US Airways",
    "Carriage":"http://www2.usairways.com/awa/content/aboutus/customersfirst/contractofcarriage.aspx",
    "Baggage":"https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp?anchorEvent=false&from=footer"
  },
  {
    "Code":"PS",
    "Name":"Ukraine Intl Airline",
    "Baggage":"http://www.flyuia.com/eng/information-and-services/before-you-fly/baggage/allowances.html"
  },
  {
    "Code":"UA",
    "Name":"United Airlines",
    "Carriage":"http://www.united.com/web/en-US/content/contract.aspx",
    "Baggage":"http://www.united.com/page/middlepage/0,6823,1031,00.html"
  },
  {
    "Code":"HY",
    "Name":"Uzbekistan Airways",
    "Baggage":"https://www.uzairways.com/en/flights/luggage"
  },
  {
    "Code":"VN",
    "Name":"Vietnam Airlines",
    "Baggage":"https://www.vietnamairlines.com/vn/en/travel-information/baggage/general-info"
  },
  {
    "Code":"VX",
    "Name":"Virgin America",
    "Carriage":"http://www.virginamerica.com/va/infoDeskAction.do",
    "Baggage":"http://virginamerica.custhelp.com/app/answers/detail/a_id/55/session/L3RpbWUvMTM2Mzk5NjMzMi9zaWQvQ1Q4SUxRbGw%3D"
  },
  {
    "Code":"VS",
    "Name":"Virgin Atlantic",
    "Carriage":"http://www.virgin-atlantic.com/en/gb/bookflightsandmore/conditionsofcarriage/index.jsp",
    "Baggage":"http://www.virginatlantic.com/us/en/travel-information/baggage-allowance.html"
  },
  {
    "Code":"VA",
    "Name":"Virgin Australia Int",
    "Baggage":"https://www.virginaustralia.com/au/en/plan/baggage/carry-on-baggage/"
  },
  {
    "Code":"VB",
    "Name":"Viva Aerobus",
    "Baggage":"www.vivaaerobus.com/en/flying-with-viva/fees-and-charges"
  },
  {
    "Code":"Y4",
    "Name":"Volaris",
    "Baggage":"https://cms.volaris.com/en/traveling-with-volaris/baggage-policy/?countryflag=United+States&currency=USD"
  },
  {
    "Code":"VY",
    "Name":"Vueling",
    "Baggage_Link":"https://www.vueling.com/en/vueling-services/prepare-your-trip/luggage",
    "Carriage":"https://www.vueling.com/en/customer-services/conditions-of-carriage"
  },
  {
    "Code":"WS",
    "Name":"Westjet",
    "Baggage":"https://www.westjet.com/en-ca/travel-info/baggage/index"
  },
  {
    "Code":"WM",
    "Name":"Windward Island Airw",
    "Baggage":"https://www.fly-winair.sx/baggage.html"
  },
  {
    "Code":"WW",
    "Name":"Wow Air",
    "Carriage":"https://wowair.co.uk/terms-conditions/",
    "Baggage":"https://wowair.us/travel-info/optional-fees-and-charges/"
  },
  {
    "Code":"SE",
    "Name":"XL Airways France",
    "Baggage":"http://www.xl.com/bagages-avion-xl"
  }
];
