import { SET_STATUS } from '../constants/status';

export default function userStatus(state = 'payment_pending', action) {
  switch (action.type) {
    case SET_STATUS:
      return action.payload;
    default:
      return state;
  }
}
