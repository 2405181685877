import React, { lazy, Suspense } from 'react';

import isBrowser from '../../../../isBrowser';

const AirlineLogoAsync = lazy(() => import('../../user/User_Account/AirlineLogo'));

let AirlineLogo = null;
if (isBrowser()) {
  AirlineLogo = ({ code }) => (
    <Suspense fallback={<span />}>
      <AirlineLogoAsync airlineCode={code} className="airlinelogo img-fluid" />
    </Suspense>
  );
}
else {
  AirlineLogo = () => <span />;
}

export default AirlineLogo;
