import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
// import axios from 'axios';
import { submitIdToken } from '../../../../actions/auth';
import { facebookProvider, googleProvider, auth } from '../../../../firebase';
import { AUTH_PENDING, AUTH_LOGGED_IN } from '../../../../constants/setAuth';
import paths from './loginSVGPaths';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateloginError: false,
      currentProvider: '',
      loading: false,
    };
    // Handlers for facebook and google login buttons
    this.firebaseLoginFacebook = this.firebaseLogin(facebookProvider, 'FACEBOOK');
    this.firebaseLoginGoogle   = this.firebaseLogin(googleProvider,   'GOOGLE');
  }

  postParams = params => {
    // axios({
    //   url: process.env.REACT_APP_API_URL_BASE + '/v1/meta/print',
    //   method: 'post',
    //   params,
    // });
  };

  setLoading = loading => {
    this.setState({ loading });
  };

  // Authenticate the idToken with the SkyHi server. If this succeeds, it will dispatch
  // setAuthStatus(AUTH_LOGGED_IN), which will cause the route wrapper component to
  // navigate away from this page.
  submitIdToken = () =>
    this.props.submitIdToken()
      // We don't need to call this.setLoading(false) on success, because we're about
      // to be redirected away from this page. In that case we might be setting state on
      // an unmounting component, which causes React to error log a warning/traceback.
      .catch(() => {
        this.setLoading(false);
      });

  handleFirebaseLoginSuccess = result => {
    ReactPixel.track('CompleteRegistration');
    // We've successfully logged in with firebase. Now we need to fetch a jwt
    // using a firebase id token fetched with auth().currentUser.getIdToken.
    // The problem is that right now, currentUser may be null, because the
    // firebase SDK sets up the data for the user asynchronously. We solve this
    // by using onAuthStateChanged to wait for the user data to be present.
    let unsubscribe = auth().onAuthStateChanged(loggedIn => {
      if (loggedIn) {
        unsubscribe();
        unsubscribe = null;
        this.submitIdToken();
      }
    });
    // Prevent a situation where the onAuthStateChanged handler never gets called with
    // "loggedIn" present and leaves us hanging on the loading screen.
    setTimeout(() => {
      if (unsubscribe !== null) {
        console.error('handleFirebaseLoginSuccess: FIREBASE SDK NEVER REPORTED THE SIGN IN EVENT');
        unsubscribe();
        this.setLoading(false);
      }
    // 7 seconds should be more than enough time for the firebase SDK to do its thing
    }, 7000);
  };

  firebaseLogin = (provider, providerName) => () => {
    this.setLoading(true);
    auth().signInWithPopup(provider)
      .then(this.handleFirebaseLoginSuccess)
      .catch(err => {
        console.error(`ERROR LOGGING IN WITH ${providerName}`);
        console.error(err);
        this.checkForDuplicateEmailError(err, providerName);
        this.setLoading(false);
      });
  };

  checkForDuplicateEmailError = (code, providerName) => {
    if (code && code.code === 'auth/account-exists-with-different-credential') {
      // User needs to link their account in the app
      console.error('DUPLICATE EMAIL ERROR');
      this.setState({
        duplicateloginError: true,
        currentProvider: providerName
      });
    }
  };

  renderGoogleLoginButton(allowButtonClick) {
    const buttonClass = 'social-login-button google';
    return (
      <button
        className={allowButtonClick ? buttonClass : buttonClass + ' inactive'}
        onClick={allowButtonClick ? this.firebaseLoginGoogle : () => null}
        target="_top"
      >
        <div>
          <div className="social-login-svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" fill="#ffffff" viewBox="0 0 50 50">
              <g>
                <path d={paths.Google} />
              </g>
            </svg>
          </div>
          <div className="social-login-button-padding" />
          <div className="social-login-button-text">Login with Google</div>
        </div>
      </button>
    );
  }

  renderFacebookLoginButton(allowButtonClick) {
    const buttonClass = 'social-login-button facebook';
    return (
      <button
        className={allowButtonClick ? buttonClass : buttonClass + ' inactive'}
        onClick={allowButtonClick ? this.firebaseLoginFacebook : () => null}
        target="_top"
      >
        <div>
          <div className="social-login-svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 90 90">
              <g>
                <path d={paths.Facebook} fill="#FFFFFF" />
              </g>
            </svg>
          </div>
          <div className="social-login-button-padding" />
          <div className="social-login-button-text">Login with Facebook</div>
        </div>
      </button>
    );
  }

  renderContents(allowButtonClick) {
    const currentProvider = this.state.currentProvider === 'FACEBOOK' ? 'Facebook' : 'Google';
    const otherProvider   = this.state.currentProvider === 'FACEBOOK' ? 'Google' : 'Facebook';

    return (
      <Fragment>
        {this.renderFacebookLoginButton(allowButtonClick)}
        {this.renderGoogleLoginButton(allowButtonClick)}
        <br />
        {this.state.duplicateloginError ? (
          <span className="alert alert-danger">
            Looks like you already have an account through {otherProvider}. To sign in with {currentProvider},
            go to the SkyHi app, sign in with {otherProvider}, navigate to Account -> Link Accounts from the menu,
            and sign in with {currentProvider}.
          </span>
        ) : null}
      </Fragment>
    );
  }

  render() {
    return (
      <div className="fb-page">
        <noscript className="login-noscript">
          Please enable javascript to login to SkyHi.
        </noscript>
        <Helmet>
          <title>Time For Lift Off</title>
        </Helmet>
        <div className="container">
          {this.state.loading ? (
            <div className="facebook-login-flex">
              <h2>Please wait while we initiate your boarding process...</h2>
            </div>
          ) : (
            <div className="facebook-login-flex">
              <div className="login-arrow-container">
                <Link className="small back-arrow" to="/">
                  &larr; Home
                </Link>
              </div>
              <h2>Time for lift off.</h2>
              <br />
              {this.props.status === AUTH_PENDING || this.props.status === AUTH_LOGGED_IN ?
                this.renderContents(false) :
                this.renderContents(true)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  submitIdToken: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default connect(
  state => ({ status: state.auth.status }),
  { submitIdToken }
)(LoginPage);
