import React, { Component } from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

class PromoForm extends Component {
  handleBlur = e => {
    this.props.submitForm();
  };

  handleFocus = e => {
    this.props.reset();
  };

  render() {
    const inputClass = this.props.errors.promo ? 'input100 is-invalid' : 'input100';
    return (
      <Form>
        <div className="inputwrap">
          <div className="input-flex-wrapper">
            <div className="wrap-input100promo">
              <Field
                type="text"
                name="promo"
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                className={inputClass}
                placeholder="Enter Promo Code"
              />
              <span className="focus-input100" />
            </div>
          </div>
        </div>
        <br />
      </Form>
    );
  }
}

export default withFormik({
  mapPropsToValues({ promo }) {
    const trimmedPromo = promo && promo.trim();
    return {
      promo: trimmedPromo && trimmedPromo !== 'default' ? trimmedPromo : '',
    };
  },
  validationSchema: Yup.object().shape({
    promo: Yup.string(),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const jwt = localStorage.jwt;
    const promo = { jwt, values };
    props.submitPromo(promo, setSubmitting);
  },
})(PromoForm);
