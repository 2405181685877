import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Promo from './Promo';
import { submitPromo } from '../../../../actions/user';

class CreatePaymentHeader extends Component {
  componentDidMount() {
    this.props.submitPromo(this.props.promo)
      .catch(error => console.error(error));
  }

  creditText = () => {
    const amount = this.props.initialDiscountAmount;
    if (amount > 250) {
      return `You get $${amount.toLocaleString()} of flight credit!`;
    }
    else {
      return `You get ${amount / 35} free flight${amount === 35 ? '' : 's'} on us!`;
    }
  };

  render() {
    return (
      <Fragment>
        <h2>Payment Details</h2>
        <br />
        <div className="intpuwrap bottom-divider">
          {this.props.trialMonths > 0 ? (
            <h4 className="text-left">
              {this.props.trialAmount}/mo
            </h4>
          ) : (
            <h4 className="text-left">{this.props.subscriptionFee}/mo</h4>
          )}
          <div className="payment-flow-pricing-list">
            <p className="small">
              Your credit card will be charged{' '}
              {this.props.trialMonths > 0 ? (
                <span>
                  {this.props.trialAmount} today for your first monthly subscription
                  that will begin today. Your regular monthly subscription of {this.props.subscriptionFee}/mo will start on{' '}
                  <Moment format="MM/DD/YYYY" add={{ months: this.props.trialMonths }}>
                    {new Date()}
                  </Moment>
                  .
                </span>
              ) : (
                <span>{this.props.subscriptionFee} today for your first monthly subscription that will begin today.</span>
              )}
            </p>
            {this.props.initialDiscountAmount > 0 ? (
              <p className="small">
                <br />
                {this.creditText()}
              </p>
            ) : null}
          </div>
        </div>
        <div className="bottom-divider">
          <Promo promo={this.props.promo} />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  promo: state.user.meta.alpha_token ? state.user.meta.alpha_token.promo : null,
  trialAmount: state.user.meta.alpha_token ? state.user.meta.alpha_token.trial_amount : null,
  trialMonths: state.user.meta.alpha_token ? state.user.meta.alpha_token.trial_months : 0,
  subscriptionFee: state.user.meta.alpha_token ? state.user.meta.alpha_token.monthly_amount : '$199',
  initialDiscountAmount: state.user.user.total_flight_credits
});

export default connect(mapStateToProps, { submitPromo })(CreatePaymentHeader);
