import axios from 'axios';

export default {
  auth: {
    login: res => axios.post(process.env.REACT_APP_API_URL_BASE + '/v1/auth/firebase', { id_token: res }),
    getUser: jwt =>
      axios
        .get(process.env.REACT_APP_API_URL_BASE + '/v1/user', { headers: { Authorization: `Token token="${jwt}` } }),
    refresh: jwt =>
      axios
        .get(process.env.REACT_APP_API_URL_BASE + '/v1/auth/refresh', { headers: { Authorization: `Token token="${jwt}` } }),
    logout: jwt =>
      axios
        .delete(process.env.REACT_APP_API_URL_BASE + '/v1/auth', { headers: { Authorization: `Token token="${jwt}` } }),
  },
  user: {
    account: user =>
      axios({
        url: process.env.REACT_APP_API_URL_BASE + '/v1/user',
        method: 'PUT',
        headers: {
          Authorization: `Token token="${user.jwt}"`,
        },
        data: { user: user.values, disclaimers: user.values.disclaimers },
      }),
    submitPromo: user =>
      axios({
        url: process.env.REACT_APP_API_URL_BASE + '/v1/alpha/token',
        method: 'POST',
        headers: {
          Authorization: `Token token="${user.jwt}"`,
        },
        data: { token: user.token, overwrite: user.overwrite || false },
      }),
    status: jwt =>
      axios
        .get(process.env.REACT_APP_API_URL_BASE + '/v2/user/plan-info', { headers: { Authorization: `Token token="${jwt}` } }),
    bookings: jwt =>
      axios
        .get(process.env.REACT_APP_API_URL_BASE + '/v2/bookings', { headers: { Authorization: `Token token="${jwt}` } }),
  },
  subscription: {
    createSubscription: ({ profile, token, jwt }) =>
      axios({
        url: process.env.REACT_APP_API_URL_BASE + '/v1/payments/subscription',
        method: 'POST',
        headers: {
          Authorization: `Token token="${jwt}"`,
        },
        data: {
          token,
          plan: 'US',
          profile,
        },
      }),
    updateSubscription: ({ profile, token, jwt }) =>
      axios({
        url: process.env.REACT_APP_API_URL_BASE + '/v1/payments/subscription',
        method: 'PUT',
        headers: {
          Authorization: `Token token="${jwt}"`,
        },
        data: {
          token,
          plan: 'US',
          profile,
        },
      }),
    fetchPaymentInfo: jwt =>
      axios({
        url: process.env.REACT_APP_API_URL_BASE + '/v1/payments/payment-info',
        headers: {
          Authorization: `Token token="${jwt}"`,
        },
      }),
  },
};
