import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = () => (
  <div className="nomatch-page">
    <div className="container">
      <div className="nomatch-flex">
        <div className="nomatch-text">
          <h2>Oops! Looks like this page doesn't exist!</h2>
          <br />
          <Link to="/" className="btn btn-blue">
            Lets go home!
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default NoMatch;
