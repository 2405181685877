import { SET_USER } from '../constants/user';
import { SET_AUTH } from '../constants/auth';
import { AUTH_LOGGED_OUT } from '../constants/setAuth';

const defaultState = {
  user: {},
  meta: {},
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case SET_AUTH:
      return action.payload === AUTH_LOGGED_OUT ? defaultState : state;
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
}
