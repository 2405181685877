import { SET_PAYMENT_INFO, FETCHING_PAYMENT_INFO } from '../constants/paymentInfo';

const defaultState = {
  data: {
    billing_address: {}
  },
  fetching: false
};

export default function paymentInfo(state = defaultState, action) {
  switch (action.type) {
    case SET_PAYMENT_INFO:
      return {
        data: action.payload,
        fetching: false
      };

    case FETCHING_PAYMENT_INFO:
      return {
        ...state,
        fetching: true
      };

    default:
      return state;
  }
}
