import { SET_STATUS } from '../constants/status';
import api from '../api';

export const setStatus = payload => ({
  type: SET_STATUS,
  payload,
});

export const getStatus = jwt => dispatch =>
  api.user.status(jwt).then(payload => {
    const data = payload.data.plan_info;
    if (data) {
      dispatch(setStatus(data.subscription_status));
    }
  });
