import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { withRouter } from 'react-router-dom';
import { grabUser } from '../../../../actions/user';
import PaymentFlow from './PaymentFlow';
import CreatePaymentHeader from './CreatePaymentHeader';
import api from '../../../../api';


const CREATE_BRANCH_LINK = 'https://wfbz.app.link/bcP3PmC3nV';

class CreatePayment extends Component {
  constructor(props) {
    super(props);
    // We want to ensure that we only send this event once.
    this.trackedPaymentInfoEvent = false;
  }

  async submitProfileInfo({ profile, token }) {
    const jwt = localStorage.jwt;
    const response = await api.subscription.createSubscription({ profile, token, jwt });
    ReactPixel.track('Subscribe');
    return response;
  }

  handleCardFormSubmit = () => {
    if (!this.trackedPaymentInfoEvent) {
      this.trackedPaymentInfoEvent = true;
      ReactPixel.track('AddPaymentInfo');
    }
  }

  handleSuccessfulPayment = () => {
    // This doesn't change the mounted component; it's just for analytics purposes
    this.props.history.replace('/members/payment-details/payment-success/');
  }

  handleSuccessfulPaymentConfirmation = () => {
    // Calling this here will cause the app to be redirected to the account pages
    this.props.grabUser(localStorage.jwt);
  }

  render() {
    return (
      <PaymentFlow
        branchLink={CREATE_BRANCH_LINK}
        submitProfileInfo={this.submitProfileInfo}
        headerComponent={CreatePaymentHeader}
        onCardFormSubmit={this.handleCardFormSubmit}
        onSuccessfulPayment={this.handleSuccessfulPayment}
        onSuccessfulPaymentConfirmation={this.handleSuccessfulPaymentConfirmation}
        successText="Welcome to SkyHi! Continue!"
        initialValuesReady={this.props.initialValuesReady}
        initialValues={{
          email: this.props.email,
          phone_number: this.props.phoneNumber,
          country: this.props.country,
          region: this.props.region
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValuesReady: !state.location.fetching,
  country: state.location.data.country_code,
  region: state.location.data.region_code,
  email: state.user.user.email,
  phoneNumber: state.user.user.phone_number
});

export default connect(mapStateToProps, { grabUser })(withRouter(CreatePayment));

