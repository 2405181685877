import React from 'react';
import { Helmet } from 'react-helmet-async';
import { airlineData } from '../../../../data';

import AirlineLogo from './AirlineLogo';

const airlinesWithCarriage = airlineData.filter((airline) => airline.Carriage);

const Carriage = () => (
  <div className="baggagepage">
    <Helmet>
      <title>Contracts of Carriage</title>
    </Helmet>
    <div className="container">
      <h2>Airlines Contracts of Carriage</h2>
      <div className="baggageflex">
        {airlinesWithCarriage.map(airline => (
          <div key={airline.Code} className="baggageinfobox">
            <div><AirlineLogo code={airline.Code} /></div>
            <h4>{airline.Name}</h4>
            <div>
              <a href={airline.Carriage}>Contract of Carriage</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Carriage;
