import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

class AccountInfo extends Component {
  render() {
    const { user } = this.props;
    return (
      <div className="account-info-box">
        <Helmet>
          <title>Account Info</title>
        </Helmet>
        <h4>Passenger Information</h4>
        <br />
        <div className="info-group">
          <div className="flexGroup">
            <img className="img-fluid profile-img" src={user.profile_image_url} alt={user.first_name} />
          </div>
          <br />
          {user.documents.length > 0 && (
            <Fragment>
              <p className="subtitle">NAME AS IT APPEARS ON PASSPORT</p>
              <p>
                {user.documents[0].first_name} {user.documents[0].middle_name} {user.documents[0].last_name}
              </p>
            </Fragment>
          )}
          <br />
          <p className="subtitle">Email</p>
          <p>{user.email}</p>
          <br />
          <p className="subtitle">Phone Number</p>
          <p>{user.phone_number}</p>
          <br />
          <p className="subtitle">DATE OF BIRTH</p>
          <p>
            {user.birthday ? <Moment format="LL">{user.birthday}</Moment> : <span />}
          </p>
          <br />
          <p className="subtitle">Address</p>
          <p>
            {user.address.address_line_1} {user.address.address_line_2}
          </p>
          <p>{user.address.city}</p>
          <p>{user.address.state}</p>
          <p>{user.address.zip_code}</p>
          <p>{user.address.country_code}</p>
          <br />
          {user.documents.length > 0 && (
            <Fragment>
              <p className="subtitle">Passport No.</p>
              <p>{user.documents[0].code}</p>
              <br />
              <p className="subtitle">Country of Issuance</p>
              <p>{user.documents[0].issue_country}</p>
              <br />
              <p className="subtitle">Issue Date</p>
              <p>
                <Moment format="LL">{user.documents[0].issue_date}</Moment>
              </p>
              <br />
              <p className="subtitle">Expiration Date</p>
              <p>
                <Moment format="LL">{user.documents[0].date_of_expiry}</Moment>
              </p>
            </Fragment>
          )}
          <br />
          <p className="small">
            Use the SkyHi App to change any of the above information. If you need to update any passport information,
            please contact us at contact@flyskyhi.com
          </p>
        </div>
        <br />
        <div className="arrow-container">
          <Link className="small back-arrow" to="/members/account/trips/">
            My Trips &rarr;
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  null
)(AccountInfo);
