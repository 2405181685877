import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import isBrowser from '../../../isBrowser';

const FebruaryTermsOfServiceContents = lazy(() => import('./FebruaryTermsOfServiceContents'));

const contents = isBrowser() ? (
  <Suspense fallback={<span />}>
    <FebruaryTermsOfServiceContents />
  </Suspense>
) : <span />;

const FebruaryTermsOfService = () => (
  <div>
    <Helmet>
      <title>Terms of Service (February 2018)</title>
    </Helmet>
    {contents}
  </div>
);

export default FebruaryTermsOfService;
